import { makeStyles } from '@material-ui/core';

const stepIconStyles = makeStyles(() => ({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 50,
    width: 50,
    alignItems: 'center',
  },
  active: {
    color: '#FD5252',
    height: 50,
    width: 50,
  },
  circle: {
    height: 50,
    width: 50,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circleComplete: {
    height: 50,
    width: 50,
    borderRadius: '50%',
    backgroundColor: '#23B73A',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  completed: {
    color: 'white',
    zIndex: 1,
  },
  text: {
    color: 'white',
    fontSize: 20,
  },
}));

export default stepIconStyles;
