import { Button, CircularProgress, Dialog } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {
    AddBox,
    ArrowDownward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    SaveAlt,
    Search,
} from '@material-ui/icons'
import 'firebase/functions'
import MaterialTable, { Column, Icons } from 'material-table'
import moment from 'moment'
// @ts-ignore
import pdfMake from 'pdfmake-thai/build/pdfmake'
// @ts-ignore
import pdfFonts from 'pdfmake-thai/build/vfs_fonts'
import React, { forwardRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const tableIcons: Icons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
    )),
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
            display: 'flex',
        },
    }),
)

export default function FacebookPageDetailReportTable(
    title: string,
    dataArray: any[],
    exportFileName: string = '',
) {
    const classes = useStyles()
    const history = useHistory()
    const [data, setData] = React.useState(dataArray)
    const [open, setOpen] = React.useState(false)

    useEffect(() => {
        setData(dataArray)
    }, [dataArray])

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const dateFormat = 'YYYYMMDD_HHmm'

    pdfMake.vfs = pdfFonts.pdfMake.vfs
    pdfMake.fonts = {
        THSarabunNew: {
            normal: 'THSarabunNew.ttf',
            bold: 'THSarabunNew Bold.ttf',
            italics: 'THSarabunNew Italic.ttf',
            bolditalics: 'THSarabunNew BoldItalic.ttf',
        },
        Roboto: {
            normal: 'Roboto-Regular.ttf',
            bold: 'Roboto-Medium.ttf',
            italics: 'Roboto-Italic.ttf',
            bolditalics: 'Roboto-MediumItalic.ttf',
        },
    }

    const gotoPost = (data: any) => {
        window.open(data.link.link, '_blank')
    }

    return (
        <div>
            <MaterialTable
                title={title}
                columns={[
                    { field: 'date', title: 'Live วันที่' },
                    { field: 'liveViewer', title: 'จำนวนคนดู สูงสุด' },
                    { field: 'liveStocks', title: 'จำนวนสินค้า ที่ลงขาย' },
                    { field: 'allOrderCount', title: 'จำนวนสินค้า ที่มีคนCF' }, //จำนวนสินค้าที่ถูกสั่งซื้อ จำนวนสินค้า ที่ตกลงขาย
                    { field: 'orderCount', title: 'จำนวนสินค้า ตกลงขาย' },
                    { field: 'userOrderCount', title: 'จำนวนผู้ซื้อ' },
                    {
                        field: 'sumIncomeAndShippingFree',
                        title: 'ยอดขาย รวมค่าส่ง',
                    },
                    { field: 'income', title: 'ยอดขาย ทั้งหมด' },
                    { field: 'sumShippingFee', title: 'ค่าส่ง ทั้งหมด' },

                    {
                        field: 'sumTransferdOrder',
                        title: 'ยอดโอน',
                    },
                    {
                        field: 'sumTransferWaitingOrder',
                        title: 'ยอดยังไม่โอน',
                    },
                    {
                        field: 'sumComfirmTransferOrder',
                        title: 'ยอดยืนยัน รับยอดโอน',
                    },
                    {
                        field: 'link',
                        title: 'link post',
                        hidden: true,
                        // render: (data, type) => {
                        //     return data.link.link
                        // },
                    },
                ]}
                // style={
                //     {
                //         maxHeight: '100%',
                //         width: '1920',
                //     }
                // }
                data={dataArray}
                icons={tableIcons}
                align="right"
                options={{
                    pageSize: 25,
                    actionsColumnIndex: -1,
                    exportButton: true, //{ csv: true, pdf: false } as any,
                    exportAllData: true,
                    pageSizeOptions: [25, 50, 100, 200, dataArray.length],

                    exportFileName: (
                        columns: Column<any>,
                        data: string[][],
                    ) => {
                        const dateStirng = moment().format(dateFormat)
                        return `${exportFileName}-${dateStirng}`
                    },
                    // @ts-ignore
                    exportPdf: (columns: Column<any>[], data: any[]) => {
                        // @ts-ignore
                        const filteredColumns: {
                            field: string
                            title: string
                        }[] = columns.filter(
                            (c) =>
                                typeof c.field === 'string' &&
                                typeof c.title === 'string',
                        )
                        const docDefinition = {
                            defaultStyle: {
                                font: 'THSarabunNew',
                            },
                            content: [
                                {
                                    fontSize: 9,
                                    layout: 'lightHorizontalLines',
                                    table: {
                                        headerRows: 1,
                                        // widths: filteredColumns.map(() => '*'),
                                        body: [
                                            filteredColumns.map((c) => c.title),
                                            ...data.map((row) =>
                                                filteredColumns.map((c) => {
                                                    const { field } = c
                                                    let value: any = ''
                                                    const fieldSteps =
                                                        field.split('.')
                                                    value = row
                                                    fieldSteps.forEach(
                                                        (step) => {
                                                            value = value[step]
                                                        },
                                                    )
                                                    return value
                                                }),
                                            ),
                                        ],
                                    },
                                },
                            ],
                        }
                        const getFilename = () => {
                            const dateStirng = moment().format(dateFormat)
                            return `${exportFileName}-${dateStirng}`
                        }
                        pdfMake
                            .createPdf(docDefinition)
                            .download(`${getFilename()}`)
                    },
                }}
                localization={{
                    header: {
                        actions: 'Action',
                    },
                }}
                actions={[
                    {
                        // icon: 'edit',
                        icon: (props: any) => {
                            return (
                                <div className={classes.root}>
                                    <Button variant="outlined" color="primary">
                                        LINK
                                    </Button>
                                </div>
                            )
                        },
                        tooltip: 'go to post',
                        onClick: (event, rowData) => gotoPost(rowData),
                    },
                ]}
                // เป็น MelonLive_Report-${pageID}-YYYYMMDD_HHMM

                // เช่น MelonLive_Report-102585864773528-20201229_1014
                // actions={[
                //   {
                //     // icon: 'edit',
                //     icon: (props: any) => {
                //       return (
                //         <div className={classes.root}>
                //           <Button variant="outlined" color="primary">
                //             View
                //           </Button>
                //         </div>
                //       )
                //     },
                //     tooltip: 'View',
                //     onClick: (event, rowData) => viewReport(rowData),
                //   },
                // ]}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CircularProgress style={{ margin: '20px' }} />
            </Dialog>
        </div>
    )
}
