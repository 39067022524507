import React, { useEffect } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Grid, Icon } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import SearchIcon from '@material-ui/icons/Search'
import moment from 'moment'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 140,
        },
    }),
)
type DateRangePickersParams = {
    fromdateValue: string
    todateValue: string
    onSubmit: (fromDate: String, toDate: String) => void
}

export default function DateRangePickers(params: DateRangePickersParams) {
    const classes = useStyles()
    const [fromDate, setFromDate] = React.useState<String>('')
    const [toDate, setToDate] = React.useState<String>('')

    const onclick = (event: any) => {
        params.onSubmit(fromDate, toDate)
    }

    const onChangeFromDate = (event: any) => {
        setFromDate(event.target.value)
    }

    const onChangeToDate = (event: any) => {
        setToDate(event.target.value)
    }

    useEffect(() => {
        setFromDate(params.fromdateValue)
        setToDate(params.todateValue)
    }, [params])

    return (
        <Grid container spacing={5} alignItems="flex-end">
            <Grid item xs={4}>
                <TextField
                    id="fromdate"
                    label="FromDate"
                    type="date"
                    defaultValue={fromDate}
                    value={fromDate}
                    onChange={onChangeFromDate}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={4}>
                <TextField
                    id="todate"
                    label="ToDate"
                    type="date"
                    value={toDate}
                    defaultValue={toDate}
                    onChange={onChangeToDate}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SearchIcon />}
                    onClick={onclick}
                >
                    Date
                </Button>
            </Grid>
        </Grid>
    )
}
