import React from 'react';
import MaskedInput from 'react-text-mask';
import { ITextMaskCustom } from "../models/RegisModel";

export const TelephoneMask = (props: ITextMaskCustom) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
            ]}
            guide={false}
        />
    );
}

export const PostCodeMask = (props: ITextMaskCustom) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
            guide={false}
        />
    );
}