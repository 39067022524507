import { StepConnector, withStyles } from '@material-ui/core';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
  },
  active: {
    '& $line': {
      borderColor: '#23B73A',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#23B73A',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 4,
    borderRadius: 1,
  },
})(StepConnector);

export default ColorlibConnector;
