import {
    createMuiTheme,
    CssBaseline,
    MuiThemeProvider,
} from '@material-ui/core'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { initFirebaseUser } from './firebase'
import './index.css'
import * as serviceWorker from './serviceWorker'

initFirebaseUser()
const theme = createMuiTheme({
    palette: {
        primary: { main: '#FF4100' },
        secondary: { main: '#FF9A77' },
    },
})

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <App />
            </MuiThemeProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root'),
)
serviceWorker.unregister()
