const firebaseConfig = {
    apiKey: "AIzaSyC1xv1l3FxFYbdLfQBQ1ITZxvZM90HA5v8",
    authDomain: "livemote-293ae.firebaseapp.com",
    databaseURL: "https://livemote-293ae.firebaseio.com",
    projectId: "livemote-293ae",
    storageBucket: "livemote-293ae.appspot.com",
    messagingSenderId: "79888474413",
    appId: "1:79888474413:web:9d413ddd0d95055840f3a4",
    measurementId: "G-W84WSX24E9"
};

export default firebaseConfig;