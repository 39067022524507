import { Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import appStore from '../../../images/landing/app_store.svg'
import googlePlay from '../../../images/landing/google_play.svg'
import iconFree from '../../../images/landing/icon_free.png'
import topicPng from '../../../images/landing/topic.png'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bg: {
            backgroundColor: ' #ff9a77',
            position: 'relative',
            width: 'calc(100% + 48px)',
            margin: '0 -24px',
            alignItems: 'center',

            [theme.breakpoints.down('xs')]: {
                width: 'calc(100% + 40px)',
            },
        },
        logo: {
            margin: '12px 0px 8px 0px',
            [theme.breakpoints.down('xs')]: {
                width: '50px',
                height: '50px',
            },
        },
        row: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                display: 'grid',
            },
        },
        content: {
            fontSize: '40px',
            fontWeight: 'bold',
            fontFamily: 'PslTextNewPro',
            margin: '0px',
            color: ' #ffffff',
            [theme.breakpoints.down('md')]: {
                fontSize: '35px',
                // marginBottom: '-8px',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '26px',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '20px',
            },
        },
        groupImage: {
            marginRight: '48px',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'end',
            },
        },
        img: {
            display: 'contents',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
            },
        },
        button: {
            [theme.breakpoints.down('sm')]: {
                padding: '0px',
            },
        },
        image: {
            width: '137px',
            height: '40px',
            margin: '12px 0px 8px 0px',
            [theme.breakpoints.down('sm')]: {
                margin: '0px',
                width: '100px',
                height: '30px',
                padding: '0px',
            },
            [theme.breakpoints.down('xs')]: {
                width: '80px',
                padding: '0px',
            },
        },
        free: {
            right: '0',
            bottom: '0',
            width: '65px',
            position: 'absolute',
            [theme.breakpoints.down('xs')]: {
                width: '40px',
            },
        },
    }),
)

export default function ComplexGrid() {
    const classes = useStyles()

    return (
        <Grid container item spacing={2} className={classes.bg}>
            <Grid lg={1} md={1} sm={2} xs={3} container justify="center">
                <img className={classes.logo} src={topicPng} alt="topic" />
            </Grid>
            <Grid
                lg={10}
                md={10}
                sm={8}
                xs={8}
                className={classes.row}
                alignItems="center"
            >
                <Grid lg={6} md={6} sm={12} xs={12}>
                    <p className={classes.content}>
                        LIVE ขายง่ายๆ เล่นกิจกรรมก็สะดวก!
                    </p>
                </Grid>

                <Grid
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    className={classes.groupImage}
                >
                    <Grid lg={2} md={3} sm={4} xs={5} className={classes.img}>
                        <Button
                            className={classes.button}
                            href="https://apps.apple.com/us/app/id1508261567"
                            target="_blank"
                        >
                            <img
                                src={appStore}
                                alt="app store icon"
                                style={{
                                    marginLeft: '0px',
                                }}
                                className={classes.image}
                            />
                        </Button>
                    </Grid>
                    <Grid lg={2} md={3} sm={4} xs={5}>
                        <Button
                            className={classes.button}
                            href="https:play.google.com/store/apps/details?id=com.praneat.livemote"
                            target="_blank"
                        >
                            <img
                                src={googlePlay}
                                alt="google play"
                                className={classes.image}
                            />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid lg={1} md={1} sm={2} xs={2}>
                <img className={classes.free} src={iconFree} alt="icon_free" />
            </Grid>
        </Grid>
    )
}
