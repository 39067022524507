import { useCallback } from 'react'

export interface IPatchInfoPage {
    patchName: string
    detail: string
    version: number
    createDate: string
    patch(): Promise<void>
}

export abstract class PatchBase implements IPatchInfoPage {
    abstract patchName: string
    abstract detail: string
    abstract createDate: string
    abstract version: number

    abstract patch(): Promise<void>

    getInfo() {
        return {
            patchName: this.patchName,
            detail: this.detail,
            createDate: this.createDate,
            version: this.version,
        }
    }
}
