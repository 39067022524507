import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import styled from 'styled-components'
import appStore from '../../../images/landing/app_store.svg'
import googlePlay from '../../../images/landing/google_play.svg'
import iconFree from '../../../images/landing/icon_free.png'
import topicPng from '../../../images/landing/topic.png'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bg: {
            backgroundColor: ' #ff9a77',
            paddingTop: '20px',
        },
        logo: {
            paddingLeft: '10px',
        },
        content: {
            fontSize: '40px',
            fontWeight: 'bold',
            fontFamily: 'PslTextNewPro',
            margin: '0px',
            color: ' #ffffff',
            [theme.breakpoints.down('md')]: {
                fontSize: '26px',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '23px',
            },
        },
        downloadStore: {
            padding: theme.spacing(1),
            textAlign: 'right',
        },
        downloadGoogle: {
            padding: theme.spacing(1),
        },
        free: {
            right: '0',
            bottom: '0',
            textAlign: 'right',
        },
    }),
)

export default function ComplexGrid() {
    const classes = useStyles()

    return (
        <CustomGrid container item spacing={2} className={classes.bg}>
            {/* <Grid item> */}
            <Grid lg={1} md={1} xs={2}>
                <img className={classes.logo} src={topicPng} alt="topic" />
            </Grid>
            <Grid lg={6} md={6} xs={11}>
                <p className={classes.content}>
                    LIVE ขายง่ายๆ เล่นกิจกรรมก็สะดวก!
                </p>
            </Grid>
            <Grid lg={2} md={2} xs={6} className={classes.downloadStore}>
                <a
                    href="https://apps.apple.com/us/app/id1508261567"
                    target="_blank"
                >
                    <img
                        src={appStore}
                        alt="app store icon"
                        style={{
                            marginLeft: '0px',
                        }}
                    />
                </a>
            </Grid>
            <Grid lg={2} md={2} xs={6} className={classes.downloadGoogle}>
                <a
                    href="https:play.google.com/store/apps/details?id=com.praneat.livemote"
                    target="_blank"
                >
                    <img src={googlePlay} alt="google play" />
                </a>
            </Grid>
            <Grid lg={1} md={1} xs={2} className={classes.free}>
                <img className="free" src={iconFree} alt="icon_free" />
            </Grid>
            {/* </Grid> */}
        </CustomGrid>
    )
}

const CustomGrid = styled(Grid)`
    background-color: #ff9a77;
    padding-top: 20px;
`
