import { CircularProgress, Container, Dialog, Grid } from '@material-ui/core'
import 'firebase/firestore'
import 'firebase/storage'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import NavBar from '../component/navBar'
import useStyles from '../component/style'
import * as Patches from './src'
import { IPatchInfoPage, PatchBase } from './src/_base'
import PatchTable from './table'

export default function PatchPage(props: any) {
    const classes = useStyles()
    const [open, setOpen] = React.useState<boolean>(false)
    const [pathList, setPathList] = useState<IPatchInfoPage[]>([
        {
            patchName: '',
            detail: '',
            version: 0,
            createDate: '',
            patch: async () => {},
        },
    ])

    useEffect(() => {
        getPatchList()
    }, [])

    const handleClose = () => {
        setOpen(false)
    }

    const getPatchList = () => {
        const keys = _.keys(Patches)
        const patchList = _.map(keys, (key) => {
            const patch = new (Patches as any)[key]() as PatchBase
            const info = patch.getInfo()
            return {
                ...info,
                patch: patch.patch,
            }
        })
        setPathList(patchList)
    }

    return (
        <Container>
            <NavBar title={`Patch`} />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Container component="main">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {PatchTable('Patch', pathList)}
                        </Grid>
                    </Grid>
                </Container>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <CircularProgress style={{ margin: '20px' }} />
                </Dialog>
            </main>
        </Container>
    )
}
