import { CircularProgress, Container, Dialog, Grid } from '@material-ui/core'
import 'firebase/firestore'
import 'firebase/storage'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import firebase from '../../firebase'
import NavBar from '../component/navBar'
import useStyles from '../component/style'
import FacebookPageDetailReportTable from './table'

interface IReportDetailParam {
    pageId: string
}

interface IReportDetailPage extends BasicStat, Stock, UserOrder, Order {
    pageId: string
    postId: string
    date: string | ''
    link: {
        text: string
        link: string
    }
}

type Timestamp = {
    seconds: number
    nanoseconds: number
}

type Stock = {
    postPath: string
    liveStocks: number
}

type Order = {
    postPath: string
    orderDocCount: number
}

type BasicStat = {
    postPath: string
    liveViewer: number
    liveDuration: number
    income: number
    allOrderCount: number
    orderCount: number //cf
    userOrderCount: number //buyer
}
type UserOrder = {
    postPath: string
    userOrderDocCount: number
    orderSummary: number
    sumTransferWaitingOrder: number
    sumTransferdOrder: number
    sumComfirmTransferOrder: number
    sumShippingFee: number
    sumIncomeAndShippingFree: number
}

export default function ReportDetailPage(props: any) {
    const classes = useStyles()
    const [open, setOpen] = React.useState<boolean>(false)
    const [
        reportDetailParam,
        setReportDetailParam,
    ] = useState<IReportDetailParam>({
        pageId: '',
    })

    const [details, setDetails] = useState<IReportDetailPage[]>([
        {
            pageId: '',
            postId: '',
            postPath: '',
            date: '',
            liveViewer: 0,
            liveDuration: 0,
            income: 0,
            orderCount: 0,
            allOrderCount: 0,
            userOrderCount: 0,
            orderSummary: 0,
            liveStocks: 0,
            orderDocCount: 0,
            userOrderDocCount: 0,
            sumTransferWaitingOrder: 0,
            sumTransferdOrder: 0,
            sumComfirmTransferOrder: 0,
            sumShippingFee: 0,
            sumIncomeAndShippingFree: 0,
            link: {
                link: '',
                text: '',
            },
        },
    ])

    useEffect(() => {
        handleClickOpen()
        const { pageId } = props.match.params
        setReportDetailParam({ pageId })
        fetchData(pageId)
    }, [])

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const converTimestampToDateString = (timestamp: Timestamp) => {
        return timestamp
            ? moment(timestamp.seconds * 1000).format('YYYY-MM-DD HH:mm:ss')
            : ''
    }
    const getStat = async (postPath: string) => {
        const statData = await firebase
            .firestore()
            .doc(`${postPath}/stats/basicStats`)
            .get()

        return {
            postPath,
            ...statData.data(),
        }
    }

    const getStock = async (postPath: string) => {
        const stockData = await firebase
            .firestore()
            .collection(`${postPath}/liveStocks`)
            .get()

        return {
            postPath,
            liveStocks: _.chain(stockData.docs) //stockData.size
                .map((doc) => doc.data())
                .sumBy('amount')
                .value(),
        }
    }

    const getUserOrder = async (postPath: string) => {
        const queryResult = await firebase
            .firestore()
            .collection(`${postPath}/userOrders`)
            .get()
        const userOrders = _.map(queryResult.docs, (doc) => doc.data())
        const userOrderDocCount = userOrders.length
        const orderSummary = _.sumBy(userOrders, 'orderSummary')
        const sumShippingFee = _.sumBy(userOrders, 'shippingFee')
        const partitions = _.partition(
            userOrders,
            (order) => order.status === 'transferWaiting',
        )
        const [transferWaitingOrders, transferedOrders] = partitions
        const sumTransferWaitingOrder = _.sumBy(
            transferWaitingOrders,
            'saleSummary', //'totalPrice',
        )

        const comfirmTransferStatusList = ['shippingWaiting', 'complete'] // แม่ค้ายืนยัน
        const transferdStatusList = ['transferred', 'confirmWaiting'].concat(
            comfirmTransferStatusList,
        )
        const sumComfirmTransferOrder = _.chain(transferedOrders)
            .filter((order) =>
                _.includes(comfirmTransferStatusList, order.status),
            )
            .sumBy('saleSummary') //'totalPrice')
            .value()

        const sumTransferdOrder = _.chain(transferedOrders)
            .filter((order) => _.includes(transferdStatusList, order.status))
            .sumBy('saleSummary')
            .value()

        const sumIncomeAndShippingFree = _.sum([
            sumShippingFee,
            sumTransferWaitingOrder,
            sumTransferdOrder,
        ])

        return {
            postPath,
            userOrderDocCount,
            orderSummary,
            sumTransferWaitingOrder,
            sumTransferdOrder,
            sumComfirmTransferOrder,
            sumShippingFee,
            sumIncomeAndShippingFree,
        }
    }

    const getOrder = async (postPath: string) => {
        const orderData = await firebase
            .firestore()
            .collection(`${postPath}/orders`)
            .get()
        return {
            postPath,
            orderDocCount: orderData.size,
        }
    }

    const convertAllNumberToCurrency = (arr: any[]) => {
        return _.map(arr, (v) => {
            for (let k in v) {
                if (!isNaN(v[k])) {
                    v[k] = v[k].toLocaleString('en-US')
                }
            }
            return v
        })
    }

    const fetchData = useCallback((pageId: string) => {
        firebase
            .firestore()
            .collection(`pages/${pageId}/lives`)
            .onSnapshot(async (querySnapshot) => {
                let reportDetails: any[] = []
                let liveData = _.chain(querySnapshot.docs)
                    .map((doc) => {
                        const postId = doc.id
                        const liveStartTime: Timestamp = doc.data()
                            .liveStartTime
                        return {
                            postPath: doc.ref.path,
                            postId,
                            date: converTimestampToDateString(liveStartTime),
                            link: {
                                text: 'link',
                                link: `http://www.facebook.com/${postId}`,
                            },
                        }
                    })
                    .orderBy('date', 'desc')
                    .value()
                const postDocRefPaths = _.map(liveData, 'postPath')

                let basicStats: BasicStat[] = []
                const _basicStats = Promise.all(
                    _.map(postDocRefPaths, (path) => getStat(path)),
                ) as Promise<BasicStat[]>
                _basicStats.then((data) => (basicStats = data))

                let stocks: Stock[] = []
                const _stocks = Promise.all(
                    _.map(postDocRefPaths, (path) => getStock(path)),
                ) as Promise<Stock[]>
                _stocks.then((data) => (stocks = data))

                let userOrders: UserOrder[] = []
                const _userOrders = Promise.all(
                    _.map(postDocRefPaths, (path) => getUserOrder(path)),
                ) as Promise<UserOrder[]>
                _userOrders.then((data) => (userOrders = data))

                let orders: Order[] = []
                const _orders = Promise.all(
                    _.map(postDocRefPaths, (path) => getOrder(path)),
                ) as Promise<Order[]>
                _orders.then((data) => (orders = data))

                await Promise.all([_basicStats, _stocks, _userOrders, _orders])

                const mergeData = _.merge(
                    _.keyBy(liveData, 'postPath'),
                    _.keyBy(basicStats, 'postPath'),
                    _.keyBy(stocks, 'postPath'),
                    _.keyBy(userOrders, 'postPath'),
                    _.keyBy(orders, 'postPath'),
                )

                reportDetails = _.values(mergeData)

                const undefineList = [undefined, NaN, null]

                _.forEach(reportDetails, (detail) => {
                    // Case of backward compatible
                    if (undefineList.includes(detail.allOrderCount)) {
                        detail['allOrderCount'] = detail.orderDocCount
                    }
                    // Case of bug data
                    detail.orderCount = detail.orderSummary
                    if (undefineList.includes(detail.userOrderCount)) {
                        detail['userOrderCount'] = _.get(
                            detail,
                            'userOrderDocCount',
                            0,
                        )
                    }
                })

                setDetails(convertAllNumberToCurrency(reportDetails))
                handleClose()
            })
    }, [])

    const exportFilename = `MelonLive_Report-${reportDetailParam.pageId}`

    return (
        <Container>
            <NavBar title={`Report page id: ${reportDetailParam.pageId} `} />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Container component="main">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {FacebookPageDetailReportTable(
                                'Report Detail',
                                details,
                                exportFilename,
                            )}
                        </Grid>
                    </Grid>
                </Container>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <CircularProgress style={{ margin: '20px' }} />
                </Dialog>
            </main>
        </Container>
    )
}
