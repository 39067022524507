import React from 'react'
import { Done } from '@material-ui/icons'
import useStyles from '../style/style'
import productDelivering from '../../../images/product-delivering.svg'
import { Grid } from '@material-ui/core'
import { StatusEnum } from '../../../enum/statusEnum'

export default function TrackingStatusComponent(props: any) {
    const classes = useStyles()

    const shippingWaitingButton = () => {
        return (
            <div
                className={classes.deliveryStatusIcon}
                style={{ backgroundColor: '#FF8900' }}
            >
                <img
                    src={productDelivering}
                    alt="product delivering icon"
                    style={{ marginRight: '4px' }}
                />
                <p className={classes.deliveryStatusTextIcon}>
                    กำลังเตรียมสินค้า
                </p>
            </div>
        )
    }

    const completeButton = () => {
        return (
            <div
                className={classes.deliveryStatusIcon}
                style={{ backgroundColor: '#23B73A' }}
            >
                <Done style={{ fill: '#FFFFFF', marginRight: '4px' }} />
                <p className={classes.deliveryStatusTextIcon}>จัดส่งแล้ว</p>
            </div>
        )
    }

    return (
        <Grid item xs={12} className={classes.cardSpaceing}>
            <div className={classes.deliveryStatus}>
                <div>
                    <p className={classes.deliveryStatusText}>
                        ทางเราขอแจ้งให้ทราบว่า
                    </p>
                </div>
                {props.activeStep === StatusEnum.SHIPPINGWAITING ? (
                    shippingWaitingButton()
                ) : props.activeStep === StatusEnum.COMPLETE ? (
                    completeButton()
                ) : (
                    <div />
                )}
            </div>
        </Grid>
    )
}
