import { Container, Grid } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import React from 'react';
import styled from 'styled-components';
import completeImg from '../../images/thankyou/completeImg.png';
import DownloadHeaderComponent from '../trackingPage/component/downloadHeaderComponent';
import useStyles from './style/style';

export default function ThankYouPage() {
    const classes = useStyles()
    return (
        <Container className={classes.bgColor}>
            <DownloadHeaderComponent />
            <Grid container>
                <Grid item xs={12} className={titleClasses(classes)}>
                    ชำระสินค้าเรียบร้อยแล้ว
                </Grid>
                <WrapImg>
                    <CompleteImg />
                </WrapImg>
            </Grid>
        </Container>
    )
}

const titleClasses = (classes: ClassNameMap) => [
    classes.center,
    classes.textCenter,
    classes.textTitle,
    classes.cardSpaceing,
].join(' ')

const WrapImg = styled.div`
    margin:70px auto 0 auto;
    height: 70vh;
    display: flex;
    justify-content: center;
    @media (max-width: ${(props) => props.theme.breakpoint.lg}px) {
       margin: 20% auto 0 auto;
       height: 55vh;
    }
`

const CompleteImg = styled.img.attrs({
    src: `${completeImg}`,
    alt: "pay complete"
})`
    max-height: 60%;
`;
