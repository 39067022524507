import { Button, Container, makeStyles } from '@material-ui/core';
import React from 'react';
import { StatusEnum } from '../../../enum/statusEnum';
import { IContact } from '../../../model/IContact';
import { PaymentMethodType } from '../../../utils/paymentMethodType';

const useStyles = makeStyles({
  rootContainer: {
    margin: '15px 0px',
    padding: '15px 0px'
  },
  submitButton: {
    color: '#FFFFFF',
    borderColor: '#FF5252',
    backgroundColor: '#FF5252',
    borderRadius: '50px',
    fontFamily: 'kittithada',
    fontSize: '30px',
    '&:hover': {
      backgroundColor: '#FF5252',
    },
    '&:disabled': {
      backgroundColor: '#C8C7CE',
      color: '#FFFFFF',
    },
  }

});

interface ISubmitButton {
  submitUserData: () => void,
  activeStep: StatusEnum,
  contact: IContact,
  errContactFormMsg: IContact,
  slipList: string[]
  email: string
  selectedPaymentMethod: string
}

export default function SubmitButtonComponent(props: ISubmitButton) {
  const classes = useStyles();

  const isHasContact = (): boolean => {
    const { contact } = props
    if (!contact) return false
    const { address, name, postCode, province, tel, district, subdistrict } = contact;
    return !!(address && name && postCode && province && tel && district && subdistrict)

  }

  const hasNoError = (): boolean => {
    const { errContactFormMsg } = props;
    const {
      address: errAddress,
      name: errName,
      postCode: errPostCode,
      province: errProvince,
      tel: errTel,
    } = errContactFormMsg;

    return (errAddress || errName || errPostCode || errTel || errProvince) ===
      ''
  }

  const hasSlipList = (): boolean => {
    const { slipList } = props;
    const isUploadingSlip = slipList.some(s => s == 'loading');
    return slipList.length > 0 && !isUploadingSlip
  }

  const hasEmail = (): boolean => {
    const { email } = props
    return !!(email)
  }

  const isDisableSubmitButton = () => {
    return props.selectedPaymentMethod === PaymentMethodType.Bank.value
      ? !(isHasContact() && hasNoError() && hasSlipList())
      : !(isHasContact() && hasNoError() && hasEmail())
  };

  const renderButtonLabel = () => {
    let result: String = ''

    switch (props.activeStep) {
      case StatusEnum.TRANSFERWAITING:
      case StatusEnum.TRANSFERINVALID:
        result = 'ส่งข้อมูลให้ร้านค้า'
        break;
      case StatusEnum.TRANSFERRED:
        result = 'แก้ไขข้อมูล และส่งให้ร้านค้าอีกครั้ง'
        break;
    }

    return result;
  }

  return (
    <Container disableGutters className={classes.rootContainer}>
      <Button
        variant="contained"
        style={{ width: '100%' }}
        onClick={() => props.submitUserData()}
        className={classes.submitButton}
        disabled={isDisableSubmitButton()}
      >
        {renderButtonLabel()}
      </Button>
    </Container>
  )
}
