import { Button, Grid, IconButton, Snackbar } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { useEffect, useRef, useState } from 'react'
import copyIcon from '../../../images/copyIcon.svg'
import { ITracking } from '../../../model/ITracking'
import shippingData from '../../../static/shipping.json'
import useStyles from '../style/style'

export default function ShippingComponent(props: any) {
    const classes = useStyles()
    const trackingNumberRef = useRef<any>(null)
    const [showClipboardPopup, setShowClipboardPopup] = useState<boolean>(false)

    const tracking: ITracking = props.tracking
    const pageId: String = props.pageId

    useEffect(() => {
        console.log(`tracking: ${JSON.stringify(props.tracking)}`)
    }, [])

    const copyToClipboard = () => {
        setShowClipboardPopup(true)
        const textField = document.createElement('textarea')
        textField.innerText = trackingNumberRef.current.innerText
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setShowClipboardPopup(false)
    }

    const renderShippingIcon = (tracking: ITracking) => {
        const shipping = tracking.isShippop
            ? shippingData.find((item) => item.id === 'SHIPPOP')
            : (tracking.id !== null)
                ? shippingData.find((item) => item.id === tracking.id)
                : null

        return shipping ? (
            <div
                className={[
                    classes.deliveryHeadingLayout,
                    classes.cardBorder,
                ].join(' ')}
            >
                <img
                    src={require(`../../../${shipping?.icon}`)}
                    alt={`${shipping?.name} icon`}
                    style={{ width: '64px', marginRight: '8px' }}
                />
                <p className={classes.deliverName}>{shipping?.name}</p>
            </div>
        ) : null
    }

    const renderPageContactLink = () => {
        return (
            <div>
                <p className={classes.trackingCheckTitle}>
                    สามารถติดตามพัสดุได้ที่เพจร้าน :
                </p>
                <a
                    className={classes.trackingCheckLink}
                    href={`https://www.facebook.com/${pageId}`}
                >
                    https://www.facebook.com/{pageId}
                </a>
            </div>
        )
    }

    const renderShippingURL = (tracking: ITracking) => {
        let shippingModel = shippingData.find((item) => item.id === (tracking.isShippop ? 'SHIPPOP' : tracking.id))

        if (!shippingModel?.url) return null

        let shippingUrl;
        switch (shippingModel.id) {
            case 'SHIPPOP':
                shippingUrl = `${shippingModel.url}?tracking_code=${tracking.trackingNumber}`
                break;
            default:
                shippingUrl = shippingModel.url
                break;
        }

        return (
            <div>
                <p className={classes.trackingCheckTitle}>
                    สามารถติดตามพัสดุได้ที่ :
                    </p>
                <a className={classes.trackingCheckLink} href={shippingUrl} target="_blank">
                    {shippingUrl}
                </a>
            </div>
        )
    }

    return (
        <>
            <Grid item xs={12} className={classes.cardSpaceing}>
                {renderShippingIcon(tracking)}
                <div className={classes.deliveryTrackingLayout}>
                    <p
                        className={classes.trackingNumber}
                        ref={trackingNumberRef}
                    >
                        {tracking.trackingNumber}
                    </p>

                    {tracking.id != null ? (
                        <Button
                            variant="contained"
                            size="small"
                            className={classes.copyBtn}
                            onClick={() => copyToClipboard()}
                        >
                            <img
                                src={copyIcon}
                                className={classes.copyIconStyle}
                                alt="copy icon"
                            />{' '}
                            <span className={classes.copyIconText}>คัดลอก</span>
                        </Button>
                    ) : (
                        <div />
                    )}
                </div>
                {tracking.id == null
                    ? renderPageContactLink()
                    : renderShippingURL(tracking)}
            </Grid>
            <Snackbar
                open={showClipboardPopup}
                autoHideDuration={2000}
                onClose={closeSnackbar}
                message="คัดลอกแล้ว"
                action={
                    <IconButton color="inherit" onClick={closeSnackbar}>
                        <Close />
                    </IconButton>
                }
            />
        </>
    )
}
