import LandingPage from './landingPage/landingPage'
import LoginPage from './loginPage/loginPage'
import ManageImagesPage from './manageImagesPage/manageImagesPage'
import PatchPage from './patchPage'
import PaySolutionPage from './paySolutionPage/paySolutionPage'
import PolicyPage from './policy/policyPage'
import RegisterOnlineServiceExpirePage from './registerOnlineServiceExpirePage/registerOnlineServiceExpirePage'
import RegisterOnlineServicePage from './registerOnlineServicePage/registerOnlineServicePage'
import RegisterOnlineServiceSuccessPage from './registerOnlineServiceSuccessPage/registerOnlineServiceSuccessPage'
import ReportDetailPage from './reportDetailPage'
import ReportPage from './reportListPage'
import ThankyouPage from './thankyouPage/thankyouPage'
import TrackingPage from './trackingPage/trackingPage'

export {
    LandingPage,
    LoginPage,
    ManageImagesPage,
    PolicyPage,
    TrackingPage,
    RegisterOnlineServicePage,
    RegisterOnlineServiceExpirePage,
    RegisterOnlineServiceSuccessPage,
    ReportPage,
    ReportDetailPage,
    PatchPage,
    PaySolutionPage,
    ThankyouPage,
}
