import { PatchBase } from './_base'
import firebase from '../../../firebase'
import 'firebase/firestore'
import 'firebase/storage'
import _ from 'lodash'

type pageStatDataType = {
    [pageId: string]: {
        paid: number
        unpaid: number
        incomeTotal: number
        live: {
            [livePath: string]: {
                paid: number
                unpaid: number
                incomeTotal: number
            }
        }
    }
}

// transferWaiting,
//   transferred,
//   shippingWaiting,
//   confirmWaiting,
//   reserve,
//   complete,
//   canceled,

export class PatchPageAndLiveStat extends PatchBase {
    patchName = 'update page live stat all page'
    detail = 'calculate incomeToal paid, unpaid '
    createDate = '2021-01-29'
    version = 1
    cancelStatusList = ['canceled']
    unpaidStatusList = [
        'transferWaiting',
        'reserve',
        'confirmWaiting',
        'transferred',
    ]
    paidStatusList = ['shippingWaiting', 'complete']

    patch = async () => {
        const snapshot = await firebase
            .firestore()
            .collectionGroup('userOrders')
            .get()
        const pageData = _.reduce(
            snapshot.docs,
            (res, doc, index) => {
                const data = doc.data()
                const pageId = doc.id.split('_')[0]
                const livePath = _.get(doc, 'ref.parent.parent.path', '')
                const saleSummary = data.saleSummary || 0
                const incomeTotal = saleSummary + (data.shippingFee || 0)
                if (
                    !doc.exists ||
                    !data.status ||
                    this.cancelStatusList.includes(data.status)
                ) {
                    return res
                }
                if (!res[pageId]) {
                    res[pageId] = {
                        paid: 0,
                        unpaid: 0,
                        incomeTotal: 0,
                        live: {},
                    }
                }
                if (!res[pageId].live[livePath]) {
                    res[pageId].live[livePath] = {
                        paid: 0,
                        unpaid: 0,
                        incomeTotal: 0,
                    }
                }

                if (this.unpaidStatusList.includes(data.status)) {
                    res[pageId].unpaid += saleSummary
                    res[pageId].live[livePath].unpaid += saleSummary
                } else if (this.paidStatusList.includes(data.status)) {
                    res[pageId].paid += saleSummary
                    res[pageId].live[livePath].paid += saleSummary
                }
                res[pageId].incomeTotal += incomeTotal
                res[pageId].live[livePath].incomeTotal += incomeTotal
                return res
            },
            {} as pageStatDataType,
        )
        await this.updateLiveStat(pageData)
        await this.updatePageStat(pageData)
        console.log('path PatchPageAndLiveStat success')
    }

    updateLiveStat = async (pageDataList: pageStatDataType) => {
        for (const pageId in pageDataList) {
            const pageData = pageDataList[pageId]
            await Promise.all(
                _.map(_.keys(pageData.live), (livePath) => {
                    const liveData = pageData.live[livePath]
                    const { paid, unpaid, incomeTotal } = liveData
                    return firebase
                        .firestore()
                        .doc(`${livePath}/stats/basicStats`)
                        .set({ paid, unpaid, incomeTotal }, { merge: true })
                }),
            )
        }
    }

    updatePageStat = async (pageDataList: pageStatDataType) => {
        await Promise.all(
            _.map(_.keys(pageDataList), (pageId) => {
                const pageData = pageDataList[pageId]
                const pageBasicStatsPath = `pages/${pageId}/stats/basicStats`
                const { paid, unpaid, incomeTotal } = pageData
                return firebase
                    .firestore()
                    .doc(pageBasicStatsPath)
                    .set({ paid, unpaid, incomeTotal }, { merge: true })
            }),
        )
    }
}
