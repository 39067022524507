import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  TextField
} from '@material-ui/core';
import { LocalShipping } from '@material-ui/icons';
import React from 'react';
import MaskedInput from 'react-text-mask';
import provinceData from '../../../static/province.json';
import useStyles from '../style/style';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function TelephoneMask(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
}

function PostCodeMask(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
      guide={false}
    />
  );
}

export default function CustomerContactComponent(props: any) {
  const classes = useStyles();
  const {
    contact,
    errContactFormMsg,
    setContactData,
    setErrContactFormMsg,
  } = props;

  const validateValue = (event: any) => {
    const { target } = event;
    const { id, value } = target;
    let temp = {};
    if (value) {
      if (id === 'tel' && value.length > 0 && value.length !== 12) {

        temp = {
          [id]: 'กรุณากรอกข้อมูลให้ถูกต้อง',
        };
        setErrContactFormMsg({ ...props.errContactFormMsg, ...temp });

      } else if (id === 'postCode' && value.length > 0 && value.length !== 5) {

        temp = {
          [id]: 'กรุณากรอกข้อมูลให้ถูกต้อง',
        };
        setErrContactFormMsg({ ...props.errContactFormMsg, ...temp });

      } else {

        temp = {
          [id]: '',
        };
        setErrContactFormMsg({ ...props.errContactFormMsg, ...temp });
      }

    } else {
      temp = {
        [id]: 'จำเป็นต้องกรอกข้อมูลส่วนนี้',
      };
      setErrContactFormMsg({ ...props.errContactFormMsg, ...temp });
    }
  };

  return (
    <Grid item xs={12} className={classes.cardSpaceing}>
      <Card className={[classes.root, classes.wrapperLayout].join(' ')}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.iconShipping}>
              {' '}
              <LocalShipping style={{ fill: '#5CB4FF' }} />
            </Avatar>
          }
          title={<span className={classes.cardTitle}>ที่อยู่ในการจัดส่ง</span>}
        />
        <CardContent>
          <form>
            <TextField
              id="name"
              name="name"
              label="ชื่อผู้รับ"
              value={contact ? contact.name : null}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) => (setContactData(e.target), validateValue(e))}
              onBlur={(e) => validateValue(e)}
              error={errContactFormMsg.name !== ''}
              helperText={errContactFormMsg.name}
            />
            <TextField
              id="tel"
              name="tel"
              label="เบอร์ติดต่อ"
              value={contact ? contact.tel : null}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) => (setContactData(e.target), validateValue(e))}
              onBlur={(e) => validateValue(e)}
              error={errContactFormMsg.tel !== ''}
              helperText={errContactFormMsg.tel}
              InputProps={{
                inputComponent: TelephoneMask as any,
              }}
            />
            <TextField
              id="address"
              name="address"
              label="ที่อยู่"
              value={contact ? contact.address : null}
              fullWidth
              multiline
              margin="normal"
              variant="outlined"
              rows={3}
              onChange={(e) => (setContactData(e.target), validateValue(e))}
              onBlur={(e) => validateValue(e)}
              error={errContactFormMsg.address !== ''}
              helperText={errContactFormMsg.address}
            />
            <div>
              <TextField
                id="district"
                name="district"
                label="อำเภอ"
                value={contact?.district}
                margin="normal"
                variant="outlined"
                style={{ width: '40%' }}
                onChange={(e) => (setContactData(e.target), validateValue(e))}
                onBlur={(e) => validateValue(e)}
                error={errContactFormMsg.district !== ''}
                helperText={errContactFormMsg.district}
              />
              <TextField
                id="subdistrict"
                name="subdistrict"
                label="ตำบล"
                value={contact && contact.subdistrict}
                margin="normal"
                variant="outlined"
                style={{ width: 'calc(60% - 12px)', marginLeft: '12px' }}
                onChange={(e) => (setContactData(e.target), validateValue(e))}
                onBlur={(e) => validateValue(e)}
                error={errContactFormMsg.subdistrict !== ''}
                helperText={errContactFormMsg.subdistrict}
              />
            </div>
            <div>
              <TextField
                id="postCode"
                name="postCode"
                label="รหัสไปรษณีย์"
                value={contact ? contact.postCode : null}
                margin="normal"
                variant="outlined"
                style={{ width: '40%' }}
                onChange={(e) => (setContactData(e.target), validateValue(e))}
                onBlur={(e) => validateValue(e)}
                error={errContactFormMsg.postCode !== ''}
                helperText={errContactFormMsg.postCode}
                InputProps={{
                  inputComponent: PostCodeMask as any,
                }}
              />
              <TextField
                id="province"
                name="province"
                select
                label="เลือกจังหวัด"
                value={contact && contact.province}
                margin="normal"
                variant="outlined"
                style={{ width: 'calc(60% - 12px)', marginLeft: '12px' }}
                onChange={(e) => setContactData(e.target)}
                onBlur={(e) => validateValue(e)}
                error={false}
                helperText={errContactFormMsg.province}
              >
                {provinceData.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </form>
        </CardContent>
      </Card>
    </Grid>
  );
}
