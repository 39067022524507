import React from 'react';
import { BounceLoader } from 'react-spinners';
import styled from 'styled-components';

export default function FullScreenLoading() {
    return (
        <>
            <ContainerFullScreenLoading>
                <WrapLoadingIcon><BounceLoader color={'#FF5252'} loading />
                </WrapLoadingIcon>
            </ContainerFullScreenLoading>
        </>
    )
}

const ContainerFullScreenLoading = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.25);
    z-index: 2;
`

const WrapLoadingIcon = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%)
`
