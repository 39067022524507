import axios from 'axios'
import { IUserRegis } from '../models/RegisModel'

export async function getUserRegisData(key: string | null): Promise<any> {

    try {
        // TODO config/firebase
        const url = 'https://asia-east2-livemote-293ae.cloudfunctions.net/v1-getRegisterOnlineServiceData'
        const requestBody = {
            key
        }
        const { data: { data } } = await axios.post(url, requestBody)
        return data
    } catch (error) {
        console.log('error', JSON.stringify(error));
        throw error
    }

}


export async function submitUserRegis(key: string | null, userData: IUserRegis): Promise<any> {
    try {
        const url = 'https://asia-east2-livemote-293ae.cloudfunctions.net/v1-submitRegisterOnlineservice'
        const headers = {
            ['authorization']: `bearer ${key}`,
        }
        const { data: { data } } = await axios.post(url, userData, { headers })
        return data
    } catch (error) {
        console.log('error', JSON.stringify(error));
        throw error
    }
}
