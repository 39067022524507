import { Button, CircularProgress, Dialog } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {
    AddBox,
    ArrowDownward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    SaveAlt,
    Search,
} from '@material-ui/icons'
import 'firebase/functions'
import MaterialTable, { Icons } from 'material-table'
// @ts-ignore
import pdfMake from 'pdfmake-thai/build/pdfmake'
// @ts-ignore
import pdfFonts from 'pdfmake-thai/build/vfs_fonts'
import React, { forwardRef, useEffect } from 'react'

const tableIcons: Icons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
    )),
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
            display: 'flex',
        },
    }),
)

export default function PatchTable(title: string, dataArray: any[]) {
    const classes = useStyles()
    const [data, setData] = React.useState(dataArray)
    const [open, setOpen] = React.useState(false)

    useEffect(() => {
        setData(dataArray)
    }, [dataArray])

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    pdfMake.vfs = pdfFonts.pdfMake.vfs
    pdfMake.fonts = {
        THSarabunNew: {
            normal: 'THSarabunNew.ttf',
            bold: 'THSarabunNew Bold.ttf',
            italics: 'THSarabunNew Italic.ttf',
            bolditalics: 'THSarabunNew BoldItalic.ttf',
        },
        Roboto: {
            normal: 'Roboto-Regular.ttf',
            bold: 'Roboto-Medium.ttf',
            italics: 'Roboto-Italic.ttf',
            bolditalics: 'Roboto-MediumItalic.ttf',
        },
    }

    const onClick = async (data: any) => {
        handleClickOpen()
        await data.patch()
        handleClose()
    }

    return (
        <div>
            <MaterialTable
                title={title}
                columns={[
                    { field: 'patchName', title: 'Name' },
                    { field: 'detail', title: 'Detail' },
                    { field: 'version', title: 'Version' },
                    { field: 'createDate', title: 'CreateDate' }, //จำนวนสินค้าที่ถูกสั่งซื้อ จำนวนสินค้า ที่ตกลงขาย
                ]}
                data={dataArray}
                icons={tableIcons}
                options={{
                    pageSize: 20,
                    actionsColumnIndex: -1,
                }}
                localization={{
                    header: {
                        actions: 'Action',
                    },
                }}
                actions={[
                    {
                        icon: (props: any) => {
                            return (
                                <div className={classes.root}>
                                    <Button variant="outlined" color="primary">
                                        PATCH
                                    </Button>
                                </div>
                            )
                        },
                        tooltip: 'use Patch',
                        onClick: (event, rowData) => onClick(rowData),
                    },
                ]}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CircularProgress style={{ margin: '20px' }} />
            </Dialog>
        </div>
    )
}
