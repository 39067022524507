import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        width: '100%',
        margin: '0px !important',
        padding: '0px !important',
        maxWidth: 'unset !important'
    }
}))

export default useStyles;