import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bgColor: { backgroundColor: '#ffffff' },
  root: {
    width: '100%',
    fontFamily: 'PslTextNewPro',
  },
  title: {
    width: '100%',
    fontSize: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    width: '100%',
    fontSize: 14,
  },
  textCenter: {
    textAlign: 'center',
  },
  textColor: {
    color: '#3C3B40',
  },
  secondaryTextColor: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  textTitle: {
    color: '#3C3B40',
    fontSize: '32px',
    fontFamily: 'kittithada',
  },
  subTextTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#9C9BA2',
    lineHeight: '1',
    fontFamily: 'PslTextNewPro',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconProduct: {
    backgroundColor: '#FFEBE8',
  },
  iconShipping: {
    backgroundColor: '#DDEFFF',
  },
  iconBanking: {
    backgroundColor: '#D6F7DB',
  },
  media: {
    width: 60,
    height: 60,
  },
  wrapperLayout: {
    // width: 'calc(100% + 32px)',
    // margin: '0 -16px',
  },
  headingContent: {
    padding: '24px 0',
  },
  copyBtn: {
    borderRadius: '16px',
    border: '1px solid #c8c7ce',
    boxShadow: 'none',
    backgroundColor: '#F8F8F9',
  },
  cardBorder: {
    borderBottom: '1px solid #E6E6E6',
  },
  orderProductLayout: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#3C3B40',
  },
  cardProductLayout: {
    display: 'grid',
    gridTemplateColumns: '60px calc(100% - 60px)',
    padding: '16px 0',
    borderBottom: '1px solid #E6E6E6',
  },
  cardProductDetail: {
    fontFamily: 'PslTextNewPro',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingLeft: '16px',
  },
  productSummaryPrice: {
    color: '#FD5252',
    fontSize: '34px',
  },
  bankDetailContent: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#3C3B40',
  },
  bankIconWrapper: {
    fontWeight: 'normal',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'PslTextNewPro',
    fontSize: '30px',
  },
  bankContactText: {
    fontFamily: 'PslTextNewPro',
    fontSize: '34px',
    fontWeight: 'bold',
  },
  bankAccountWrapper: {
    border: '1px solid #E6E6E6',
    borderRadius: '24px',
    padding: '16px',
    marginBottom: '16px',
  },
  bankNumberWrapper: {
    padding: '8px 0',
    marginBottom: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  copyIconStyle: {
    width: '12px',
    marginRight: '4px',
  },
  copyIconText: {
    fontFamily: 'PslTextNewPro',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  btnPaymentStyle: {
    backgroundColor: '#FFFFFF',
    padding: '24px 16px',
    marginTop: '10px',
  },
  btnPayment: {
    color: '#FF5252',
    borderColor: '#FF5252',
    borderRadius: '24px',
    fontFamily: 'kittithada',
    fontSize: '24px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #FF5252',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  btnPrimary: {
    color: '#FFFFFF',
    borderColor: '#FF5252',
    backgroundColor: '#FF5252',
    borderRadius: '50px',
    fontFamily: 'kittithada',
    fontSize: '30px',
    '&:hover': {
      backgroundColor: '#FF5252',
    },
    '&:disabled': {
      backgroundColor: '#C8C7CE',
      color: '#FFFFFF',
    },
  },
  contactText: {
    fontSize: '24px',
    color: 'rgba(0, 0, 0, 0.87)',
    margin: '0',
    marginBottom: '10px',
    lineHeight: '1',
  },
  deliveryStatus: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  deliveryStatusText: {
    fontFamily: 'PslTextNewPro',
    fontSize: '24px',
    color: '#6B6A71',
    margin: '0',
    marginBottom: '4px',
  },
  deliveryStatusIcon: {
    display: 'flex',
    alignItems: 'center',
    color: '#FFFFFF',
    fontSize: '14px',
    padding: '6px 12px',
    borderRadius: '4px',
  },
  deliveryStatusTextIcon: {
    margin: '0',
    fontSize: '18px',
    fontWeight: 'bold',
    fontFamily: 'PslTextNewPro',
  },
  deliveryHeadingLayout: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 0',
    marginBottom: '16px',
  },
  deliveryTrackingLayout: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  deliverName: {
    fontSize: '30px',
    fontFamily: 'kittithada',
    margin: '0',
  },
  trackingNumber: {
    margin: '0',
    fontSize: '30px',
    fontFamily: 'kittithada',
  },
  trackingCheckTitle: {
    fontFamily: 'PslTextNewPro',
    fontWeight: 'bold',
    fontSize: '24px',
    color: '#6B6A71',
    marginTop: '8px',
    margin: '0',
  },
  trackingCheckLink: {
    fontSize: '24px',
    color: '#1792E6',
    textDecoration: 'underline',
    fontFamily: 'PslTextNewPro',
  },
  cardSpaceing: {
    paddingTop: '10px',
  },
  cardTitle: {
    fontSize: '24px',
    fontFamily: 'kittithada',
  },
  customTypographyText: {
    fontSize: '24px',
    fontFamily: 'PslTextNewPro',
  },
  slipImgCard: {
    display: 'grid',
    gridTemplateColumns: 'calc(100% - 48px) 48px',
    alignItems: 'center',
    width: 'calc(100% - 16px)',
    margin: '0 8px',
    marginBottom: '8px',
    padding: '8px',
    border: '1px solid #d9d9d9',
    borderRadius: '4px',
  },
  slipImgBtn: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0',
  },
  annotation: {
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    fontSize: '24px',
    fontFamily: 'PslTextNewPro',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  paymentMethodDropdownLabel: {
    marginBottom: '5px',
    fontSize: '24px',
    fontFamily: 'PslTextNewPro',
  },
  paymentMethodDropdownContainer: {
    padding: '0px 15px',
  },
  annotationIcon: {
    marginBottom: '1px',
    fontSize: '1rem',
    verticalAlign: 'middle',
    display: 'inline-flex'
  },
}));

export default useStyles;
