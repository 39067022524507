import { Modal } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import modalWarningMsg from '../../../images/modalConfirmPayment/modal-warning-msg-v2.png'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '400px',
            width: 'calc(100% - 32px)',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '12px',
            margin: 0,
            padding: '32px 28px',
        },
        title: {
            textAlign: 'center',
            margin: '0',
            color: '#3C3B40',
            fontFamily: 'kittithada',
            fontWeight: 'normal',
            fontSize: '34px',
            lineHeight: '1',
            marginBottom: '12px',
        },
        description: {
            textAlign: 'center',
            margin: '0',
            color: '#6B6A71',
            fontFamily: 'PslTextNewPro',
            fontSize: '24px',
            fontWeight: 'bold',
            lineHeight: '1',
        },
        customButtom: {
            borderRadius: '24px',
            border: '1px solid',
            padding: '10px 0',
            width: '100%',
            cursor: 'pointer',
        },
        warningMessageImageContainer: {
            display: 'bloc',
            padding: '16px 0.5rem',
        },
        warningDescription: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'PslTextNewPro',
            fontWeight: 'bold',
            fontSize: '30px',
            color: '#3C3B40',
            lineHeight: '1',
        },
        contactPage: {
            padding: '10px 33px',
            fontFamily: 'kittithada',
            fontWeight: 'normal',
            fontSize: '24px',
            color: '#1792E6',
            lineHeight: '1',
            wordBreak: 'break-all',
            textAlign: 'center',
        },
        confirmBtnContainer: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '20px',
        },
        confirmBtn: {
            fontFamily: 'kittithada',
            fontWeight: 'normal',
            fontSize: '24px',
            backgroundColor: '#FF561C',
            color: '#FFFFFF',
            borderColor: '#FF561C',
            '&:disabled': {
                backgroundColor: '#eb9797',
                color: '#e9e6e6',
                borderColor: '#eb9797',
            },
            '&:hover': {
                boxShadow: '0 0 11px rgba(33,33,33,.2)',
            },
        },
        buttonProgress: {
            color: '#E5F7FF',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    }),
)

export default function SimpleModal(props: any) {
    const classes = useStyles()
    const { isOpenInboxPageModal, setIsOpenInboxPageModal, pageId } = props

    const closeModal = () => {
        setIsOpenInboxPageModal(false)
    }

    const body = (
        <div className={classes.paper}>
            <h2 className={classes.title}>ยืนยันส่งข้อมูล</h2>
            <div className={classes.description}>
                <div>ระบบจะส่งข้อมูลไปยังร้านค้า</div>
                <div>เพื่อดำเนินการเตรียมสินค้าและจัดส่งต่อไป</div>
            </div>
            <div className={classes.warningMessageImageContainer}>
                <img
                    src={modalWarningMsg}
                    alt="Warning message"
                    style={{ width: '100%' }}
                />
            </div>
            <div className={classes.warningDescription}>
                <span>
                    อย่าลืมทักแชทร้านค้า
                    <br />
                    เพื่อติดตาม เลขพัสดุ
                </span>
            </div>
            <div className={classes.contactPage}>
                <a href={`https://www.facebook.com/${pageId}`}>
                    https://www.facebook.com/{pageId}
                </a>
            </div>
            <div className={classes.confirmBtnContainer}>
                <button
                    style={{ position: 'relative' }}
                    onClick={() => closeModal()}
                    color="primary"
                    className={[classes.customButtom, classes.confirmBtn].join(
                        ' ',
                    )}
                >
                    ตกลง
                </button>
            </div>
        </div>
    )

    return <Modal open={isOpenInboxPageModal}>{body}</Modal>
}
