import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import expireImg from '../../images/registerOnlineService/expire-img.png';

export default function RegisterOnlineServiceExpirePage() {
    const GlobalStyle = createGlobalStyle`
    body {
        background-color: #ffffff !important;
    }
`
    return (
        <>
            <GlobalStyle />
            <Container>
                <ContentWrapper>
                    <p className="content-title">ลิงก์หมดอายุ</p>
                    <LogoImg src={expireImg} alt="expire" width="227" height="233" />
                    <p className="content-desc">กรุณาเปิดลิงก์นี้</p>
                    <p className="content-desc">ผ่านทางแอปพลิเคชัน Melon LIVE</p>
                </ContentWrapper>
            </Container>
        </>
    )
}

const Container = styled.div`
    font-family: 'kittithada';
    padding: 16px;
    position: relative;
    height: 100vh;
    width: 100%;
`

const ContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .content-title {
      font-size: 32px;
      color: #3C3B40;
      margin: 0;
      text-align: center;
      line-height: 1;
  }
  .content-desc {
    font-size: 24px;
      color: #9C9BA2;
      margin: 0;
      text-align: center;
      line-height: 1;
  }
`

const LogoImg = styled.img`
    margin-top: 16px;
    margin-bottom: 24px;
`
