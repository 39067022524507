import React from "react";
import styled from 'styled-components';
import { InputRegis, IRegisState, ProvinceField, WebsiteField } from "../shareds/inputFields";
import { PostCodeMask, TelephoneMask } from "../shareds/inputMasks";

type PropsType = {
    groupRegisState: IRegisState
    provinceData: any[]
}

export default function FormFieldComponent(props: PropsType) {
    const { groupRegisState, provinceData } = props
    const { setRegisState, regisState: userRegisData, errorState: errMessage } = groupRegisState
    return (
        <>
            <InputRegis label={'ชื่อบุคคล/ชื่อบริษัท'} name='registerName'
                regisValue={userRegisData.registerName}
                errMessageTxt={errMessage.registerName}
                state={groupRegisState} />

            <InputRegis label={'ชื่อผู้ติดต่อ'} name='contactName'
                regisValue={userRegisData.contactName}
                errMessageTxt={errMessage.contactName}
                state={groupRegisState} />

            <InputRegis label={'เบอร์โทรศัพท์'} name='telephone'
                regisValue={userRegisData.telephone}
                errMessageTxt={errMessage.telephone}
                state={groupRegisState}
                type={'tel'} inputMode={'tel'}
                InputProps={{ inputComponent: TelephoneMask as any }} />

            <InputRegis label={'อีเมล'} name='email'
                regisValue={userRegisData.email}
                errMessageTxt={errMessage.email}
                state={groupRegisState}
                type={'email'} inputMode={'email'} />

            <WebsiteField websiteFacebookPage={userRegisData.websiteFacebookPage} />
            <Divider />

            <InputRegis label={'ที่อยู่'} name='address'
                regisValue={userRegisData.address}
                errMessageTxt={errMessage.address}
                state={groupRegisState} />

            <InputRegis md={6} label={'แขวง/ตำบล'} name='subDistrict'
                regisValue={userRegisData.subDistrict}
                errMessageTxt={errMessage.subDistrict}
                state={groupRegisState} />

            <InputRegis md={6} label={'เขต/อำเภอ'} name='district'
                regisValue={userRegisData.district}
                errMessageTxt={errMessage.district}
                state={groupRegisState} />

            <ProvinceField userRegisData={userRegisData}
                provinceData={provinceData}
                setRegisState={setRegisState} />

            <InputRegis md={6} label={'รหัสไปรษณีย์'} name='postcode'
                regisValue={userRegisData.postcode}
                errMessageTxt={errMessage.postcode}
                state={groupRegisState}
                inputMode={'tel'}
                InputProps={{ inputComponent: PostCodeMask as any }} />
        </>
    )
}

const Divider = styled.div`
    margin: 32px 0;
    background-color: #D5D5D5;
    width: 100%;
    height: 1px;
    @media (max-width: ${(props) => props.theme.breakpoint.md}px) {
        margin: 24px 0;
    }
`