import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { IUserRegis } from '../models/RegisModel';

export interface ICheckboxProps {
    regisState: IUserRegis
    setRegisState: React.Dispatch<React.SetStateAction<IUserRegis>>
    tempLastCheckbox: boolean
    imgUrl: string
    checkboxValue: boolean
    label: string
    name: string
    targetCheckbox: string
}

export const CheckboxRegis = (props: ICheckboxProps) => {
    const { regisState, targetCheckbox, setRegisState,
        tempLastCheckbox, checkboxValue, imgUrl, label, name } = props;

    return (
        <div>
            <CustomFormControlLabel
                control={<Checkbox
                    checked={checkboxValue}
                    disabled={tempLastCheckbox}
                    onChange={(e) => setRegisState({ ...regisState, [targetCheckbox]: e.target.checked })}
                    name={name}
                />
                }
                label={<CustomCheckboxLabel>
                    <span>{label}</span>
                    <img className={name} src={imgUrl} alt={name} />
                </CustomCheckboxLabel>}
            />
        </div>
    );
}

const CustomFormControlLabel = styled(FormControlLabel)`
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #FF9A77;
    }
    .MuiCheckbox-colorSecondary.Mui-disabled {
        color: rgba(0, 0, 0, 0.26);
    }
`
const CustomCheckboxLabel = styled.div`
    display: flex;
    align-items: center;
    span {
        font-family: 'kittithada';
        color: #3C3B40;
        font-size: 24px;
        margin-right: 8px;
        line-height: 1;
    }
    .shippop {
        width: 64px;
        height: 15px;
    }
    .paySolution{
        width: 48px;
        height: 48px;
    }
    @media (max-width: ${(props) => props.theme.breakpoint.md}px) {
        span {
            font-family: 'kittithada';
            color: #3C3B40;
            font-size: 22px;
            margin-right: 8px;
        }
    }
`