import firebase from 'firebase/app'
import firebaseConfig from './config/firebase'
import 'firebase/analytics'

firebase.initializeApp(firebaseConfig)

export let isFirebaseAuthed = false

export function initFirebaseUser() {
    getUserAuthedFromLocalStorage()
    subcribeFirebaseUser()
}

function subcribeFirebaseUser() {
    firebase.auth().onAuthStateChanged(function (user) {
        if (user && !isFirebaseAuthed) {
            isFirebaseAuthed = true
            localStorage.setItem('isFirebaseAuthed', 'true')
        } else if (!user && isFirebaseAuthed) {
            isFirebaseAuthed = false
            localStorage.setItem('isFirebaseAuthed', 'false')
        }
    })
}

export function getUserAuthedFromLocalStorage() {
    isFirebaseAuthed = localStorage.getItem('isFirebaseAuthed') == 'true'
}

export function firebaseAnalytic() {
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig)
        // Check that `window` is in scope for the analytics module!
        if (typeof window !== 'undefined') {
            // To enable analytics. https://firebase.google.com/docs/analytics/get-started
            if ('measurementId' in firebaseConfig) {
                firebase.analytics()
                firebase.performance()
            }
        }
    }
}

export default firebase
