import { CircularProgress, Container, Dialog, Grid } from '@material-ui/core'
import { firestore } from 'firebase'
import 'firebase/firestore'
import 'firebase/storage'
import React, { useCallback, useEffect, useState } from 'react'
import firebase from '../../firebase'
import { IImageProduct } from '../../model/IImageProduct'
import NavBar from '../component/navBar'
import useStyles from '../component/style'
import ImageTable from './table'

export default function ManageImagesPage() {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)

    const [imageProduct, setImageProduct] = useState([
        {
            imagePath: '',
            name: '',
            docRef: null,
        },
    ])

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const getData = useCallback(
        async (
            docData: firestore.QueryDocumentSnapshot,
        ): Promise<IImageProduct> => {
            const docRef: firestore.DocumentReference = docData.ref
            const data = docData.data()
            const imageUrl =
                data.imagePath != null ? await getUrlImage(data.imagePath) : ''
            return {
                imagePath: imageUrl,
                name: data.name,
                docRef: docRef,
            }
        },
        [],
    )

    const getStocks = useCallback(() => {
        firebase
            .firestore()
            .collectionGroup('stocks')
            .where('isDeleted', '==', false)
            .limit(50)
            .onSnapshot(async function (querySnapshot) {
                let imageProductArray: any[] = []
                for (let doc of querySnapshot.docs) {
                    const imageProductData: IImageProduct = await getData(doc)
                    imageProductArray.push(imageProductData)
                }
                imageProductArray = imageProductArray.filter(
                    (data: IImageProduct) => data.imagePath !== '',
                )
                setImageProduct(imageProductArray)
                handleClose()
            })
    }, [getData])

    useEffect(() => {
        handleClickOpen()
        getStocks()
    }, [getStocks])

    const getUrlImage = async (path: string): Promise<string> => {
        try {
            const storage = firebase.storage()
            const pathReference = storage.ref(path)
            const url = await pathReference.getDownloadURL()
            return url
        } catch (error) {
            return ''
        }
    }

    return (
        <Container>
            <NavBar title={'Manage Product Images'} />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Container component="main">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {ImageTable('Manage Product Images', imageProduct)}
                        </Grid>
                    </Grid>
                </Container>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <CircularProgress style={{ margin: '20px' }} />
                </Dialog>
            </main>
        </Container>
    )
}
