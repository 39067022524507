import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '400px',
      width: '100%',
      margin: 0,
      padding: '0 16px',
      borderRadius: '12px',
      '&:focus': {
        outline: 'none',
      },
    },
    img: {
      display: 'flex',
      maxWidth: '100%',
      maxHeight: '70vh',
      borderRadius: '12px',
      margin: 'auto',
    },
  }),
);

export default function ModalSlipItemComponent(props: any) {
  const [isOpen, setIsOpen] = useState(true);
  const classes = useStyles();
  const {
    imageUrl,
    defaultUrlImage,
    isSlipModalOpen,
    setIsSlipModalOpen,
  } = props;

  const closeModal = () => {
    setIsSlipModalOpen(false);
  };

  const body = (
    <div className={classes.paper} onClick={() => closeModal()}>
      <img
        className={classes.img}
        src={imageUrl ? imageUrl : defaultUrlImage}
      />
    </div>
  );

  return (
    <Modal
      open={isSlipModalOpen}
      onClick={() => setIsOpen(true)}
      onClose={() => closeModal()}
    >
      {body}
    </Modal>
  );
}
