import { Typography } from '@material-ui/core';
import React from 'react';
import stepIconStyles from '../../style/stepIconStyles';
import clsx from 'clsx';
import { Check } from '@material-ui/icons';

function StepIcon(props: any) {
  const classes = stepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <div className={classes.circleComplete}>
          <Check className={classes.completed} />
        </div>
      ) : (
        <div className={classes.circle}>
          <Typography className={classes.text}>{props.icon}</Typography>
        </div>
      )}
    </div>
  );
}
export default StepIcon;
