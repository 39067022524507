import { Grid, Step, StepLabel, Stepper } from '@material-ui/core';
import React, { useEffect } from 'react';
import useStyles from '../style/style';
import ColorlibConnector from './step/colorlibConnector';
import StepIcon from './step/stepIcon';

function getSteps() {
  return [1, 2, 3];
}

export default function StepComponent(props: any) {
  const classes = useStyles();
  const steps = getSteps();

  console.log(`props.activeStep : ${props.activeStep}`);

  return (
    <Grid item xs={12}>
      <Stepper
        className={classes.wrapperLayout}
        alternativeLabel
        activeStep={props.activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={StepIcon}></StepLabel>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
}
