import { CardMedia, IconButton, Typography } from '@material-ui/core';
import { DeleteOutlined } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { MoonLoader } from 'react-spinners';
import { StatusEnum } from '../../../enum/statusEnum';
import firebase from '../../../firebase';
import useStyles from '../style/style';
import ModalSlipItemComponent from './modalSlipItemComponent';

export default function SlipItem(props: any) {
  const classes = useStyles();

  const [imageUrl, setImageUrl] = React.useState<string>();
  const [isSlipModalOpen, setIsSlipModalOpen] = React.useState<boolean>(false);
  const defaultUrlImage =
    'https://firebasestorage.googleapis.com/v0/b/livemote-293ae.appspot.com/o/image_default.png?alt=media&token=551f408f-b28b-4a63-83f5-37bbba7f636d';

  useEffect(() => {
    if (props.data !== 'loading') getImageUrlFromFirebase(props.data);
  }, [props]);

  const getImageUrlFromFirebase = async (data: any) => {
    if (!data) return;
    const imageUrl = await getImageUrl(data);
    setImageUrl(imageUrl);
  };

  const getImageUrl = async (path: string): Promise<string> => {
    try {
      const storage = firebase.storage();
      const pathReference = storage.ref(path);
      const url = await pathReference.getDownloadURL();
      return url;
    } catch (error) {
      return '';
    }
  };

  return (
    <>
      {props.data === 'loading' ? (
        <div
          className={classes.slipImgCard}
          style={
            props.activeStep !== 2 && props.activeStep !== 3
              ? {}
              : { gridTemplateColumns: '100%' }
          }
        >
          <div style={{ paddingRight: '16px' }}>
            <div className={classes.slipImgBtn}>
              <div style={{ marginRight: '16px' }}>
                <MoonLoader size={40} color={'#FF5252'} loading />
              </div>
              <Typography
                component="p"
                variant="caption"
                style={{ width: '100%' }}
              >
                <Skeleton width="100%" />
              </Typography>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={classes.slipImgCard}
          style={
            props.activeStep !== 2 && props.activeStep !== 3
              ? {}
              : { gridTemplateColumns: '100%' }
          }
        >
          <ModalSlipItemComponent
            imageUrl={imageUrl}
            defaultUrlImage={defaultUrlImage}
            isSlipModalOpen={isSlipModalOpen}
            setIsSlipModalOpen={setIsSlipModalOpen}
          />
          <div style={{ paddingRight: '16px' }}>
            <div
              className={classes.slipImgBtn}
              onClick={() => setIsSlipModalOpen(true)}
            >
              <CardMedia
                className={classes.media}
                style={{ marginRight: '8px' }}
                image={imageUrl ? imageUrl : defaultUrlImage}
              />
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%',
                  fontSize: '20px',
                }}
              >
                {props.data && props.data.split('/').slice(-1)[0]}
              </span>
            </div>
          </div>
          {props.activeStep === StatusEnum.TRANSFERWAITING && props.activeStep === StatusEnum.TRANSFERINVALID ? (
            <IconButton
              color="primary"
              component="button"
              onClick={() => props.deleteSlipImg(props.data)}
            >
              <DeleteOutlined
                style={{ color: 'rgba(0,0,0,.45)', fontSize: '18px' }}
              />
            </IconButton>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
}
