import React from 'react'
import { Container } from '@material-ui/core'
import styled from 'styled-components'
import bgOverlay from '../../images/landing/bg_bottom_left.png'

document.body.style.backgroundColor = '#FF9A77'

export default function PolicyPage() {
    return (
        <BgContainer>
            <BgOverlay src={bgOverlay} />
            <Container>
                <Content>
                    <Title>ข้อกำหนดและเงื่อนไข</Title>
                    <p>
                        ข้อกำหนดและเงื่อนไขที่ระบุไว้ดังต่อไปนี้
                        (ต่อไปนี้เรียกว่า &ldquo;ข้อตกลง&rdquo;)
                        เป็นสัญญาระหว่าง บริษัท ประณีต จำกัด และบริษัทอื่น ๆ
                        ในเครือ (ต่อไปนี้เรียกว่า &ldquo;Melon Live&rdquo;)
                        ในฐานะผู้ให้บริการเว็บไซต์และแอพพลิเคชั่นต่างๆ ในนามของ
                        Melon Live กับ ผู้ใช้งานหรือสมาชิกของ Melon Live
                        (ต่อไปนี้เรียกว่า &ldquo;ผู้ใช้งาน&rdquo;)
                        ในฐานะผู้ใช้บริการ
                        โปรดอ่านข้อตกลงนี้อย่างละเอียดและครบถ้วน
                    </p>
                    <p>
                        ข้อตกลงนี้กำกับดูแลและกำหนดแนวทางปฏิบัติในการใช้บริการบนเว็บไซต์และแอพพลิเคชั่นต่าง
                        ๆ ของ Melon Live ซึ่งขึ้นอยู่กับนโยบายและดุลพินิจของ
                        Melon Live แต่เพียงฝ่ายเดียวเท่านั้น
                    </p>
                    <p>
                        การใช้งานหรือบริการเว็บไซต์หรือแอพพลิเคชั่นของ Melon
                        Live
                        อยู่ภายใต้เงื่อนไขว่าผู้ใช้งานต้องตกลงยอมรับข้อกำหนดและเงื่อนไขของข้อตกลงนี้
                        โดยปราศจากการเปลี่ยนแปลงหรือยกเว้นใด ๆ
                        ที่แตกต่างไปจากที่ระบุไว้ในข้อตกลงนี้
                        ผู้ใช้งานไม่ถือว่าได้รับอนุญาต (ไม่ว่าทางตรงหรือทางอ้อม)
                        ให้ใช้งานหรือบริการบนเว็บไซต์หรือแอพพลิเคชั่นของ Melon
                        Live
                        เว้นเสียแต่ว่าผู้ใช้บริการได้แสดงเจตนาตกลงยอมรับข้อกำหนดและเงื่อนไขที่ระบุในข้อตกลงนี้ทุกประการก่อนเริ่มต้นใช้งานหรือบริการ
                        หากผู้ใช้งานไม่ยอมรับข้อกำหนดหรือเงื่อนไขต่าง ๆ เหล่านี้
                        โปรดอย่าเข้าสู่หรือใช้งานหรือบริการบนเว็บไซต์หรือแอพพลิเคชั่นของ
                        Melon Live
                    </p>
                    <p>
                        การที่ผู้ใช้งานเข้าถึง การใช้งานหรือบริการ
                        ดาวน์โหลดแอพพลิเคชั่น Melon Live การถ่ายทอดสด
                        (การไลฟ์สด)ลงประกาศ โฆษณา พูดคุย
                        ติดต่อสื่อสารโดยตรงกับผู้ซื้อหรือผู้ขาย สมัครสมาชิก
                        กดเมนูคำสั่งใด ๆ
                        หรือการกดเข้าสู่หน้าถัดไปบนเว็บไซต์หรือแอพพลิเคชั่น
                        Melon Live หรือการกดเมนูเข้าถึงบัญชี FACEBOOK
                        ถือการลงลายมือชื่อทางอิเล็กทรอนิกส์ของผู้ใช้งาน
                        ซึ่งเป็นการแสดงเจตนาของผู้ใช้งานที่เข้าใจอย่างละเอียดและตกลงที่จะปฏิบัติตามข้อกำหนด
                        เงื่อนไขทั้งหมดที่ระบุในข้อตกลงนี้ และระเบียบต่าง ๆ
                        ที่มีตามนโยบายของ Melon Live ทุกประการ
                        โดยการแสดงเจตนาดังกล่าวนี้ก่อให้เกิดความผูกพันตามกฎหมายมีผลสมบูรณ์และเพิกถอนมิได้
                    </p>
                    <p>
                        ข้อกำหนดและเงื่อนไขตามข้อตกลงนี้อาจมีการเปลี่ยนแปลงหรือเพิ่มเติมได้ในภายหลังตามความเห็นสมควรของ
                        Melon Live
                        โดยมิต้องให้คำบอกกล่าวหรือแจ้งเตือนผู้ใช้งานเป็นการล่วงหน้า
                        การเปลี่ยนแปลงหรือเพิ่มเติมข้อกำหนดและเงื่อนไขใด ๆ
                        ถือเป็นส่วนหนึ่งของข้อตกลงนี้และมีผลบังคับโดยทันที
                        ซึ่งจะมีการประกาศในสถานที่ที่เหมาะสมภายในเว็บไซต์และ/หรือแอพพลิเคชั่นของ
                        Melon Live ผู้ใช้งานมีหน้าที่ต้องตรวจสอบการแก้ไข
                        เพิ่มเติม ปรับปรุง และเปลี่ยนแปลงนโยบาย กฎ ข้อกำหนด
                        เงื่อนไข และข้อจำกัดต่าง ๆ ด้วยตนเอง
                        การที่ผู้ใช้งานดำเนินการ
                        ใช้งานหรือบริการเว็บไซต์และ/หรือแอพพลิเคชั่นของ Melon
                        Live ต่อไป
                        ถือว่าผู้ใช้งานดังกล่าวให้ความตกลงยินยอมโดยสมบูรณ์ที่จะปฏิบัติตามข้อกำหนดและเงื่อนไขที่ได้มีการเปลี่ยนแปลงหรือเพิ่มเติมนั้น
                    </p>
                    <p>
                        แพลตฟอร์มเว็บไซต์และแอพพลิเคชั่น Melon Live ทั้งหมด
                        สิทธิ์ในทรัพย์สินทางปัญญาทั้งหมดที่เกี่ยวข้องกับแพลตฟอร์ม
                        บัญชีสมาชิก ข้อมูล เนื้อหาและบริการต่าง ๆ
                        บนเว็บไซต์และแอพพลิเคชั่น Melon Live ถือเป็นสมบัติของ
                        Melon Live แต่เพียงผู้เดียว
                        ผู้ใช้งานเพียงได้รับสิทธิ์แบบจำกัด
                        ที่ไม่จำกัดแต่เพียงผู้เดียว และอาจถูกเพิกถอนได้
                        เว้นแต่จะระบุไว้เป็นอย่างอื่นในข้อตกลงนี้
                        สิทธิ์ในทรัพย์สินทางปัญญาที่เกี่ยวข้องกับเนื้อหาข้อมูลที่ลงประกาศ
                        การถ่ายทอดสด
                        (การไลฟ์สด)โดยผู้ใช้งานเป็นของผู้ใช้งานหรือผู้อนุญาตที่เกี่ยวข้อง
                    </p>
                    <p>
                        ความที่กล่าวมาข้างต้นนี้มีผลใช้บังคับกับในกรณีผู้ใช้งานในนามของหรือเพื่อวัตถุประสงค์ของนายจ้าง
                        องค์กรธุรกิจ ร้านค้า
                        (ไม่ว่าจะจดทะเบียนทางกฎหมายหรือไม่ก็ตาม) ด้วย ในกรณีนี้
                        ผู้ใช้งานรับรองและรับประกันว่าตนมีอำนาจทางกฎหมายในการทำนิติกรรมหรือก่อความผูกพันระหว่างนายจ้าง
                        องค์กรธุรกิจ หรือร้านค้ากับข้อตกลงนี้ การกระทำใด ๆ
                        และการแสดงเจตนาตกลงยินยอมปฏิบัติตามข้อกำหนด เงื่อนไขต่าง
                        ๆ ในข้อตกลงนี้และตามนโยบายของ Melon Live
                        ของผู้ใช้งานดังกล่าวถือว่าได้รับมอบอำนาจกระทำการแทนในนามขององค์กรธุรกิจหรือร้านค้านั้น
                        ๆ
                        โดยการตกลงยินยอมดังกล่าวมีผลสมบูรณ์ตามกฎหมายและเพิกถอนมิได้เช่นกัน
                    </p>
                    <p>
                        ขอบเขตการใช้บริการเว็บไซต์และแอพพลิเคชั่นของ Melon Live
                        ไม่ว่าบางส่วนหรือทั้งหมด
                        ถูกกำหนดและจำกัดพื้นที่การใช้งานหรือบริการเฉพาะภายในประเทศไทยเท่านั้น
                        โดยผู้ใช้งานจะต้องรับผิดชอบจัดหาฮาร์ดแวร์
                        (เครื่องคอมพิวเตอร์ อุปกรณ์โทรศัพท์เคลื่อนที่
                        อุปกรณ์สื่อสาร) และซอฟต์แวร์ (ระบบปฏิบัติการ)
                        ที่สามารถเข้าถึงและเข้ากันได้กับเว็บไซต์และแอพพลิเคชั่นของ
                        Melon Live และความสามารถในการเชื่อมต่ออินเทอร์เน็ต
                        โดยผู้ใช้งานต้องรับผิดชอบในค่าใช้จ่ายต่าง ๆ (หากมี)
                        เป็นการส่วนตัว
                    </p>
                    <h2>
                        <strong>
                            1. ข้อสงวนสิทธิเกี่ยวกับธุรกรรมการซื้อขาย
                        </strong>
                    </h2>
                    <p>
                        1.1 การดำเนินการของ Melon Live
                        คือการบริหารจัดการแพล็ตฟอร์มทางอินเตอร์เน็ต
                        ด้วยการจัดสรรพื้นที่ออนไลน์เพื่อเป็นศูนย์กลางสำหรับการติดต่อสื่อสารโดยตรงระหว่างผู้ใช้งานผ่านทางเว็บไซต์และ/หรือแอพพลิเคชั่นของ
                        Melon Live ในการทำธุรกรรมการซื้อขายหรือบริการ ซึ่งผู้ขาย
                        เจ้าของสินค้าหรือผู้ให้บริการสามารถการถ่ายทอดสด
                        (การไลฟ์สด)ขายสินค้าหรือบริการ
                        และผู้ซื้อสามารถเลือกชมสินค้าหรือบริการที่มีการถ่ายทอดสด
                        (การไลฟ์สด)การลงประกาศ และ
                        ซึ่งจะทำให้ผู้ขายและผู้ซื้อสามารถติดต่อ
                        เจรจาตกลงทำธุรกรรมระหว่างคู่สัญญากันเองได้อย่างเป็นอิสระโดยปราศจากการแทรกแซงของพ่อค้าคนกลางและ
                        Melon Live แต่อย่างใด ดังนั้น Melon Live
                        ไม่มีส่วนเกี่ยวข้องทางนิตินัยและทางพฤตินัยกับผู้ใช้งานทุกฝ่ายในธุรกรรมต่าง
                        ๆ ระหว่างผู้ใช้งานที่เกี่ยวข้อง การถ่ายทอดสด
                        (การไลฟ์สด)การประกาศหรือเสนอซื้อขายสินค้าหรือบริการ
                        การให้หรือความถูกต้องของข้อมูลต่าง ๆ การติดต่อสื่อสาร
                        การเจรจาต่อรอง การจะซื้อจะขาย สัญญาซื้อขาย
                        หรือการจัดจำหน่ายหรือให้บริการระหว่างผู้ใช้งาน
                        กับทั้งไม่มีส่วนในธุรกรรมทางการเงิน การชำระเงิน
                        การชำระหนี้ การตรวจสอบและส่งมอบสินค้า
                        ระหว่างผู้ใช้งานดังกล่าวแต่อย่างใด
                    </p>
                    <p>
                        1.2 ความสัมพันธ์ระหว่าง Melon Live กับผู้ใช้งาน: Melon
                        Live ไม่มีนิติสัมพันธ์กับผู้ใช้งานไม่ว่าในลักษณะตัวแทน
                        นายหน้า หุ้นส่วน นายจ้างลูกจ้าง หรือลักษณะอื่นใด
                        นอกเหนือจากการใช้งานบนเว็บไซต์และแอพพลิเคชั่นของ Melon
                        Live อีกทั้ง Melon Live ไม่ได้แนะนำ
                        เชิญชวนหรือคัดท้ายผู้ใช้งานทุกฝ่ายให้ทำธุรกรรม
                        นิติกรรมหรือสัญญาใด ๆ อีกทั้ง Melon Live
                        ไม่มีหรือได้รับส่วนแบ่งหรือผลประโยชน์อื่นใดจากการขายสินค้าหรือการให้บริการ
                        การถ่ายทอดสด (การไลฟ์สด)การลงประกาศ
                        การเจรจาติดต่อสื่อสาร
                        หรือธุรกรรมการซื้อขายระหว่างผู้ใช้งาน
                        การให้ข้อเสนอทางสัญญาใด ๆ จากผู้ใช้งานทุกฝ่าย
                    </p>
                    <p>
                        1.3 ข้อมูลและรายละเอียดต่าง ๆ
                        เกี่ยวกับการสินค้าและบริการที่แสดงหรือปรากฏในประกาศบนเว็บไซต์และแอพพลิเคชั่นของ
                        Melon Live
                        นั้นได้ถูกจัดเตรียมขึ้นโดยผู้ใช้งานแต่ละรายซึ่งเป็นเจ้าของสินค้า
                        บริการ และการถ่ายทอดสด (การไลฟ์สด)แต่เพียงฝ่ายเดียว
                        ดังนั้น Melon Live ไม่รับรอง ไม่รับประกัน
                        และไม่มีความรับผิดชอบหรือภาระผูกพันใด ๆ
                        ต่อผู้ใช้งานทุกฝ่ายและบุคคลภายนอก
                        ไม่ว่าจะเกี่ยวกับการเจรจาตกลงซื้อขาย การดำเนินธุรกรรม
                        การส่งมอบ การชำระหนี้ รวมทั้งสินค้าและบริการ
                        การถ่ายทอดสด (การไลฟ์สด)การลงประกาศ คุณภาพ ปริมาณ ราคา
                        ระยะเวลา
                        ซึ่งผู้ใช้งานต้องรับผิดชอบต่อความเสียหายที่เกิดขึ้นระหว่างผู้ใช้งานเองทั้งสิ้น
                        และผู้ใช้งานตกลงจะไม่เรียกร้องให้ Melon Live รับผิดชอบ
                        ร่วมรับผิดชอบ หรือมีภาระผูกพันทางกฎหมายหรือตามข้อตกลงนี้
                        ต่อความสูญเสียหรือความเสียหายใด ๆ
                        ที่เกิดขึ้นกับผู้ใช้งานเองหรือต่อบุคคลภายนอกที่เกี่ยวข้อง
                        ในทุกกรณี
                    </p>
                    <p>
                        1.4 เพื่อความปลอดภัย Melon LIVE
                        จะใช้ความพยายามอย่างที่สุดในการจัดระบบที่ได้มาตรฐานระดับสากลของธุรกิจประเภทบริหารจัดการพื้นที่ประกาศซื้อขายออนไลน์
                        ซึ่งการจัดระบบดังกล่าว
                        รวมถึงการให้ข้อมูลและความช่วยเหลือต่าง ๆ
                        แก่ผู้ใช้งานไม่ถือเป็นข้อผูกพันที่ทำให้ Melon LIVE
                        ต้องร่วมรับผิดหรือรับผิดแทนบุคคลใดทั้งสิ้น อย่างไรก็ดี
                        ผู้ใช้งานทุกฝ่ายควรใช้วิจารณญาณและความระมัดระวังเกี่ยวกับการถ่ายทอดสด
                        (การไลฟ์สด)ประกาศสินค้าและบริการบนเว็บไซต์และแอพพลิเคชั่นของ
                        Melon LIVE ตัวอย่างเช่น
                        ความถูกต้องหรือความเป็นจริงของข้อมูลหรือรายละเอียดต่าง ๆ
                        ที่ลงในการถ่ายทอดสด (การไลฟ์สด)ประกาศ
                        การเจรจาติดต่อสื่อสารระหว่างผู้ใช้งานด้วยกันเองก่อนตัดสินใจซื้อขายหรือการทำธุรกรรมต่าง
                        ๆ
                    </p>
                    <h2>
                        <strong>2. กฎระเบียบการใช้งานและการเป็นสมาชิก</strong>
                    </h2>
                    <p>
                        2.1 ผู้ใช้งานบุคคลหมายความถึงบุคคลธรรมดาหรือ
                        นิติบุคคลหรือองค์กรธุรกิจใด ๆ
                        ไม่ว่าจะมีการจดทะเบียนตามกฎหมายหรือไม่ก็ตาม
                        บุคลธรรมดาหรือ
                        นิติบุคคลหรือองค์กรธุรกิจดังกล่าวสามารถสมัคร (ลงทะเบียน)
                        สมาชิกและถือทะเบียนบัญชีสมาชิกของ Melon LIVE
                        โดยผู้ใช้งานยืนยันและรับรองถึงว่าตนมีความสามารถในการทำนิติกรรมและมีอำนาจในการผูกพันกับข้อตกลงนี้
                    </p>
                    <p>
                        2.2 สำหรับผู้เยาว์ (ผู้ซึ่งมีอายุต่ำกว่า 20 ปี)
                        ผู้เยาว์จะต้องได้รับความยินยอมจากบิดามารดาหรือผู้ปกครองโดยชอบด้วยกฎหมายก่อนที่จะเริ่มต้นใช้งานหรือสมัคร
                        (ลงทะเบียน) สมาชิก เว็บไซต์ และ/หรือแอพพลิเคชั่นของ
                        Melon LIVE เท่านั้น
                        ไม่ว่าการใช้งานดังกล่าวจะเอื้อต่อการดำรงชีพตามสมควรและสมแก่ฐานะของผู้เยาว์ก็ตาม
                        Melon LIVE ถือว่าการที่ผู้เยาว์เริ่มต้นใช้งานบนเว็บไซต์
                        และ/หรือแอพพลิเคชั่นของ Melon LIVE ต่อไป
                        ผู้เยาว์ดังกล่าวให้การยืนยันว่าตนได้รับความยินยอมจากบิดามารดาหรือผู้ปกครองตามกฎหมายแล้ว
                        ในกรณีนี้ ระหว่าง Melon LIVE กับ
                        ผู้เยาว์หรือบิดามารดาหรือผู้ปกครองโดยชอบด้วยกฎหมายของผู้เยาว์
                        Melon LIVE ไม่รับผิดชอบต่อสิทธิ ความรับผิด
                        หรือการเรียกร้องใด ๆ
                        อันเป็นผลมาจากการใช้งานหรือการกระทำของผู้เยาว์บนเว็บไซต์
                        และ/หรือแอพพลิเคชั่นของ Melon LIVE
                        หรือสืบเนื่องมาจากข้อโต้แย้งในวุฒิภาวะของผู้เยาว์ต่อนิติกรรมที่ผู้เยาว์มีส่วนเกี่ยวข้อง
                        ซึ่งผู้เยาว์จะต้องรับผิดชอบแต่เพียงผู้เดียว
                    </p>
                    <p>
                        2.3 Melon LIVE
                        สงวนสิทธิในการกำหนดเงื่อนไขและจำกัดขอบเขตพื้นที่ของความสามารถในการสมัครสมาชิกและการใช้งานบนเว็บไซต์
                        และ/หรือแอพพลิเคชั่นของ Melon LIVE
                        เฉพาะแก่ผู้ใช้งานและการใช้งานภายในประเทศไทยเท่านั้น
                        Melon LIVE ไม่อนุญาตการใช้งานบนเว็บไซต์
                        และ/หรือแอพพลิเคชั่นของ Melon LIVE
                        ในพื้นที่อื่นภายนอกประเทศไทยซึ่งถือว่าเป็นการกระทำละเมิดต่อ
                        Melon LIVE
                    </p>
                    <p>
                        2.4 ในการสมัครสมาชิกหรือใช้งานใด ๆ บนเว็บไซต์
                        และ/หรือแอพพลิเคชั่นของ Melon LIVE
                        ผู้ใช้งานจะต้องให้ข้อมูล รายละเอียดต่าง ๆ
                        ซึ่งรวมถึงข้อมูลเพื่อการติดต่อสื่อสารของผู้ใช้
                        ที่ถูกต้อง ครบถ้วน ตามจริง และเป็นปัจจุบัน
                        เฉพาะที่เป็นของตนเท่านั้น ภายในพื้นที่ที่ Melon LIVE
                        ได้จัดเตรียมไว้ หากมีการเปลี่ยนแปลงใด ๆ ของข้อมูล
                        รายละเอียดดังกล่าว
                        ผู้ใช้งานมีหน้าที่ต้องแก้ไขปรับปรุงในบัญชีสมาชิกหรือตามประการต่าง
                        ๆ ของตนให้ถูกต้อง ครบถ้วน ตามจริง และเป็นปัจจุบันเสมอ
                        ความรับผิดหรือความเสียหายใด ๆ (หากมี)
                        ในส่วนนี้ผู้ใช้งานจะรับผิดชอบแต่เพียงผู้เดียว
                        ขั้นตอนการลงทะเบียนบัญชีสมาชิกของ Melon LIVE
                        อาจมีการใช้เทคโนโลยีการตรวจสอบโดยบุคคลที่สาม
                        ซึ่งรวมถึงข้อมูลที่จัดเตรียมโดยเว็บไซต์สื่อสังคมออนไลน์ของบุคคลที่สามเพื่อพยายามรับรองคุณสมบัติของผู้ใช้งาน
                    </p>
                    <p>
                        2.5 Melon LIVE
                        ได้รับความคุ้มครองสิทธิ์ตามกฎหมายแต่เพียงผู้เดียวของบริษัท
                        ประณีต จำกัด ห้ามผู้ใช้งานใช้หรือระบุชื่อ Melon LIVE
                        ในชื่อสมาชิกหรือนามแฝง หรือข้อมูลในการถ่ายทอดสด
                        (การไลฟ์สด)ประกาศ
                        ซึ่งอาจเป็นการสร้างความเข้าใจผิดต่อผู้อื่นว่า Melon LIVE
                        มีส่วนเกี่ยวข้องไม่ว่าโดยตรงหรือทางอ้อม
                    </p>
                    <p>
                        2.6 ตลอดระยะเวลาของการเป็นสมาชิกของ Melon LIVE
                        หรือการใช้งานเว็บไซต์ และ/หรือแอพพลิเคชั่นของ Melon LIVE
                        นั้น ผู้ใช้งานตกลงว่าจะไม่กระทำการใด ๆ
                        อันเป็นการขัดต่อข้อกฎหมาย ศีลธรรม และข้อกำหนดต่าง ๆ
                        ตามนโยบายของ Melon LIVE รวมทั้งจะไม่กระทำการใด ๆ
                        ซึ่งถือเป็นการละเมิดสิทธิใด ๆ ของ Melon LIVE
                        หรือบุคคลอื่น หรืออาจจะก่อให้เกิดความเสียหายต่อ Melon
                        LIVE หรือบุคคลอื่น
                    </p>
                    <p>
                        2.7 บัญชีสมาชิกของ Melon LIVE
                        ตามข้อตกลงนี้ถือเป็นทรัพย์สินของ Melon LIVE
                        แต่เพียงผู้เดียว
                        ผู้ใช้งานได้รับอนุญาตที่จำกัดเฉพาะการถือบัญชีสมาชิกในนามของตนเท่านั้น
                        โดยบัญชีสมาชิกไม่สามารถถือร่วม โอน
                        แลกเปลี่ยนหรือเปลี่ยนแปลงให้บุคคลอื่นได้ Melon LIVE
                        ห้ามผู้ใช้งานจำหน่าย ให้เช่า ให้ยืม อนุญาตให้ใช้
                        หรือให้สิทธิบัญชีสมาชิก Melon LIVE
                        ของตนแก่บุคคลอื่นไม่ว่าจะด้วยเหตุผลใด ๆ ก็ตาม
                    </p>
                    <p>
                        2.8 นอกเหนือจากที่กำหนดในข้อตกลงนี้
                        ผู้ใช้งานมีหน้าที่และความรับผิดชอบตามกฎหมายอื่น ๆ
                        ที่ใช้บังคับซึ่งเกี่ยวข้องกับการการถ่ายทอดสด
                        (การไลฟ์สด)ประกาศ เผยแพร่
                        โฆษณาสินค้าหรือบริการบนเว็บไซต์และ/หรือแอพพลิเคชั่นของ
                        Melon LIVE ต่อ Melon LIVE และบุคคลอื่น
                        การยุติข้อโต้แย้งและข้อพิพาทต่าง ๆ
                        ตามข้อตกลงนี้ขึ้นอยู่กับดุลพินิจและการตัดสินใจของ Melon
                        LIVE แต่เพียงผู้เดียว โดยหลักจรรยาบรรณใดๆ
                        ไม่สามารถนำมาใช้บังคับกับการบริการที่เกี่ยวข้องภายใต้ข้อตกลงนี้
                    </p>
                    <p>
                        2.9
                        ผู้ใช้งานมีหน้าที่รับผิดชอบในการปกป้องรักษาความเป็นส่วนบุคคลหรือความลับ
                        ชื่อผู้ใช้งาน และรหัสผ่าน
                        หากมีข้อสงสัยว่ามีการเข้าถึงบัญชีสมาชิกของผู้ใช้งานหรือ
                        ชื่อผู้ใช้งาน หรือรหัสผ่านอาจถูกบุกรุกโดยไม่ได้รับอนุญาต
                        ผู้ใช้งานต้องแจ้งหรือติดต่อ{' '}
                        <a href="mailto:hello@melonlive.shop">
                            hello@melonlive.shop
                        </a>{' '}
                        ทันที
                    </p>
                    <p>
                        2.10 ระหว่าง Melon LIVE กับผู้ใช้งานหรือบุคคลภายนอก
                        Melon LIVE ไม่มีความรับผิดชอบใด ๆ
                        ตามกฎหมายและตามข้อตกลงนี้ต่อผู้ใช้งานหรือบุคคลภายนอก
                        ในความเสียหาย ละเมิดสิทธิหรือสูญหายในลักษณะใดก็ตาม
                        อันเป็นผลจากหรือซึ่งเกี่ยวข้องกับการใช้งานหรือบริการใด ๆ
                        การเปลี่ยนแปลงแก้ไขหรือการส่งรับข้อมูล
                        นิติกรรมหรือธุรกรรมใด ๆ
                        ที่เกิดขึ้นระหว่างผู้ใช้งานด้วยกันเองหรือบุคคลภายนอกบนเว็บไซต์
                        และ/หรือแอพพลิเคชั่นของ Melon LIVE
                    </p>
                    <p>
                        2.11 ผู้ใช้งานตระหนักดีว่าการใช้
                        การให้หรือเผยแพร่ข้อมูลหรือรายละเอียดใด ๆ
                        ของผู้อื่นโดยปราศจากอำนาจ ไม่ได้รับอนุญาต ไม่เป็นจริง
                        ไม่เป็นปัจจุบัน
                        หรือการไม่ปฏิบัติตามหลักเกณฑ์ของกฎหมายเกี่ยวกับการถ่ายทอดสด
                        (การไลฟ์สด)การประกาศสินค้าหรือบริการบนเว็บไซต์ และ/หรือ
                        แอพพลิเคชั่นถือเป็นการกระทำความผิดตามกฎหมายที่เกี่ยวข้องและละเมิดข้อตกลงนี้
                        ถึงแม้ว่า Melon LIVE
                        ไม่มีภาระหน้าที่และความรับผิดชอบในการตรวจสอบความถูกต้องของข้อมูลเนื้อหาต่าง
                        ๆ ของการถ่ายทอดสด (การไลฟ์สด) การประกาศ
                        ไม่ว่าบางส่วนหรือทั้งหมด หาก Melon LIVE
                        ทราบหรือพิจารณาตามดุลยพินิจของ Melon LIVE
                        แต่เพียงผู้เดียวว่าผู้ใช้งานรายใด
                        ละเมิดระเบียบข้อบังคับตามกฎหมายที่เกี่ยวข้อง
                        ละเมิดข้อตกลงนี้หรือนโยบายที่เกี่ยวข้องของ Melon LIVE
                        (ที่อาจมีการแก้ไข เปลี่ยนแปลง
                        หรือเพิ่มเติมเป็นครั้งคราว) ละเมิดสิทธิความเป็นเจ้าของ
                        หรือก่อให้เกิดความรับผิดต่อหรือมีผลเสียต่อ Melon LIVE
                        หรือบุคคลอื่น
                        ในกรณีนี้ผู้ใช้งานมีความรับผิดเป็นการส่วนตัวแต่เพียงผู้เดียวตามกฎหมาย
                        โดยยืนยันว่า Melon LIVE
                        ไม่มีส่วนเกี่ยวข้องกับการกระทำความผิดของผู้ใช้งาน
                        อย่างไรก็ดี ผู้ใช้งานตกลงที่จะรับผิดชอบต่อ Melon LIVE
                        ในความสูญเสีย ค่าเสียหาย ค่าใช้จ่ายอื่น ๆ
                        ไม่ว่าทั้งหมดหรือบางส่วน ซึ่ง Melon LIVE อาจเรียกร้องได้
                    </p>
                    <p>
                        2.12 หากมีเหตุอันควรเชื่อว่าผู้ใช้งานกระทำการละเมิดต่อ
                        Melon LIVE หรือบุคคลอื่น Melon LIVE
                        ขอสงวนสิทธิที่จะใช้ดุลยพินิจแต่เพียงฝ่ายเดียวในการปิดกั้นการเข้าสู่ระบบหรือลบบัญชีสมาชิกของผู้ใช้งานได้ตลอดเวลาไม่ว่าด้วยเหตุผลใดก็ตาม
                        โดยมิต้องบอกกล่าวล่วงหน้าและแจ้งให้ทราบถึงเหตุผล
                        ซึ่งถือเป็นสิทธิขาด
                        โดยผู้ใช้งานตกลงจะไม่เรียกร้องค่าเสียหายใด ๆ จาก Melon
                        LIVE
                    </p>
                    <p>
                        2.13 Melon LIVE ขอสงวนสิทธิในการโอนเว็บไซต์ Melon LIVE
                        และ Melon LIVE แอพพลิเคชั่น หรือบริการทุกประเภทของ Melon
                        LIVE รวมทั้งสิทธิและหน้าที่ของ Melon LIVE
                        บัญชีและข้อมูลของผู้ใช้งาน ไม่ว่าทั้งหมดหรือบางส่วน
                        ให้แก่บริษัทในเครือหรือบุคคลภายนอก
                        โดยไม่ต้องบอกกล่าวล่วงหน้าหรือได้รับอนุญาตจากผู้ใช้งาน
                        Melon LIVE
                    </p>
                    <h2>
                        <strong>3. นโยบายความเป็นส่วนตัว</strong>
                    </h2>
                    <p>
                        บริษัท ประณีต จำกัด (&ldquo;บริษัทฯ/เรา&rdquo;)
                        และบริษัทในเครือ
                        ซึ่งประกอบกิจการและกำกับดูแลตลาดออนไลน์แพลตฟอร์มภายใต้ชื่อ
                        &ldquo;Melon LIVE&rdquo; ในประเทศไทยแต่เพียงผู้เดียว
                        คำนึงถึงมาตราการปกป้องข้อมูลในทุกรูปแบบที่บริษัทฯ
                        มีในครอบครองทั้งหมดตามกฎหมายต่าง ๆ ที่เกี่ยวข้อง
                    </p>
                    <p>
                        นโยบายความเป็นส่วนตัว (&ldquo;นโยบาย&rdquo;) นี้
                        ชี้แจงรายละเอียดเกี่ยวกับการได้มา การใช้/ประมวลผล
                        และการเก็บรักษา ข้อมูลของผู้ใช้บริการ Melon LIVE
                        ของบริษัท ฯ เท่านั้น
                        โดยไม่รวมถึงบริษัทในเครือของเราซึ่งมีรูปแบบธุรกิจที่แตกต่างกันจะมีนโยบายความเป็นส่วนตัวของตนเองแยกต่างหาก
                    </p>
                    <p>
                        บริษัท ฯ
                        ให้ความสำคัญเกี่ยวกับความโปร่งใสในการดำเนินธุรกิจและการให้บริการต่อสมาชิกหรือผู้ใช้บริการ
                        Melon LIVE ในฐานะเจ้าของข้อมูล (&ldquo;คุณ&rdquo;)
                        นโยบายความเป็นส่วนตัวนี้ใช้บังคับเกี่ยวกับข้อมูลที่บริษัท
                        ฯ เก็บรวบรวมและรักษาไว้ โดยอธิบายครอบคลุมถึงประเภท
                        วัตถุประสงค์
                        และวิธีปฏิบัติเกี่ยวกับการใช้ประมวลผลและการเก็บรักษาข้อมูลต่าง
                        ๆ อันได้แก่ ไอพีแอดเดรส (IP Address) คุกกี้
                        และ/หรือข้อมูลส่วนบุคคลที่คุณมอบให้ผ่านเว็บไซต์และแอพพลิเคชั่นของบริษัท
                        ฯ ขณะที่ลงทะเบียนและใช้บริการ Melon LIVE ซึ่งรวมถึง
                        ข้อมูล Facebook ของคุณ
                    </p>
                    <p>
                        คุณมีหน้าที่ต้องอ่านและรับทราบนโยบายนี้ทั้งหมดก่อนที่จะให้ข้อมูลใด
                        ๆ กับบริษัท ฯ การใช้เว็บไซต์หรือแอพพลิเคชั่น Melon LIVE
                        หรือลงทะเบียนการขอใช้บริการและกิจกรรมอื่น ๆ ของเรา
                        โดยคุณจะต้องให้ความยินยอมผ่านการแสดงเจตนาโดยชัดแจ้งหรือโดยนัยในการอนุญาตให้บริษัท
                        ฯ รวบรวม
                        ใช้และเก็บรักษาข้อมูลของคุณตามที่กำหนดไว้ตามนโยบายนี้
                    </p>
                    <p>
                        หากคุณมีข้อสงสัยเกี่ยวกับแนวทางปฏิบัติตามนโยบายความเป็นส่วนตัวนี้
                        รวมถึงข้อกังวลหรือข้อร้องเรียนเกี่ยวกับข้อมูลของคุณประการใด
                        โปรดติดต่อโดยส่งเป็นจดหมายหรือคำร้องผ่านทางอีเมลมาที่
                        <a href="mailto:hello@melonlive.shop">
                            hello@melonlive.shop
                        </a>
                    </p>
                    <h2>
                        <strong>
                            4. ข้อสงวนสิทธิเกี่ยวกับทรัพย์สินทางปัญญา
                        </strong>
                    </h2>
                    <p>
                        4.1 Melon LIVE โดยบริษัท ประณีต
                        จำกัดและบริษัทในเครือเป็นผู้ถือกรรมสิทธิ์หรือเจ้าของแต่เพียงผู้เดียวตามรายละเอียดดังต่อไปนี้
                    </p>
                    <ul>
                        <li>
                            ข้อความ รูปภาพ ภาพเคลื่อนไหว เสียง เนื้อหาข้อมูล
                            และส่วนประกอบใดๆ
                            ทั้งหมดที่ปรากฏบนเว็บไซต์และแอพพลิเคชั่นของ Melon
                            LIVE และรวมถึงซอฟท์แวร์ โปรแกรมคอมพิวเตอร์ในระบบของ
                            Melon LIVE ถือเป็นงานอันมีลิขสิทธิ์ของ Melon LIVE
                        </li>
                        <li>
                            สิทธิในเครื่องหมายการค้า เครื่องหมายบริการ ชื่อ
                            ชื่อทางการค้า สิทธิบัตร โนว์ฮาว โดเมนเนม
                            หรือสิทธิอื่นในลักษณะเดียวกัน
                            ซึ่งจัดเป็นงานอันได้รับความคุ้มครองตามกฎหมายทรัพย์สินทางปัญญาและกฎหมายอื่นที่เกี่ยวข้อง
                            ถือเป็นทรัพย์สินทางปัญญาโดยชอบด้วยกฎหมายของ Melon
                            LIVE แต่เพียงผู้เดียว
                        </li>
                    </ul>
                    <p>
                        4.2 ห้ามผู้ใดทำซ้ำ คัดลอก ลอกเลียน ดัดแปลง ปลอมแปลง
                        จัดเผยแพร่ จำหน่าย ให้เช่า เข้าครอบครอง เรียกดึงข้อมูล
                        บันทึก ส่งผ่าน หรือกระทำการใดๆ
                        เกี่ยวกับสิทธิและทรัพย์สินทางปัญญาของ Melon LIVE
                        โดยไม่ชอบด้วยกฎหมาย
                        หรือโดยไม่ได้รับอนุญาตอย่างเป็นทางการจาก Melon LIVE
                        หรือโดยก่อให้เกิดความเสียหายแก่ Melon LIVE
                        อันเป็นการกระทำความผิดทางอาญา ซึ่งต้องได้รับโทษ
                        ตามพระราชบัญญัติทรัพย์สินทางปัญญาฯ
                        และกฎหมายอื่นที่เกี่ยวข้อง อีกทั้งเป็นการกระทำละเมิดต่อ
                        Melon LIVE ซึ่ง Melon LIVE
                        สงวนสิทธิในการยับยั้งการกระทำนั้นในทันที
                        และจะดำเนินการทางกฎหมายต่อผู้กระทำละเมิดอย่างเด็ดขาดโดยไม่มีข้อยกเว้น
                    </p>
                    <p>
                        4.3 Melon LIVE
                        เป็นผู้ถือกรรมสิทธิ์ในสำเนาแอพพลิเคชั่นและเนื้อหาทั้งหมด
                        แม้ว่าหลังจากที่ได้มีการติดตั้งแอพพลิเคชั่นและเนื้อหาลงในโทรศัพท์เคลื่อนที่หรืออุปกรณ์อื่นๆ
                        ในลักษณะเดียวกัน
                        โดยผู้ใช้งานเพียงได้รับสิทธิอนุญาตให้ใช้งานหรือบริการบนแอพพลิเคชั่นของ
                        Melon LIVE เท่านั้น
                    </p>
                    <p>
                        4.4 ผู้ใช้งานให้สิทธิ์แก่ Melon LIVE
                        โดยไม่จำกัดแต่เพียงผู้เดียวเป็นการถาวรและเพิกถอนไม่ได้
                        อย่างไม่มีภาระและค่าลิขสิทธิ์รวมทั้งความสามารถในการใช้งานทั่วโลกและให้สิทธิ์ในการรวบรวม
                        จัดเก็บ ทำซ้ำ แก้ไข ดัดแปลง
                        สร้างงานที่ต่อเนื่องจากถ่ายโอน โอนช่วงสิทธิ์
                        ให้และแจกจ่ายได้ไม่ว่าเพียงบางส่วนหรือทั้งหมด
                        ซึ่งข้อมูลเนื้อหาในการถ่ายทอดสด (การไลฟ์สด) การประกาศ
                        และข้อมูลของผู้ใช้งานที่มีความเกี่ยวข้องเชื่อมโยงกับบริการต่างๆ
                        ของ Melon LIVE ไม่ว่าจะด้วยรูปแบบ วิธีการ
                        หรือเทคโนโลยีใดก็ตามที่มีหรือได้รับทราบแล้วขณะนี้
                        หรือสร้างขึ้นหลังจากนี้ก็ตาม
                        นอกเหนือจากสิทธิ์ที่ซึ่งมอบให้ในข้อนี้อย่างเฉพาะแล้ว
                        ผู้ใช้งานตกลงที่จะสละสิทธิ์ทางศีลธรรมหรือสิทธิ์ที่เท่าเทียมกันภายใต้กฎหมายที่นำมาบังคับใช้
                    </p>
                    <p>
                        4.5 ผู้กระทำละเมิดต่อ Melon LIVE
                        มีความรับผิดที่ต้องชดใช้ค่าเสียหายต่อ Melon LIVE
                        ตามอัตราความเสียหายต่อวัน ซึ่งประเมินอย่างสมเหตุสมผลแล้ว
                        ที่ไม่น้อยกว่า 1,000 บาทต่อการถ่ายทอดสด (การไลฟ์สด)
                        การประกาศ หรือ 1,000 บาทต่อบัญชีผู้ใช้งาน หรือ 100
                        บาทต่อการเรียกข้อมูลจากเซิร์ฟเวอร์ หรือในอัตรา 500,000
                        บาท ต่อวัน ทั้งนี้ แล้วแต่จำนวนใดจะมากกว่า
                        โดยไม่ถือเป็นค่าเสียหายในเชิงลงโทษ
                        และไม่ตัดสิทธิเรียกร้องอื่นๆ ซึ่ง Melon LIVE มีอยู่
                        โดยรวมถึงการเรียกร้องความสูญเสีย ค่าเสียหาย
                        ค่าใช้จ่ายอื่นๆ อีกต่างหาก
                    </p>
                    <p>
                        4.6
                        หากท่านเป็นเจ้าของสิทธิในทรัพย์สินทางปัญญาเหนือสินค้าหรือบริการใดที่ลงถ่ายทอดสด
                        (การไลฟ์สด) ประกาศบนเว็บไซต์และแอพพลิเคชั่นของ Melon
                        LIVE หรือเป็นตัวแทนโดยชอบด้วยกฎหมายของบุคคลดังกล่าว
                        และท่านเชื่อว่าสิทธิในทรัพย์สินทางปัญญาของท่านถูกละเมิด
                        ท่านสามารถทำหนังสือแจ้งข้อเท็จจริงระบุรายการถ่ายทอดสด
                        (การไลฟ์สด) การประกาศ สินค้าหรือบริการที่เกี่ยวข้อง
                        พร้อมแนบหลักฐานการอ้างสิทธิ ส่งมายัง{' '}
                        <a href="mailto:hello@melonlive.shop">
                            hello@melonlive.shop
                        </a>{' '}
                        เพื่อให้ Melon LIVE ตรวจสอบ ซึ่ง Melon LIVE
                        อาจให้ความร่วมมือในการลบประกาศสินค้าหรือบริการตามที่ได้รับแจ้งออกจากระบบในเบื้องต้น
                        อันเป็นการกระทำโดยสุจริตเพื่อคุ้มครองสิทธิของผู้เกี่ยวข้อง
                        แต่ Melon LIVE
                        ไม่รับรองถึงผลการดำเนินการและไม่มีความรับผิดหรือภาระผูกพันใดในการดำเนินการดังกล่าว
                        ทั้งนี้
                        ข้อพิพาทและความรับผิดเป็นเรื่องระหว่างผู้อ้างสิทธิและผู้ใช้งานต่อกันเองเท่านั้น
                    </p>
                    <h2>
                        <strong>
                            5. ข้อสงวนสิทธิเกี่ยวกับระบบและข้อมูลคอมพิวเตอร์
                        </strong>
                    </h2>
                    <p>
                        5.1 เว็บไซต์และแอปพลิเคชั่นของ Melon LIVE
                        รวมถึงฐานข้อมูลคอมพิวเตอร์และระบบคอมพิวเตอร์ต่างๆ
                        ที่เกี่ยวข้องของ Melon LIVE
                        เป็นระบบที่มีมาตรการป้องกันการเข้าถึงโดยเฉพาะและมาตรการนั้นมีไว้สำหรับความมั่นคงและปลอดภัยของระบบดังกล่าว
                        อันได้รับความคุ้มครองตามกฎหมายว่าด้วยการกระทำความผิดเกี่ยวกับคอมพิวเตอร์
                    </p>
                    <p>
                        5.2 ห้ามผู้ใดเข้าถึงเว็บไซต์และแอปพลิเคชั่นของ Melon
                        LIVE รวมถึงฐานข้อมูลคอมพิวเตอร์และระบบคอมพิวเตอร์ต่างๆ
                        ที่เกี่ยวข้องของ Melon LIVE โดยมิชอบ
                        หรือระงับ/ชะลอ/ขัดขวางการทำงานของระบบดังกล่าว
                        หรือดักรับ/ทำให้เสียหาย/ทำลาย/แก้ไข/เปลี่ยนแปลง/เพิ่มเติมข้อมูลคอมพิวเตอร์หรือส่งข้อมูลคอมพิวเตอร์โดยปกปิดปลอมแปลงแหล่งที่มา
                        หรือนำข้อมูลปลอม/เท็จ/ผิดกฎหมาย/ลามกเข้าสู่ฐานข้อมูลคอมพิวเตอร์และระบบคอมพิวเตอร์ต่างๆ
                        ที่เกี่ยวข้องของ Melon LIVE
                        หรือเปิดเผยมาตรการป้องกันระบบของ Melon LIVE
                    </p>
                    <p>
                        5.3 ห้ามผู้ใดกระทำการใดที่ Interact หรือ Interoperate
                        กับฐานข้อมูลคอมพิวเตอร์และระบบคอมพิวเตอร์ต่างๆ
                        ที่เกี่ยวข้องของ Melon LIVE เช่น ดาวน์โหลด, อัปโหลด,
                        โพสต์, ตั้งค่าสถานะ, ส่งอีเมล,
                        ค้นหาผ่านวิธีการที่ไม่ได้รับการจัดสรรให้โดย Melon LIVE
                        หรือโดยไม่ได้รับอนุญาตจาก Melon LIVE
                    </p>
                    <p>
                        5.4 ห้ามผู้ใด Robots, Phishing, Spiders, Scripts,
                        Scrapers, Crawlers, Spamming
                        หรือกระทำการอันมีลักษณะเดียวกัน ไม่ว่าวิธีการใดๆ
                        ซึ่งเป็นการกระทำที่ไม่ชอบด้วยกฎหมายและไม่พึงประสงค์กับฐานข้อมูลคอมพิวเตอร์และระบบคอมพิวเตอร์ต่างๆ
                        ที่เกี่ยวข้องของ Melon LIVE
                    </p>
                    <p>
                        5.5 ห้ามผู้ใด โดยมิได้รับการอนุญาตที่ทำเป็นหนังสือจาก
                        Melon LIVE เก็บ บันทึก รวบรวม ทำซ้ำ ดัดแปลง ส่งต่อ ใช้
                        หรือมีไว้เพื่อการใดๆ ซึ่งเนื้อหาข้อมูล ข้อมูลประกาศ
                        ข้อมูลสินค้าและบริการ ข้อมูลผู้ใช้งาน
                        ข้อมูลช่องทางติดต่อผู้ใช้งาน
                        ที่ปรากฎหรือแสดงบนเว็บไซต์และแอพพลิเคชั่นของ Melon LIVE
                        รวมถึงฐานข้อมูลคอมพิวเตอร์และระบบคอมพิวเตอร์อื่นๆ
                        ที่เกี่ยวข้องของ Melon LIVE
                        และรายงานหรือเอกสารตามคำขอหรือหนังสือร้องเรียนจากหน่วยงานของรัฐหรือเจ้าหน้าที่ของรัฐ
                        ไม่ว่าบางส่วนหรือทั้งหมด
                    </p>
                    <p>
                        5.6 การฝ่าฝืนข้อห้ามตามข้อ 5.2 ถึงข้อ 5.5 ข้างต้น
                        ถือเป็นการกระทำความผิดทางอาญา
                        ตามกฎหมายว่าด้วยการกระทำความผิดเกี่ยวกับคอมพิวเตอร์
                        อีกทั้งเป็นการกระทำละเมิดต่อ Melon LIVE ซึ่ง Melon LIVE
                        ขอสงวนสิทธิที่จะใช้ดุลยพินิจแต่เพียงผู้เดียวในการยับยั้งการกระทำของท่านในทันที
                        โดยมิต้องบอกกล่าวล่วงหน้า
                        และดำเนินการทางกฎหมายต่อท่านอย่างเด็ดขาดโดยไม่มีข้อยกเว้น
                        โดยท่านจะมีความรับผิดที่ต้องชดใช้ค่าเสียหายต่อ Melon
                        LIVE ตามอัตราความเสียหายต่อวัน
                        ซึ่งประเมินอย่างสมเหตุสมผลแล้ว ที่ไม่น้อยกว่า 1,000
                        บาทต่อการถ่ายทอดสด (การไลฟ์สด) การประกาศ หรือ 1,000
                        บาทต่อบัญชีผู้ใช้งาน หรือ 100
                        บาทต่อการเรียกข้อมูลจากเซิร์ฟเวอร์ หรือในอัตรา 500,000
                        บาท ต่อวัน ทั้งนี้ แล้วแต่จำนวนใดจะมากกว่า
                        โดยไม่ถือเป็นค่าเสียหายในเชิงลงโทษ
                        และไม่ตัดสิทธิ์เรียกร้องอื่นๆ ซึ่ง Melon LIVE มีอยู่
                        โดยรวมถึงการเรียกร้องความสูญเสีย ค่าเสียหาย
                        ค่าใช้จ่ายอื่นๆ อีกต่างหาก
                    </p>
                    <h2>
                        <strong>6. ข้อสงวนสิทธิเกี่ยวกับเนื้อหาข้อมูล</strong>
                    </h2>
                    <p>
                        &nbsp;6.1 ข้อมูล รายละเอียด
                        หรือเนื้อหาที่เกี่ยวข้องกับสินค้าหรือบริการที่ประกาศบนเว็บไซต์และแอพพลิเคชั่นของ
                        Melon LIVE เช่น การถ่ายทอดสด (การไลฟ์สด)เนื้อหาประกาศ
                        เนื้อหาในพื้นที่ประชาสัมพันธ์
                        ได้ถูกจัดเตรียมขึ้นโดยผู้ใช้งานหรือบุคคลภายนอกผู้เป็นเจ้าของข้อมูลนั้นๆ
                        โดยตรง Melon LIVE เพียงแต่รวบรวมข้อมูล
                        รายละเอียดหรือเนื้อหาดังกล่าวเพื่อจัดระเบียบเนื้อหาข้อมูลเข้าด้วยกัน
                        จัดการ
                        และจัดแสดงเผยแพร่เนื้อหาข้อมูลดังกล่าวบนเว็บไซต์และแอพพลิเคชั่นของ
                        Melon LIVE
                        อันเป็นพื้นที่กลางสำหรับการสื่อสารระหว่างผู้ใช้งานเท่านั้น
                        ซึ่ง Melon LIVE จึงไม่สามารถทราบถึงความถูกต้อง
                        ข้อเท็จจริง รายละเอียด
                        และแหล่งที่มาของเนื้อหาข้อมูลได้ทั้งหมด อีกทั้ง Melon
                        LIVE ไม่มีนิติสัมพันธ์ใด ๆ กับผู้ใช้งานในลักษณะตัวแทน
                        นายหน้า หุ้นส่วน นายจ้างลูกจ้าง กับผู้ใช้งาน ดังนั้น
                        Melon LIVE จึงไม่รับรองว่า
                        เนื้อหาข้อมูลที่ปรากฏบนเว็บไซต์และแอพพลิเคชั่นของ Melon
                        LIVE มีสมบูรณ์ ถูกต้อง ปราศจากข้อบกพร่องเสียหาย
                        หรือสมประโยชน์ใดเป็นการเฉพาะ
                        หากเนื้อหาข้อมูลดังกล่าวไม่ชอบด้วยกรณีใดๆ
                        หรือก่อให้เกิดความเสียหายต่อบุคคลใดก็ตาม
                        ย่อมไม่เป็นการก่อให้เกิดสิทธิเรียกร้อง ความรับผิด
                        หรือภาระผูกพันทางกฎหมายต่อ Melon LIVE
                    </p>
                    <p>
                        6.2 ในกรณีมีเหตุอันจำเป็นสมควร
                        ไม่ว่าจะมีการแจ้งเตือนโดยบุคคลอื่นหรือเจ้าหน้าที่หรือพนังงานของรัฐ
                        หรือในกรณี Melon LIVE
                        พบหรือมีเหตุอันควรสงสัยว่าผู้ใช้งานส่งหรือป้อนเนื้อหา
                        รูปภาพ ภาพเคลื่อนไหว ข้อความ หรือข้อมูลลักษณะใด ๆ
                        ที่ขัดต่อกฎหมายหรือศีลธรรม เป็นภัยต่อความมั่นคง
                        เป็นอันตราย เป็นความลับ เป็นข้อมูลปลอมหรือเป็นเท็จ
                        เป็นข้อมูลอันล่วงละเมิดสิทธิของบุคคลอื่นหรือกฎหมาย
                        หรืออาจก่อให้เกิดความเสียหายต่อ Melon LIVE หรือบุคคลอื่น
                        หรือเป็นเนื้อหาข้อมูลที่ไม่เหมาะสมด้วยประการใดๆ ที่แสดง
                        เผยแพร่หรือปรากฏในเมนูพูดคุย
                        หรือบนเว็บไซต์และแอพพลิเคชั่นของ Melon LIVE ทาง Melon
                        LIVE ขอสงวนสิทธิในการลบหรือระงับเนื้อหาข้อมูลใดๆ
                        ในประกาศหรือบัญชีสมาชิกของผู้ใช้งานได้ทันที
                        โดยไม่ต้องมีการบอกกล่าวล่วงหน้า
                        ซึ่งถือเป็นสิทธิขาดและขึ้นอยู่กับดุลพินิจของ Melon LIVE
                        แต่เพียงผู้เดียว และ Melon LIVE
                        ไม่มีภาระผูกพันหรือความรับผิดชอบใด ๆ ต่อผลหรือต่อบุคคลใด
                        ๆ ในการดำเนินการดังกล่าวนี้
                    </p>
                    <p>
                        6.3
                        การที่ผู้ใช้งานส่งหรือป้อนเนื้อหาข้อมูลของการถ่ายทอดสด
                        (การไลฟ์สด)
                        การประกาศที่แสดงหรือปรากฏบนเว็บไซต์และแอพพลิเคชั่นของ
                        Melon LIVE นั้น
                        ผู้ใช้งานตกลงมอบสิทธิ์ในทรัพย์สินทางปัญญาโดยไม่จำกัดแต่เพียงผู้เดียวอันเป็นการถาวรและเพิกถอนไม่ได้ในเนื้อหาข้อมูลดังกล่าว
                        โดยปราศจากค่าสิทธิ์และภาระใด ๆ แก่ Melon LIVE
                        ในการจัดการและเผยแพร่ต่อสาธารณชน และดำเนินการต่าง ๆ
                        ที่เกี่ยวกับเนื้อหาข้อมูลนั้น
                        รวมทั้งความสามารถในการใช้งานทั่วโลกและให้สิทธิ์ในการรวบรวม
                        จัดเก็บ ทำซ้ำ แก้ไข ดัดแปลง
                        สร้างงานที่ต่อเนื่องจากถ่ายโอน โอนช่วงสิทธิ์
                        ให้และแจกจ่ายได้ไม่ว่าเพียงบางส่วนหรือทั้งหมด
                        เพื่อให้ความช่วยเหลือแก่ผู้ใช้งาน การปรับปรุงบริการต่าง
                        ๆ ของ Melon LIVE การรักษาความปลอดภัย
                        การให้ความร่วมมือแก่เจ้าหน้าที่ของรัฐตามที่มีหนังสือแจ้ง
                        หรือเพื่อคุ้มครองเนื้อหาข้อมูลนั้นตามสมควรในฐานะผู้มีหรือได้รับสิทธิในทรัพย์สินทางปัญญา
                        รวมทั้งได้รับมอบสิทธิ์ในการดำเนินการทางกฎหมายต่อผู้ทำการละเมิดสิทธิ์ในทรัพย์สินทางปัญญาจนถึงที่สุด
                    </p>
                    <p>
                        6.4 ข้อมูลเนื้อหาของการถ่ายทอดสด (การไลฟ์สด)
                        การประกาศบนเว็บไซต์และแอพพลิเคชั่นของ Melon LIVE
                        มีให้ไว้ตามที่เป็นอยู่และตามที่มีอยู่ ซึ่ง Melon LIVE
                        ไม่มีส่วนร่วมหรือภาระผูกพันและความรับผิดชอบใด ๆ
                        ต่อผู้ใช้งานทุกฝ่ายหรือบุคคลภายนอก โดยผู้ใช้งานรับรองว่า
                        ผู้ใช้งานตกลงจะรับผิดชอบแต่เพียงผู้เดียวต่อเนื้อหาข้อมูลของการถ่ายทอดสด
                        (การไลฟ์สด)
                        การประกาศและผลกระทบที่อาจเกิดขึ้นต่อบุคคลอื่น
                        การใช้งานหรือการก่อนิติกรรมใด ๆ
                        ที่เกี่ยวเนื่องกับการถ่ายทอดสด (การไลฟ์สด)
                        การประกาศสินค้าหรือบริการบนเว็บไซต์และแอพพลิเคชั่นของ
                        Melon LIVE ไม่ว่าจะผ่านทางเว็บไซต์และแอพพลิเคชั่นของ
                        Melon LIVE หรือไม่ก็ตาม อาจมีความเสี่ยง
                        ซึ่งเป็นภาระและหน้าที่ของผู้ใช้งานทุกฝ่ายที่ควรใช้วิจารณญาณอย่างรอบคอบเกี่ยวกับข้อมูล
                        รายละเอียดหรือเนื้อหาต่าง ๆ ของการถ่ายทอดสด (การไลฟ์สด)
                        การประกาศก่อนตัดสินใจดำเนินการใด ๆ
                        เพื่อความปลอดภัยในทรัพย์สินและสิทธิส่วนบุคคล
                    </p>
                    <h2>
                        <strong>7. ข้อกำหนดและเงื่อนไขการใช้เมนูพูดคุย</strong>
                    </h2>
                    <p>
                        เมื่อผู้ใช้งานใช้หรือเข้าถึงเมนูพูดคุย
                        ผู้ใช้งานตกลงจะปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้เมนูพูดคุย
                        ดังนี้โดยเคร่งครัด
                    </p>
                    <p>7.1. การเข้าใช้เมนูพูดคุยและบัญชีผู้ใช้เมนูพูดคุย</p>
                    <p>
                        7.1.1 Melon LIVE
                        สงวนสิทธิ์จำกัดความสามารถในการใช้เมนูพูดคุยให้เฉพาะผู้ใช้งานที่มีบัญชีสมาชิก
                        Melon LIVE เท่านั้น
                        เมื่อผู้ใช้งานได้ลงทะเบียนบัญชีสมาชิกผู้ใช้งานกับ Melon
                        LIVE แล้ว ผู้ใช้งานสามารถใช้เมนูพูดคุยได้โดยอัตโนมัติ
                    </p>
                    <p>
                        7.1.2 Melon LIVE
                        ถือว่าการที่ผู้ใช้งานเข้าถึงหรือใช้งานในเมนูพูดคุย
                        ผู้ใช้งานยอมรับและตกลงปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้เมนูพูดคุยนี้ทุกประการ
                        โดยปราศจากข้อยกเว้นและเพิกถอนมิได้
                    </p>
                    <p>
                        7.1.3
                        ผู้ใช้งานจะต้องดูแลบัญชีสมาชิกของผู้ใช้งานและป้องกันการเข้าถึงหรือการใช้โดยไม่ได้รับอนุญาต
                        ซึ่งรวมถึงเมนูพูดคุยของตนด้วย
                        ผู้ใช้งานตกลงและรับรองว่าการกระทำหรือบทสนทนาใดๆ
                        ที่เกิดขึ้นในเมนูพูดคุยในบัญชีสมาชิกของผู้ใช้งานนั้นถือเป็นการกระทำหรือการดำเนินการโดยผู้ใช้งานที่ถือบัญชีสมาชิกดังกล่าวโดยปราศจากข้อโต้แย้งใดๆ
                    </p>
                    <p>
                        7.1.4
                        ผู้ใช้งานตกลงมอบสิทธิ์ในทรัพย์สินทางปัญญาโดยไม่จำกัดแต่เพียงผู้เดียวอันเป็นการถาวรในเนื้อหาข้อมูลในบัญชีผู้ใช้งานและเพิกถอนไม่ได้
                        โดยปราศจากค่าสิทธิ์และภาระใด ๆ แก่ Melon LIVE
                        ในการเข้าถึงและตรวจสอบบัญชีสมาชิกและเมนูพูดคุยของผู้ใช้งานได้ทุกเมื่อ
                        โดยมิต้องมีแจ้งเตือนเป็นการล่วงหน้า
                        รวมทั้งให้สิทธิ์ในการรวบรวม จัดเก็บ ทำซ้ำ แก้ไข ดัดแปลง
                        สร้างงานที่ต่อเนื่องจากถ่ายโอน โอนช่วงสิทธิ์
                        ให้และแจกจ่ายได้ไม่ว่าเพียงบางส่วนหรือทั้งหมด
                        อันเป็นการดำเนินการภายในขอบเขตที่จำเป็นเพื่อให้ความช่วยเหลือผู้ใช้งาน
                        รวมถึงเพื่อจัดบริการ และ เพื่อการบำรุงรักษา
                        เพิ่มประสิทธิภาพ ความปลอดภัย และการปฏิบัติตามกฎหมาย
                        ซึ่งอาจกระทำโดย Melon LIVE
                        หรือผู้ที่ได้รับมอบอำนาจให้กระทำการแทน
                    </p>
                    <p>
                        7.1.5 Melon LIVE สงวนสิทธิ์แต่เพียงฝ่ายเดียวในการแก้ไข
                        ปรับปรุงหรือเพิ่มเติมข้อกำหนดและเงื่อนไขการใช้เมนูพูดคุยได้ในภายหลัง
                        ซึ่งอาจจะมีการแจ้งให้ทราบในพื้นที่บนเว็บไซต์และแอพพลิเคชั่นของ
                        Melon LIVE
                        โดยผู้ใช้งานมีหน้าที่ต้องตรวจสอบถึงความเป็นปัจจุบันของข้อกำหนดและเงื่อนไขการใช้เมนูพูดคุยที่มีการเปลี่ยนแปลง
                        ดังนั้น
                        การที่ผู้ใช้งานเข้าถึงหรือใช้งานในเมนูพูดคุยต่อไป
                        ผู้ใช้งานยอมรับและตกลงปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้เมนูพูดคุยนี้ทุกประการ
                        โดยปราศจากข้อยกเว้นและเพิกถอนมิได้
                    </p>
                    <p>7.2. เนื้อหาและข้อมูลผู้ใช้งาน</p>
                    <p>
                        7.2.1 &ldquo;ข้อมูล&rdquo; และ &ldquo;เนื้อหา&rdquo;
                        นอกเหนือจากคำนิยามตามกฎหมายที่เกี่ยวข้องแล้ว
                        ให้หมายความถึง ข้อความ รูปภาพ วิดีโอ ข้อมูลโปรไฟล์ ไอคอน
                        เป็นต้น ที่ผู้ใช้งานส่งให้ Melon LIVE
                        หรือที่ผู้ใช้งานให้ผู้ใช้ Melon LIVE
                        อื่นดูผ่านทางเมนูพูดคุย
                    </p>
                    <p>
                        7.2.2
                        ผู้ใช้งานรับรองว่าข้อมูลและเนื้อหาของตนไม่ละเมิดสิทธิของบุคคลอื่น
                        ไม่ว่าจะเป็น ลิขสิทธิ์ สิทธิบัตร
                        สิทธิในเครื่องหมายการค้า สิทธิในทรัพย์สินทางปัญญา
                        ความลับทางการค้า
                        สิทธิในเกียรติยศและศักดิ์ศรีความเป็นมนุษย์
                        สิทธิในภาพบุคคล สิทธิส่วนบุคคล สิทธิในชื่อเสียง
                        และสิทธิอื่น ๆ รวมถึงไม่ขัดต่อกฎหมายและข้อตกลงนี้
                        และไม่ขัดต่อความสงบเรียบร้อยของสังคมและศีลธรรมของประชาชน
                    </p>
                    <p>
                        7.2.3
                        ห้ามผู้ใช้งานให้หรือใช้ข้อความเพื่อแอบอ้างว่าเป็นบุคคลอื่น
                        เพื่อป้องกันไม่ให้ผู้ใช้งานอื่นเข้าใจผิดว่าข้อมูลดังกล่าวมาจากบุคคลอื่นโดยตรง
                        ในกรณีที่มีการอ้างอิงถึงข้อมูลหรือเนื้อหาของบุคคลอื่น
                        ผู้ใช้งานจะต้องแสดงข้อมูลดังกล่าวอย่างเหมาะสม
                        โดยระบุแหล่งที่มาและเจ้าของข้อมูลที่แท้จริง
                    </p>
                    <p>
                        7.2.4
                        ในกรณีเนื้อหาหรือข้อมูลของผู้ใช้งานมีความไม่ถูกต้องเหมาะสม
                        หรือผู้ใช้งานละเมิดข้อ 2.2 ดังกล่าวข้างต้น Melon LIVE
                        อาจระงับการส่งหรือลบเนื้อหาดังกล่าว
                        หรือปิดบัญชีสมาชิกของผู้ใช้งานได้
                        โดยมิต้องแจ้งให้ทราบล่วงหน้า และถือเป็นสิทธิ์เด็ดขาด
                    </p>
                    <p>
                        7.2.5 Melon LIVE อาจจัดเก็บ ใช้
                        หรือแบ่งปันเนื้อหาและข้อมูลในการถ่ายทอดสด (การไลฟ์สด)
                        การประกาศ เมนูพูดคุย และบัญชีสมาชิกของผู้ใช้งาน
                        ในการบริหารจัดการ ติดต่อผู้ใช้งาน
                        โฆษณาและส่งเสริมการขายและบริการ เพื่อการวิเคราะห์
                        ประเมินผลทางสถิติและพฤติกรรม หรือดำเนินการตามกฎหมาย
                        โดยผู้ใช้งานอนุญาตให้ Melon LIVE จัดเก็บ ใช้
                        หรือแบ่งปันเนื้อหาและข้อมูลได้เท่าที่จำเป็นสำหรับวัตถุประสงค์ดังกล่าวข้างต้นอย่างไม่มีกำหนดระยะเวลา
                        ไม่มีค่าใช้จ่าย และไม่จำกัดเขตพื้นที่
                    </p>
                    <p>
                        7.2.6. Melon LIVE จะไม่เปิดเผยเนื้อหา ข้อมูลผู้ใช้งาน
                        หรือข้อมูลส่วนบุคคลที่ไม่บ่งชี้ตัวตนของผู้ใช้งานในเมนูพูดคุยแก่บุคคลอื่นโดยไม่ได้รับความเห็นชอบจากผู้ใช้งาน
                        ยกเว้น (1) Melon LIVE อาจแบ่งปันข้อมูลที่ Melon LIVE
                        เก็บรวบรวมไว้
                        กับบริษัทในเครือหรือกลุ่มบริษัทซึ่งร่วมกันให้บริการ
                        หรือผู้ให้บริการที่เป็นบุคคลภายนอกในการช่วยเสนอปรับปรุงบริการด้านต่างๆ
                        ตามที่ Melon LIVE เห็นสมควร รวมถึงมาตรการความปลอดภัย
                        สำหรับ Melon LIVE และ
                        (2)ในกรณีที่จำเป็นต้องเปิดเผยตามข้อบังคับตามกฎหมาย
                        และในกรณีที่หน่วยงานของรัฐ มีคำขอโดยชอบด้วยกฎหมายให้
                        Melon LIVE เปิดเผยหรือส่งสำเนาเนื้อหาและข้อมูลผู้ใช้งาน
                        Melon LIVE
                        อาจเปิดเผยหรือส่งสำเนาข้อมูลผู้ใช้งานให้แก่หน่วยงานดังกล่าว
                        โดยไม่ต้องแจ้งหรือขออนุมัติจากผู้ใช้งานแต่อย่างใด
                    </p>
                    <p>7.3. ข้อจำกัดความรับผิด</p>
                    <p>
                        7.3.1 ผู้ใช้งานเป็นผู้จัดเตรียมเนื้อหาข้อมูลต่างๆ
                        ที่ปรากฏในเมนูพูดคุยโดยผู้ใช้งานเอง ซึ่ง Melon LIVE
                        ไม่มีส่วนเกี่ยวข้องและไม่รับประกัน
                        ถึงความถูกต้องและความเป็นจริงที่เชื่อถือได้ในเนื้อหาข้อมูลต่างๆ
                        ดังกล่าว ดังนั้น Melon LIVE
                        จึงไม่มีส่วนร่วมรับผิดชอบในความเสียหายใด ๆ
                        ที่เกิดขึ้นกับผู้ใช้งานหรือบุคคลอื่นอันเกิดจากการใช้เมนูพูดคุย
                        ไม่ว่าโดยตรงหรือทางอ้อม
                    </p>
                    <p>
                        7.3.2
                        ในกรณีที่มีการใช้สิทธิเรียกร้องหรือฟ้องร้องดำเนินคดี
                        โดยมีสาเหตุมาจากหรือเกี่ยวกับการใช้เมนูพูดคุยของผู้ใช้งาน
                        ผู้ใช้งานจะยุติข้อเรียกร้องนั้นด้วยความรับผิดชอบและค่าใช้จ่ายของผู้ใช้งานเอง
                        ไม่ว่าด้วยเหตุผลใดก็ตาม Melon LIVE
                        สงวนสิทธิที่จะไม่เกี่ยวข้องในการยุติข้อเรียกร้องหรือข้อพิพาทดังกล่าว
                    </p>
                    <p>7.4. ความปลอดภัยในการใช้งาน</p>
                    <p>
                        การใช้เมนูพูดคุยของ Melon LIVE ห้ามผู้ใช้งานกระทำการใด ๆ
                        ดังต่อไปนี้
                    </p>
                    <p>
                        7.4.1 กระทำการอันขัดต่อกฎหมาย
                        หรือขัดต่อความสงบเรียบร้อยของสังคมและศีลธรรมของประชาชน
                    </p>
                    <p>
                        7.4.2 ละเมิดทรัพย์สินทางปัญญา ลิขสิทธิ์
                        เครื่องหมายการค้า สิทธิบัตร สิทธิในชื่อเสียง
                        สิทธิส่วนบุคคล และสิทธิอื่นใดของ Melon LIVE
                        ผู้ใช้งานอื่น หรือบุคคลอื่น
                    </p>
                    <p>
                        7.4.3
                        โอนสิทธิ์หรือให้บุคคลอื่นเข้าถึงเมนูพูดคุยในบัญชีสมาชิกของผู้ใช้งาน
                        ไม่ว่าจะด้วยเหตุผลใดก็ตาม
                    </p>
                    <p>
                        7.4.4 โพสต์หรือส่งข้อมูลที่แสดงออกถึงความรุนแรง คุกคาม
                        ลามกหรือมีเนื้อหาทางเพศ หรือเลือกปฏิบัติทางเชื้อชาติ
                        ชาติกำเนิด สัญชาติ ศาสนา เพศ สถานภาพทางสังคม
                        หรือมีเนื้อหาเป็นภัยต่อสังคม
                        หรือทำให้ผู้อื่นเกิดความเดือดร้อนรำคาญ
                    </p>
                    <p>
                        7.4.5 บิดเบือนข้อมูล ทำให้เกิดความเข้าใจผิด
                        เสื่อมเสียชื่อเสียง หรือเผยแพร่ข้อมูลเท็จโดยเจตนา
                    </p>
                    <p>
                        7.4.6
                        ใช้เมนูพูดคุยเพื่อวัตถุประสงค์เกี่ยวกับพฤติกรรมทางเพศ
                        เพื่อแสวงหาประโยชน์จากกิจกรรมที่ผิดกฎหมาย
                        ใช้ฉ้อโกงหรือกรรโชกทรัพย์
                        ใช้วิธีการฉ้อฉลในการทำธุรกรรมทางธุรกิจ
                        ใช้ประกอบอาชญากรรม
                        ใช้เพื่อเป็นสมาชิกกลุ่มองค์กรอาชญากรรม
                        หรือเพื่อวัตถุประสงค์อื่นนอกเหนือจากวัตถุประสงค์ที่
                        Melon LIVE ได้กำหนด
                    </p>
                    <p>
                        7.4.7 เก็บรวบรวม เปิดเผย หรือให้ ข้อมูลส่วนบุคคล
                        ข้อมูลทะเบียน หรือประวัติการใช้งาน
                        โดยไม่ชอบด้วยกฎหมายหรืออย่างไม่เหมาะสม
                    </p>
                    <p>
                        7.4.8 รบกวนการทำงานของเซิร์ฟเวอร์ ระบบเครือข่าย
                        หรือระบบของ Melon LIVE หรือแทรกแซงการให้บริการของ Melon
                        LIVE หรือการใช้เมนูพูดคุยของผู้ใช้งานรายอื่น
                        หรือบุคคลอื่น เช่น Spamming, Scripting
                        หรือกระทำการที่มีลักษณะเดียวกัน ไม่ว่าวิธีการใด ๆ
                    </p>
                    <p>
                        7.4.9 กระทำการหรือกิจกรรมอื่นใดที่ Melon LIVE
                        ถือว่าเป็นการรบกวนผู้ใช้งานอื่น ๆ
                        ไม่ว่าจะเป็นการถ่ายทอดสด (การไลฟ์สด) การประกาศ โฆษณา
                        หรือส่งข้อความรบกวนโดยการสุ่มถึงผู้ใช้งานผ่านทางเมนู
                        &ldquo;คุยกับผู้ขาย&rdquo;
                        หรือโทรศัพท์เคลื่อนที่ของผู้ใช้งานไม่ว่าจะในลักษณะข้อความสั้น
                        (SMS) หรือข้อความมัลติมีเดีย (MMS)
                        (ยกเว้นเฉพาะกรณีที่ได้รับความเห็นชอบจาก Melon LIVE)
                    </p>
                    <p>
                        7.4.10 กระทำการใดๆ ซึ่งทำให้ Melon LIVE
                        อาจมีส่วนเกี่ยวข้องในข้อพิพาทหรือข้อเรียกร้อง
                        หรือกระทำการอื่นใดที่ Melon LIVE พิจารณาว่าไม่เหมาะสม
                    </p>
                    <p>
                        7.5. การแก้ไขเปลี่ยนแปลง
                        การระงับและการยกเลิกการใช้เมนูพูดคุย
                    </p>
                    <p>
                        Melon LIVE อาจระงับการใช้ ปิดกั้นการเข้าถึงเมนูพูดคุย
                        หรือปิดบัญชีสมาชิกของผู้ใช้งานโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                        ไม่ว่าในกรณีจำเป็นหรือที่มีความเสี่ยงทางกฎหมาย
                        หรือในกรณีที่เกิดหรือมีความเสี่ยงที่จะเกิดเหตุการณ์ดังต่อไปนี้
                    </p>
                    <p>
                        7.5.1 มีการฝ่าฝืนข้อกำหนดและเงื่อนไขการใช้เมนูพูดคุยนี้
                        รวมทั้งข้อกำหนดและเงื่อนไขการใช้บริการทั่วไปของ Melon
                        LIVE หรือมีการใช้เมนูพูดคุยในวัตถุประสงค์ที่ไม่เหมาะสม
                        หรือมีการกระทำการใดๆ ที่พิจารณาได้ว่าไม่เหมาะสม
                    </p>
                    <p>
                        7.5.2 ในกรณีที่ Melon LIVE ไม่สามารถติดต่อผู้ใช้งานได้
                        โดยไม่มีสาเหตุมาจาก Melon LIVE ซึ่ง Melon LIVE
                        สงวนสิทธิ์ในการแก้ไขเปลี่ยนแปลงหรือยกเลิกบริการเมนูพูดคุยไม่ว่าทั้งหมดหรือบางส่วนได้แต่เพียงฝ่ายเดียว
                        โดยอาจแจ้งให้ผู้ใช้งานทราบโดยทั่วกันหรือโดยตรง ซึ่ง
                        Melon LIVE
                        ไม่มีความรับผิดต่อความเสียหายที่เกิดขึ้นจากการแก้ไขเปลี่ยนแปลง
                        ระงับ ปิดกั้นการเข้าถึง
                        หรือยกเลิกการใช้เมนูพูดคุยดังกล่าว
                    </p>
                    <p>7.6. การโอนสิทธิ</p>
                    <p>
                        7.6.1 ห้ามผู้ใช้งานโอนหรือให้สิทธิ
                        หน้าที่และความรับผิดชอบไม่ว่าบางส่วนหรือทั้งหมดของผู้ใช้งานในการใช้เมนูพูดคุยให้แก่บุคคลอื่นในทุกกรณี
                    </p>
                    <p>
                        7.6.2 Melon LIVE สงวนสิทธิ์ในการโอนบริการเมนูพูดคุย
                        ไม่ว่าทั้งหมดหรือบางส่วน ให้แก่กลุ่มบริษัทหรือบุคคลอื่น
                        โดยแจ้งให้ผู้ใช้งานทราบโดยทั่วกันหรือโดยตรง
                    </p>
                    <h2>
                        <strong>การใช้งานหรือการถ่ายทอดสด (การไลฟ์สด)</strong>
                    </h2>
                    <p>
                        ผู้ใช้งานที่ให้คำเสนอหรือการถ่ายทอดสด (การไลฟ์สด)
                        การประกาศขายสินค้าหรือให้บริการ
                        ต้องไม่ใช้ข้อมูลที่เป็นเท็จ ฉ้อฉล คลุมเครือ
                        สร้างหรืออาจก่อให้เกิดความเสียหายให้แก่บุคคลอื่น
                        ในการลงประกาศ การถ่ายทอดสด
                        (การไลฟ์สด)ขายสินค้าหรือบริการของตน
                    </p>
                    <p>
                        <strong>ข้อห้ามทั่วไป</strong>
                    </p>
                    <p>
                        (1) ห้ามผู้ใช้งานการถ่ายทอดสด (การไลฟ์สด)
                        การประกาศสินค้าและบริการประเภท
                        &ldquo;ห้ามโดยเด็ดขาด&rdquo; ในทุกกรณี โดยไม่มีเงื่อนไข
                        (รายละเอียดปรากฏตามข้างล่างนี้)
                    </p>
                    <p>
                        (2) ห้ามผู้ใช้งานการถ่ายทอดสด (การไลฟ์สด)
                        การประกาศสินค้าและบริการประเภท
                        &ldquo;สินค้าและบริการที่มีเงื่อนไข&rdquo; (บางจำพวก)
                        ซึ่งมีระเบียบและข้อกำหนดที่กำหนดไว้เป็นการเฉพาะ
                        โดยปราศจากความเข้าใจในเนื้อหาต่าง ๆ
                        โดยละเอียดถี่ถ้วนก่อนที่จะลงประกาศ การถ่ายทอดสด
                        (การไลฟ์สด) (รายละเอียดปรากฏตามข้างล่างนี้)
                    </p>
                    <p>
                        <strong>ประเภทสินค้าที่ต้องห้ามลงประกาศ</strong>
                    </p>
                    <ol>
                        <li>
                            ผลิตภัณฑ์สารเคมีที่เป็นอันตรายร้ายแรง หรือ
                            ห้ามจำหน่าย&nbsp;
                        </li>
                        <li>สิ่งของหลุดจำนำ</li>
                        <li>
                            สินค้า/บริการ ที่ผิดกฎหมายหรือผิดศีลธรรม วัฒนธรรม
                            เช่น เครื่องดื่มแอลกอฮอล์,
                            บุหรี่และสิ่งเสพติดทุกชนิด, อาวุธ, สื่อลามกอนาจาร,
                            อุปกรณ์การพนัน, ชิ้นส่วนหรืออวัยวะมนุษย์,
                            ของที่ได้มาจากการกระทำความผิด หรือโจรกรรม
                        </li>
                        <li>
                            ผลิตภัณฑ์อาหารแปรรูปที่ไม่ได้จดทะเบียน อย.&nbsp;
                        </li>
                        <li>
                            ปริญญาบัตร, ประกาศนียบัตร, ใบสำเร็จการศึกษาต่างๆ,
                            การรับทำวิทยานิพนธ์, งานวิจัย
                        </li>
                        <li>
                            ข้อมูลส่วนบุคคล เช่น ชื่อ ที่อยู่ เบอร์โทรศัพท์,
                            อีเมล หรือ ชื่อในระบบออนไลน์
                        </li>
                        <li>รถยนต์ไม่มีเล่มทะเบียนกรมการขนส่งทางบก</li>
                    </ol>
                    <p>
                        รถจดประกอบ, รถอินวอยส์, รถสรรพสามิต (ไม่มีหนังสือ)&nbsp;
                    </p>
                    <p>รถนำเข้าหรือรถเกรย์มาร์เก็ตไม่มีเล่มทะเบียนฯ</p>
                    <p>รถหลุดจำนำ, รถติดไฟแนนซ์ (ที่ไม่ระบุเงื่อนไขไฟแนนซ์)</p>
                    <ol>
                        <li>
                            สัตว์ป่าสงวนและสัตว์ป่าคุ้มครองทุกชนิดที่ระบุไว้ตามกฎหมาย
                            อนุสัญญาระหว่างประเทศ
                            และตามบัญชีต้องห้ามที่ระบุโดยองค์กรระหว่างประเทศ
                            เช่น WWF เป็นต้น (ทั้งนี้อาจขึ้นอยู่กับนโยบายของ
                            Kaidee ซึ่งอาจมีการเปลี่ยนแปลงในภายหลัง)
                        </li>
                        <li>หุ้น และ หลักทรัพย์ ต่างๆ</li>
                        <li>
                            ภาพที่มีโลโก้ของเว็บไซต์อื่นประทับเป็นลายน้ำ
                            โดยโลโก้นั้นมิใช่ของเว็บไซต์ของผู้ลงการถ่ายทอดสด
                            (การไลฟ์สด) การประกาศ รวมถึงภาพที่เป็นลิขสิทธิ์ของ
                            ผู้อื่น
                        </li>
                        <li>เครื่องราชอิสริยาภรณ์</li>
                        <li>
                            GPS Tracker ที่ไม่มีใบอนุญาตจำหน่ายจาก กสทช. หรือ
                            ใบอนุญาตติดตั้งจากกรมการขนส่งทางบก
                        </li>
                        <li>
                            ไม้หวงห้ามทุกชนิด ที่ไม่ได้แปรรูป
                            หรือที่แปรรูปแต่ไม่มีใบอนุญาต
                        </li>
                    </ol>
                    <p>
                        &nbsp;*** ไม้พะยุง ไม่อนุญาตให้ลงการถ่ายทอดสด
                        (การไลฟ์สด) การประกาศ ทุกชนิดประเภท&nbsp;
                    </p>
                    <p>
                        <strong>
                            ประเภทบริการที่ต้องห้ามทำการถ่ายทอดสด (การไลฟ์สด)
                            การประกาศ
                        </strong>
                    </p>
                    <ol>
                        <li>ธุรกิจเครือข่ายที่เข้าลักษณะแชร์ลูกโซ่</li>
                        <li>
                            โฆษณาชวนเชื่อ ตัวอย่างเช่น
                            โฆษณาเกี่ยวกับการทำงานผ่านเน็ต
                        </li>
                        <li>
                            ประกันทางการเงิน เช่น สินเชื่อ-เงินกู้,
                            การประกันเงินกู้ทุกรูปแบบ,
                            บริการเกี่ยวกับการเงินทุกประเภท
                        </li>
                        <li>บริการรับจำนำ, จำนอง, ขายฝาก</li>
                    </ol>
                    <p>
                        <strong>ความรับผิดชอบ</strong>
                    </p>
                    <ul>
                        <li>
                            ผู้ใช้งานรับผิดชอบและยืนยันว่าข้อมูลต่าง ๆ
                            รูปภาพและรายละเอียดเกี่ยวกับสินค้าหรือบริการ
                            (ซึ่งรวมถึงแต่ไม่จำกัดเฉพาะ รูปภาพ รูปถ่าย
                            รูปแบบตัวอักษร ข้อความใด ๆ) ที่ใช้ในการถ่ายทอดสด
                            (การไลฟ์สด) การประกาศบนเว็บไซต์และแอพพลิเคชั่นของ
                            Melon LIVE เป็นของตน ผู้ซึ่งมีกรรมสิทธิ์
                            และสิทธิอื่น ๆ
                            ที่สมบูรณ์และได้รับความคุมครองทางกฎหมาย
                            หรือตนได้รับการอนุญาตให้ใช้ที่ถูกต้องตามกฎหมาย Melon
                            LIVE ห้ามมิให้ผู้ใช้งานใช้หรือนำมาเผยแพร่ซึ่งข้อมูล
                            รูปภาพและรายละเอียดต่าง ๆ ที่ได้จากการทำซ้ำ
                            ทำขึ้นใหม่ ดัดแปลง หรือ
                            คัดลอกไม่ว่าบางส่วนหรือทั้งหมดโดยไม่ได้รับอนุญาตจากเจ้าของ
                            แหล่งข้อมูลอื่นหรือเว็บไซต์อื่นที่มิใช่เว็บไซต์ของผู้ใช้งานเอง
                            ซึ่งผู้ใช้งานต้องรับผิดชอบแต่เพียงฝ่ายเดียวในกรณีที่มีการเรียกร้องความเสียหายที่เกี่ยวข้องกับ
                            ข้อมูล
                            รูปภาพและรายละเอียดเกี่ยวกับสินค้าหรือบริการโดยบุคคลอื่นที่มีสิทธิ์ที่เหนือกว่าผู้ใช้งาน
                            ผู้ใช้งานรับทราบว่าข้อมูล รูปภาพและรายละเอียดต่าง ๆ
                            ที่ใช้ในการถ่ายทอดสด (การไลฟ์สด)
                            การประกาศบนเว็บไซต์และแอพพลิเคชั่นของ Melon LIVE
                            จะไม่เป็นความลับและพร้อมสำหรับการรับชมแบบสาธารณะ
                            ดังนั้น Melon LIVE
                            ไม่มีความผูกพันตามหลักปฏิบัติเกี่ยวกับนโยบายความเป็นส่วนตัวหรือเนื้อหาของบุคคลที่สามและไม่มีความรับผิดชอบในความสูญเสียหรือความเสียหายใดๆ
                            ต่อบุคคลที่สาม
                        </li>
                    </ul>
                    <ul>
                        <li>
                            หาก Melon LIVE
                            พบหรือมีเหตุอันควรเชื่อว่าผู้ใช้งานฝ่าฝืนกฎระเบียบหรือข้อกำหนดต่าง
                            ๆ ที่กล่าวมาข้างต้นไม่ว่าข้อใดข้อหนึ่ง Melon LIVE
                            สงวนสิทธิในการลบประกาศ ระงับการแสดงการถ่ายทอดสด
                            (การไลฟ์สด) การประกาศ หรือแก้ไขเปลี่ยนแปลงประกาศ
                            รวมทั้งปิดกั้นการเข้าสู่ระบบและระงับบัญชีสมาชิกของผู้ใช้งาน
                            ซึ่งถือเป็นสิทธิขาดและขึ้นอยู่กับดุลพินิจของ Melon
                            LIVE
                            แต่เพียงผู้เดียวโดยไม่ต้องบอกกล่าวแก่ผู้ใช้งานเป็นการล่วงหน้า
                            นอกจากนี้ Melon LIVE
                            ไม่มีภาระผูกพันหรือความรับผิดชอบต่อผลหรือต่อบุคคลใด
                            ๆ จากการดำเนินการนี้
                        </li>
                    </ul>
                </Content>
            </Container>
        </BgContainer>
    )
}

const BgContainer = styled.div`
    background-color: #ff9a77;
    color: #ffffff;
    min-height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    font-family: 'kittithada';
`

const BgOverlay = styled.img`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 430px;
    @media (max-width: ${(props) => props.theme.breakpoint.md}px) {
        bottom: unset;
        top: 0;
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }
`

const Title = styled.h1`
    margin: 0;
    text-align: center;
    font-weight: bold;
    font-size: 32px;
`

const Content = styled.div`
    padding-top: 48px;
    padding-bottom: 48px;
    h2 {
        margin: 0;
        margin-top: 8px;
        font-size: 24px;
    }
    p {
        margin: 0;
        font-size: 20px;
        line-height: 1.3;
    }
    a {
        color: #ffffff;
        margin: 0;
        font-size: 20px;
    }
    li {
        font-size: 20px;
    }
`
