import { Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { IStatus } from '../../../model/IStatus';
import useStyles from '../style/style';

const textMap: Record<number, IStatus> = {
  0: {
    title: 'แจ้งที่อยู่และหลักฐานการโอนเงิน',
    desc: [
      'กรุณาตรวจสอบรายการสั่งซื้อ',
      'และแจ้งที่อยู่ในการจัดส่งรวมถึงแนบหลักฐานการโอนเงิน',
    ],
  },
  1: {
    title: 'ตรวจสอบหลักฐาน',
    desc: ['ร้านค้ากำลังตรวจสอบข้อมูลการจัดส่ง', 'และหลักฐานการโอนเงิน'],
  },
  2: {
    title: 'กำลังเตรียมสินค้า',
    desc: ['ร้านค้ากำลังเตรียมจัดส่งสินค้า'],
  },
  3: {
    title: 'จัดส่งแล้ว',
    desc: ['ร้านค้าได้ทำการจัดส่งสินค้าเรียบร้อยแล้ว'],
  },
  4: {
    title: 'ตรวจสอบหลักฐาน',
    desc: ['หลักฐานการโอนเงินของคุณไม่ถูกต้อง', 'กรุณาแก้ไขให้ถูกต้อง'],
  },
};

export default function TitleTextComponent(props: any) {
  const classes = useStyles();
  const [statusText, setStatusText] = React.useState<IStatus>(textMap[0]);

  useEffect(() => {
    const { activeStep } = props;
    const text: IStatus = textMap[activeStep];
    setStatusText(text);
  }, [props]);

  return (
    <Grid
      item
      xs={12}
      className={[
        classes.center,
        classes.headingContent,
        classes.textCenter,
        classes.cardSpaceing,
      ].join(' ')}
    >
      <Typography className={[classes.text, classes.textTitle].join(' ')}>
        {` ${statusText.title}`}
      </Typography>
      {statusText.desc.map((data) => {
        return (
          <Typography key={data} className={classes.subTextTitle}>
            {data}
          </Typography>
        );
      })}
    </Grid>
  );
}
