import React, { useEffect, useRef } from 'react';

export default function PaySolutionPage(props: any) {

    const submitBtnRef = useRef<any>();

    useEffect(() => {
        submitBtnRef.current.click()
    }, [])

    return (
        <html>
            <head>
                <title>EPAYLINK</title>
            </head>
            <body>
                <form method="post" action="https://www.thaiepay.com/epaylink/payment.aspx">
                    <input type="hidden" name="refno" value={props.match.params.refno} />
                    <input type="hidden" name="merchantid" value={props.match.params.merchantid} />
                    <input type="hidden" name="customeremail" value={props.match.params.email} />
                    <input type="hidden" name="productdetail" value={props.match.params.productdetail} />
                    <input type="hidden" name="total" value={props.match.params.total} />
                    <input type="hidden" name="cc" value="00" />
                    <input ref={submitBtnRef} type="submit" name="Submit" value="Confirm Order" style={{ display: 'none' }} />
                </form>
            </body>
        </html>
    )
}