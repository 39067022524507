import {
    Box,
    Button,
    Checkbox,
    Container,
    CssBaseline,
    FormControlLabel,
    Link,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core'
import 'firebase/auth'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import firebase from '../../firebase'
import { PageType } from '../../utils/PageTypes'

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://melonlive.shop/">
                Melon LIVE
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    media: {
        height: 0,
        paddingTop: '56.25%',
        marginTop: '30',
    },
    bg: {
        background: '#ffffff',
        padding: '10%',
        marginTop: '10%',
    },
}))

export default function LoginPage() {
    const classes = useStyles()
    const [values, setValues] = useState({
        email: '',
        password: '',
    })
    const history = useHistory()

    const onChangeTextfield = (changeEvent: any) => {
        changeEvent.persist()
        setValues((values) => ({
            ...values,
            [changeEvent.target.name]: changeEvent.target.value,
        }))
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()
        firebase
            .auth()
            .signInWithEmailAndPassword(values.email, values.password)
            .then((res) => {
                if (res !== undefined) {
                    // getUser(res.user?.uid);
                    history.push(PageType.ReportPage.path)
                } else {
                    alert('ไม่พบข้อมูล')
                }
            })
            .catch((error) => {
                alert('เกิดข้อผิดพลาดในการเข้าถึง : ' + error.message)
            })
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                borderRadius={16}
                className={classes.bg}
                borderColor={'#FFD721'}
                border={1}
            >
                <CssBaseline />
                <div className={classes.paper}>
                    {/* <img src={logo} height="80" /> */}
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            onChange={(e) => {
                                onChangeTextfield(e)
                            }}
                            value={values.email}
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            onChange={(e) => {
                                onChangeTextfield(e)
                            }}
                            value={values.password}
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox value="remember" color="primary" />
                            }
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            className={classes.submit}
                        >
                            {' '}
                            Log In{' '}
                        </Button>
                    </form>
                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Box>
        </Container>
    )
}
