import { CardMedia, Paper, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import firebase from '../../../firebase';
import { IProduct } from '../../../model/IProduct';
import { thousandFormat } from '../../../utils/thousandFormat';
import useStyles from '../style/style';


export default function OrderItem(props: any) {
  const classes = useStyles();
  const [data, setData] = React.useState<IProduct>({
    amount: 0,
    code: '',
    commentId: '',
    item: '',
    message: '',
    productId: '',
    productName: '',
    price: 0,
    imagePath: '',
    subCode: '',
  });

  const [imageUrl, setImageUrl] = React.useState<string>();
  const defaultUrlImage =
    'https://firebasestorage.googleapis.com/v0/b/livemote-293ae.appspot.com/o/image_default.png?alt=media&token=551f408f-b28b-4a63-83f5-37bbba7f636d';

  useEffect(() => {
    const { item } = props;
    setData(item);
    getImageUrlFromFirebase(item);
  }, []);

  const getImageUrlFromFirebase = async (item: IProduct) => {
    if (!item.imagePath) return;

    const imageUrl = await getImageUrl(item.imagePath);
    setImageUrl(imageUrl);
  };

  const getImageUrl = async (path: string): Promise<string> => {
    try {
      const storage = firebase.storage();
      const pathReference = storage.ref(path);
      const url = await pathReference.getDownloadURL();
      return url;
    } catch (error) {
      return '';
    }
  };

  return (
    // <></>
    <Paper
      key={data.productId}
      elevation={0}
      className={classes.cardProductLayout}
    >
      <CardMedia
        className={classes.media}
        image={imageUrl ? imageUrl : defaultUrlImage}
      />
      <div className={classes.cardProductDetail}>
        <Typography
          variant="h5"
          component="h2"
          className={classes.customTypographyText}
        >
          {data.productName}
        </Typography>
        <div className={classes.orderProductLayout}>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.customTypographyText}
          >
            {data.subCode} x {thousandFormat(data.amount)}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.copyIconText}
          >
            <span className={classes.textColor}>
              {/* เปลี่ยน UX ลูกค้าแสดงผลต่อชิ้น */}
              {data.price ? thousandFormat(data.price / data.amount) : 0}
            </span>{' '}
            บาท
          </Typography>
        </div>
      </div>
    </Paper>
  );
}
