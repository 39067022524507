import {
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import PersonIcon from '@material-ui/icons/Person'
import clsx from 'clsx'
import 'firebase/auth'
import React from 'react'
import { withRouter } from 'react-router-dom'
import sideMenu from '../../config/sideMenu'
import firebase from '../../firebase'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    center: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}))

const DrawerSide = (props: any) => {
    const { history } = props

    const classes = useStyles()
    const [isOpen] = React.useState(true)

    const itemsList = [
        {
            // text: sidemeu.user,
            text: 'user',
            icon: <PersonIcon />,
            onClick: () => history.push('/manageimages'),
        },
    ]

    const itemsSettingList = [
        {
            text: sideMenu.logout,
            icon: <ExitToAppIcon />,
            onClick: () => signOut(),
        },
    ]

    const signOut = () => {
        firebase
            .auth()
            .signOut()
            .then(function () {
                history.push('/login')
                console.log('firebase signOut')
            })
            .catch(function (error: any) {
                console.log('firebase error')
            })
    }

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: isOpen,
                [classes.drawerClose]: !isOpen,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: isOpen,
                    [classes.drawerClose]: !isOpen,
                }),
            }}
            open={true}
        >
            <Divider />
            <List>
                {itemsList.map((item) => {
                    const { text, icon, onClick } = item
                    return (
                        <ListItem button key={text} onClick={onClick}>
                            {icon && <ListItemIcon>{icon}</ListItemIcon>}
                            <ListItemText primary={text} />
                        </ListItem>
                    )
                })}
            </List>
            <Divider />
            <List>
                {itemsSettingList.map((item) => {
                    const { text, icon, onClick } = item
                    return (
                        <ListItem button key={text} onClick={onClick}>
                            {icon && <ListItemIcon>{icon}</ListItemIcon>}
                            <ListItemText primary={text} />
                        </ListItem>
                    )
                })}
            </List>
        </Drawer>
    )
}

export default withRouter(DrawerSide)
