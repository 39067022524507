import { Avatar, Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { Assignment } from '@material-ui/icons';
import React from 'react';
import { IProduct } from '../../../model/IProduct';
import { ISummary } from '../../../model/ISummary';
import { thousandFormat } from '../../../utils/thousandFormat';
import useStyles from '../style/style';
import OrderItem from './orderItem';

export default function CustomerOrderComponent(props: any) {
  const classes = useStyles();
  const customerProductArray: IProduct[] = props.customerProductArray;
  const summary: ISummary = props.summary;

  return (
    <Grid item xs={12}>
      <Card className={[classes.root, classes.wrapperLayout].join(' ')}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.iconProduct}>
              {' '}
              <Assignment style={{ fill: '#FF8880' }} />
            </Avatar>
          }
          title={<span className={classes.cardTitle}>รายการสั่งซื้อ</span>}
        />
        <CardContent>
          {customerProductArray.map((data) => {
            return <OrderItem key={data.productId} item={data}></OrderItem>;
          })}
          <div style={{ padding: '16px 0' }} className={classes.cardBorder}>
            <div className={classes.orderProductLayout}>
              <span>รวมราคาสินค้า</span>
              <div>
                {thousandFormat(summary.saleSummary)}{' '}
                <span className={classes.secondaryTextColor}>บาท</span>
              </div>
            </div>
            <div className={classes.orderProductLayout}>
              <span>ค่าจัดส่ง</span>
              <div>
                {thousandFormat(summary.shippingFee)}{' '}
                <span className={classes.secondaryTextColor}>บาท</span>
              </div>
            </div>
          </div>
          <div
            className={classes.orderProductLayout}
            style={{
              alignItems: 'baseline',
              paddingTop: '16px',
            }}
          >
            <div>จำนวน {thousandFormat(summary.orderSummary)} รายการ</div>
            <div>
              ยอดรวม{' '}
              <span className={classes.productSummaryPrice}>
                {thousandFormat(summary.saleSummary + summary.shippingFee)}
              </span>{' '}
              <span className={classes.secondaryTextColor}>บาท</span>
            </div>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
}
