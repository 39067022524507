import { Card, Container, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import slipIcon from '../../../images/trackingPage/slipIcon.png';
import SlipItem from './slipItem';

const useStyles = makeStyles({

    // Root
    card: {
        width: '100%',
        margin: '15px 0px',
        padding: '0px 15px'
    },
    rootContainer: {
        margin: '15px 0px 15px 0px'
    },

    // Title
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: '15px 0px'
    },
    slipIconImage: {
        width: '1rem',
        height: '1rem',
        marginRight: '15px',
    },
    title: {
        fontSize: '24px',
        fontFamily: 'kittithada',
    },
});

interface ISlipComponent {
    slipUrlList: string[]
}

export default function SlipComponent(props: ISlipComponent) {
    const classes = useStyles();

    const renderSlip = () => {
        return props.slipUrlList.map((data) => (<SlipItem key={data.link} data={data} />))
    }

    return (
        <Card className={classes.card}>
            <Container disableGutters maxWidth={false} className={classes.rootContainer}>
                <Container disableGutters maxWidth={false} className={classes.titleContainer}>
                    <img id='slipIcon' src={slipIcon} alt='slipIcon' className={classes.slipIconImage} />
                    <Typography id='title' variant='subtitle1' variantMapping={{ subtitle1: 'h6' }} className={classes.title}>สลิปโอนเงิน</Typography>
                </Container>
                {renderSlip()}
            </Container >
        </Card>
    )
}
