import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu, { MenuProps } from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { INavMenu, navMenu } from './navMenu'
import useStyles from './style'

const NavBar = (props: any) => {
    const classes = useStyles()
    const history = useHistory()

    const { title } = props
    const [isOpen] = React.useState(false)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleClickMenu = (menu: INavMenu) => {
        if (menu.onClick) {
            menu.onClick()
        }
        if (menu.redirectPath) {
            history.push(menu.redirectPath)
        }
    }

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: isOpen,
            })}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleClick}
                    edge="start"
                    className={clsx(classes.menuButton, {
                        [classes.hide]: isOpen,
                    })}
                >
                    <MenuIcon />
                </IconButton>

                <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {navMenu.map((menu, index) => {
                        return (
                            <StyledMenuItem
                                key={index}
                                onClick={() => handleClickMenu(menu)}
                            >
                                <ListItemIcon>{menu.icon}</ListItemIcon>
                                <ListItemText primary={menu.title} />
                            </StyledMenuItem>
                        )
                    })}
                </StyledMenu>

                <Typography variant="h6" noWrap>
                    {' '}
                    {title}{' '}
                </Typography>
            </Toolbar>
        </AppBar>
    )
}

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
))

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem)

export default NavBar
