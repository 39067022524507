import React from 'react'
import { Container, Grid } from '@material-ui/core'
import styled from 'styled-components'
import bgOverlay from '../../images/landing/bg_bottom_left.png'
import topicSvg from '../../images/landing/topic.svg'
import landingImg from '../../images/landing/landing.png'
import checkCircleWhite from '../../images/landing/check_circle_white.svg'
import checkCircleGreen from '../../images/landing/check_circle_green.svg'
import appStore from '../../images/landing/app_store.svg'
import googlePlay from '../../images/landing/google_play.svg'
import lineWhiteIcon from '../../images/landing/line_white_icon.svg'
import fbWhiteIcon from '../../images/landing/fb_white_icon.svg'

document.body.style.backgroundColor = '#FF9A77'
export default function LoginPage() {
    return (
        <BgContainer>
            <BgOverlay src={bgOverlay} />
            <CustomContainer>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Topic>
                            <img className="logo" src={topicSvg} alt="topic" />
                            <div>
                                <h2 className="text">LIVE ง่าย! ยอดขาย ปัง!</h2>
                                <h2 className="text">ปิดการขายได้แบบมือโปร</h2>
                            </div>
                        </Topic>
                        <img
                            src={landingImg}
                            alt="content"
                            style={{ width: '100%' }}
                        />
                    </Grid>
                    <GridContentRight item xs={12} md={6}>
                        <ContentText>
                            <h2 className="title">Melon LIVE คืออะไร?</h2>
                            <p className="desc">
                                เป็นเหมือน “รีโมทคอนโทรล” ช่วยให้ LIVE
                                ขายของง่าย สะดวก
                                ช่วยจัดการและการขายได้อย่างมีประสิทธิภาพ
                            </p>
                            <ul className="list">
                                <li>
                                    <img src={checkCircleWhite} alt="content" />
                                    <span>
                                        มีระบบ CF LIVE สด อัตโนมัติ
                                        ไม่เสียเวลาอ่านคอมเมนต์
                                    </span>
                                </li>
                                <li>
                                    <img src={checkCircleWhite} alt="content" />
                                    <span>
                                        รู้ว่าลูกค้าคนไหน CF สินค้าใดบ้าง
                                        และแสดงรายชื่อลูกค้าตามลำดับ CF แบบ
                                        Realtime!
                                    </span>
                                </li>
                                <li>
                                    <img src={checkCircleWhite} alt="content" />
                                    <span>
                                        แจ้ง Inbox ถึงลูกค้าทันทีที่เมื่อลูกค้า
                                        CF
                                    </span>
                                </li>
                                <li>
                                    <img src={checkCircleWhite} alt="content" />
                                    <span>
                                        สรุปยอดให้ลูกค้าหลัง LIVE สด ได้ทันที
                                    </span>
                                </li>
                                <li>
                                    <img src={checkCircleWhite} alt="content" />
                                    <span>ใช้งานได้ไม่จำกัดจำนวนแฟนเพจ</span>
                                </li>
                            </ul>
                        </ContentText>
                        <AdBoxWrapper>
                            <AdTextWrapper>
                                <img
                                    src={checkCircleGreen}
                                    alt="success icon"
                                    className="adImage"
                                />
                                <span className="adText">โหลดฟรี</span>
                            </AdTextWrapper>
                            <AdTextWrapper>
                                <img
                                    src={checkCircleGreen}
                                    alt="success icon"
                                    className="adImage"
                                />
                                <span className="adText">ใช้ฟรี</span>
                            </AdTextWrapper>
                            <AdTextWrapper>
                                <img
                                    src={checkCircleGreen}
                                    alt="success icon"
                                    className="adImage"
                                />
                                <span className="adText">ไม่มีโฆษณา</span>
                            </AdTextWrapper>
                        </AdBoxWrapper>
                        <CustomFooterContainer container>
                            <Grid item xs={8} sm={7} md={6}>
                                <InfoTitle>ดาวน์โหลดแอปพลิเคชัน</InfoTitle>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <a
                                            href="https://apps.apple.com/us/app/id1508261567"
                                            target="_blank"
                                        >
                                            <img
                                                src={appStore}
                                                alt="app store icon"
                                                style={{
                                                    width: '100%',
                                                    paddingRight: '16px',
                                                    maxWidth: '200px',
                                                }}
                                            />
                                        </a>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <a
                                            href="https://play.google.com/store/apps/details?id=com.praneat.livemote"
                                            target="_blank"
                                        >
                                            <img
                                                src={googlePlay}
                                                alt="google play"
                                                style={{
                                                    width: '100%',
                                                    paddingRight: '16px',
                                                    maxWidth: '200px',
                                                }}
                                            />
                                        </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} sm={5} md={6}>
                                <InfoTitle>ดูข้อมูลเพิ่มเติม</InfoTitle>
                                <InfoContact
                                    href="https://line.me/R/ti/p/%40056msotj"
                                    target="_blank"
                                >
                                    <img
                                        src={lineWhiteIcon}
                                        alt="Line icon"
                                        className="adImage"
                                    />
                                    <span>@MelonLIVE</span>
                                </InfoContact>
                                <InfoContact
                                    href="https://www.facebook.com/hellomelonlive"
                                    target="_blank"
                                >
                                    <img
                                        src={fbWhiteIcon}
                                        alt="Facebook icon"
                                        className="adImage"
                                    />
                                    <span>Melon LIVE</span>
                                </InfoContact>
                            </Grid>
                        </CustomFooterContainer>
                    </GridContentRight>
                </Grid>
            </CustomContainer>
        </BgContainer>
    )
}

const BgContainer = styled.div`
    background-color: #ff9a77;
    min-height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
`

const CustomContainer = styled(Container)`
    padding-top: 48px;
    padding-bottom: 48px;
    @media (max-width: ${(props) => props.theme.breakpoint.md}px) {
        padding-top: 36px;
        padding-bottom: unset;
    }
`

const BgOverlay = styled.img`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 430px;
    @media (max-width: ${(props) => props.theme.breakpoint.md}px) {
        bottom: unset;
        top: 0;
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }
`

const Topic = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 56px;
    .logo {
        width: 112px;
        margin-right: 20px;
    }
    .text {
        font-family: 'kittithada';
        color: #ffffff;
        font-size: 48px;
        text-shadow: 0px 5px 10px #00000020;
        margin: 0;
        line-height: 0.9;
    }
    @media (max-width: ${(props) => props.theme.breakpoint.md}px) {
        justify-content: center;
        margin-bottom: 48px;
        .logo {
            width: 74px;
            margin-right: 20px;
        }
        .text {
            font-size: 36px;
        }
    }
    @media (max-width: 350px) {
        .logo {
            width: 60px;
            margin-right: 16px;
        }
        .text {
            font-size: 28px;
        }
    }
`

const GridContentRight = styled(Grid)`
    padding-left: 80px;
    @media (max-width: ${(props) => props.theme.breakpoint.md}px) {
        padding-left: unset;
    }
`

const ContentText = styled.div`
    margin-top: 124px;
    width: 100%;
    font-family: 'kittithada';
    .title {
        color: #ffffff;
        font-size: 34px;
        margin: 0;
    }
    .desc {
        color: #ffffff;
        font-size: 28px;
        line-height: 1;
        margin: 0;
        max-width: 480px;
        width: 100%;
    }
    .list {
        margin-top: 28px;
        padding: 0;
        list-style: none;
        max-width: 362px;
        li {
            font-family: 'PslTextNewPro';
            font-size: 22px;
            color: #ffffff;
            display: flex;
            align-items: baseline;
            margin-bottom: 8px;
            img {
                width: 14px;
                margin-right: 8px;
            }
            span {
                line-height: 1.2;
            }
        }
    }
    @media (max-width: ${(props) => props.theme.breakpoint.md}px) {
        margin-top: 48px;
        .title {
            text-align: center;
        }
        .desc {
            margin: 0 auto;
        }
        .list {
            margin: 0 auto;
            margin-top: 28px;
            max-width: 480px;
        }
    }
    @media (max-width: ${(props) => props.theme.breakpoint.sm}px) {
        .title {
            font-size: 30px;
        }
        .desc {
            font-size: 24px;
        }
    }
`

const AdBoxWrapper = styled.div`
    width: 100%;
    max-width: 480px;
    background-color: #ffffff;
    border-radius: 36px;
    padding: 12px 20px;
    margin: 32px 0;
    display: flex;
    justify-content: space-between;
    @media (max-width: ${(props) => props.theme.breakpoint.md}px) {
        margin: 32px auto;
    }
`

const AdTextWrapper = styled.div`
    display: flex;
    align-items: center;
    .adText {
        font-family: 'kittithada';
        font-size: 32px;
        font-weight: bold;
        color: #3c3b40;
    }
    .adImage {
        width: 20px;
        margin-right: 8px;
    }
    @media (max-width: ${(props) => props.theme.breakpoint.sm}px) {
        .adText {
            font-size: 28px;
        }
        .adImage {
            width: 18px;
        }
    }
    @media (max-width: 350px) {
        .adText {
            font-size: 20px;
        }
        .adImage {
            width: 16px;
        }
    }
`

const CustomFooterContainer = styled(Grid)`
    @media (max-width: ${(props) => props.theme.breakpoint.md}px) {
        margin: 0 -24px;
        padding: 32px 24px;
        background-color: #fa7d52;
        width: calc(100% + 48px);
    }
    @media (max-width: ${(props) => props.theme.breakpoint.sm}px) {
        margin: 0 -16px;
        padding: 32px 16px;
        width: calc(100% + 32px);
    }
`

const InfoTitle = styled.p`
    font-family: 'kittithada';
    margin: 0;
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    @media (max-width: ${(props) => props.theme.breakpoint.sm}px) {
        font-size: 20px;
    }
`

const InfoContact = styled.a`
    display: flex;
    align-items: center;
    font-family: 'kittithada';
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 4px;
    text-decoration: none;
    width: max-content;
    img {
        width: 14px;
        margin-right: 4px;
    }
`
