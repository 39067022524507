import React from 'react';
import { createGlobalStyle } from 'styled-components';
import FullScreenLoading from '../component/fullScreenloading';

const GlobalStyle = createGlobalStyle`
  html, body {
    overflow: hidden;
  }
`
export default function Loading() {
  return (
    <>
      <GlobalStyle />
      <FullScreenLoading />
    </>
  )
}