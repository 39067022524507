import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ImageIcon from '@material-ui/icons/Image'
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import firebase from 'firebase'
import React from 'react'
import { PageType } from '../../utils/PageTypes'

export interface INavMenu {
    title: string
    onClick?: () => void
    icon?: JSX.Element
    redirectPath?: string
}

export const navMenu: INavMenu[] = [
    {
        title: 'Report Facebook Page',
        onClick: () => {},
        icon: <TrendingUpIcon fontSize="small" />,
        redirectPath: PageType.ReportPage.path,
    },
    {
        title: 'Manage Product Images',
        onClick: () => {},
        icon: <ImageIcon fontSize="small" />,
        redirectPath: PageType.ManageImages.path,
    },
    {
        title: 'Patcher',
        onClick: () => {},
        icon: <SentimentVerySatisfiedIcon fontSize="small" />,
        redirectPath: PageType.PatchPage.path,
    },
    {
        title: 'Sign Out',
        onClick: async () => {
            await firebase.auth().signOut()
        },
        icon: <ExitToAppIcon fontSize="small" />,
        redirectPath: PageType.Login.path,
    },
]
