export interface ITextMaskCustom {
    inputRef: (ref: HTMLInputElement | null) => void;
}

export interface IRegisData {
    registerName: string,
    contactName: string,
    telephone: string,
    email: string,
    address: string,
    subDistrict: string,
    district: string,
    province: string,
    postcode: string,
}

export interface IUserRegis extends IRegisData {
    websiteFacebookPage: string,
    isSelectedShippop: boolean,
    isSelectedPaySolution: boolean,
}

export const defaultErrMessage: IRegisData = {
    registerName: '',
    contactName: '',
    telephone: '',
    email: '',
    address: '',
    subDistrict: '',
    district: '',
    province: '',
    postcode: '',
}

export const defaultUserRegis: IUserRegis = {
    ...defaultErrMessage,
    websiteFacebookPage: '',
    isSelectedShippop: false,
    isSelectedPaySolution: false,
}
