import { Grid } from '@material-ui/core';
import every from 'lodash/every';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import some from 'lodash/some';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import styled from 'styled-components';
import topicSvg from '../../images/landing/topic.svg';
import provinceData from '../../static/province.json';
import { useSetState } from '../../utils/customSetState';
import CheckboxComponent from './components/checkboxComponent';
import FormFieldComponent from './components/formFieldComponent';
import LoadingComponent from './loading';
import { defaultErrMessage, defaultUserRegis, IRegisData, IUserRegis } from './models/RegisModel';
import { RegisAPI } from './services';
import { IRegisState } from './shareds/inputFields';

export default function RegisterOnlineServicePage() {
    const [loading, setLoading] = useState(true)
    const [loadingSubmit, setLoadingSubmit] = useState(false)

    const location = useLocation();
    const history = useHistory();

    const [userRegisData, setUserRegisData, getUserRegisData] = useSetState<IUserRegis>(defaultUserRegis)

    const [tempLastCheckbox, setTempLastCheckbox] = useState({
        isSelectedShippop: false,
        isSelectedPaySolution: false,
    })

    const [errMessage, setErrMessage] = useState<IRegisData>(defaultErrMessage)
    const [canShowCheckboxElement, setCanShowCheckboxElement] = useState(true)

    useEffect(() => {
        getUserData()
    }, [])

    const getUserData = async () => {
        const search = location.search;
        const key = new URLSearchParams(search).get('key');

        if (!key) history.push("/register/onlineservice/expire");

        try {
            const userData = await RegisAPI.getUserRegisData(key)
            if (!userData) history.push("/register/onlineservice/expire");

            const { contactName, email, isSelectedPaySolution, isSelectedShippop, registerName, telephone, websiteFacebookPage } = userData
            if (isSelectedPaySolution && isSelectedShippop) setCanShowCheckboxElement(false)

            setUserRegisData({
                registerName: registerName || '',
                contactName: contactName || '',
                telephone: telephone || '',
                email: email || '',
                address: get(userData, 'addressList[0].address', ''),
                subDistrict: get(userData, 'addressList[0].subDistrict', ''),
                district: get(userData, 'addressList[0].district', ''),
                province: get(userData, 'addressList[0].province', provinceData[1]),
                postcode: get(userData, 'addressList[0].postcode', ''),
                websiteFacebookPage: websiteFacebookPage,
                isSelectedShippop: isSelectedShippop || false,
                isSelectedPaySolution: isSelectedPaySolution || false,
            })

            setTempLastCheckbox({
                isSelectedShippop: isSelectedShippop || false,
                isSelectedPaySolution: isSelectedPaySolution || false,
            })

            await getUserRegisData()
            setLoading(false)
        } catch (e) {
            console.error('registerOnlineServicePage getUserData Error', e)
            history.push("/register/onlineservice/expire");
        }
    }

    const validateCondition = (): boolean => {
        const mainUserData = omit(userRegisData, ['isSelectedShippop', 'isSelectedPaySolution'])
        const isMainUserComplete = !(some(mainUserData, isEmpty))
        const havaError = !(every(errMessage, isEmpty))
        const { isSelectedShippop, isSelectedPaySolution } = userRegisData
        return (
            isMainUserComplete &&
            (isSelectedShippop || isSelectedPaySolution) &&
            !havaError
        ) ? true : false;
    }

    const submitForm = async () => {
        setLoadingSubmit(true)
        const search = location.search;
        const key = new URLSearchParams(search).get('key');
        try {
            const result = await RegisAPI.submitUserRegis(key, userRegisData)
            result ? history.push("/register/onlineservice/success") : history.push("/register/onlineservice/expire");
        } catch (e) {
            console.error('registerOnlineServicePage submitForm Error', e)
            history.push("/register/onlineservice/expire");
        }
    }

    const groupRegisState: IRegisState = {
        regisState: userRegisData,
        setRegisState: setUserRegisData,
        errorState: errMessage,
        setErrorState: setErrMessage
    }
    return (
        <>
            {loading ? <LoadingComponent /> :
                <>
                    {loadingSubmit && <LoadingComponent />}
                    <BgContainer>
                        <Heading>
                            <LogoImg src={topicSvg} alt="logo" width="60" height="60" />
                            <div>
                                <Title>สมัครบริการขนส่งออนไลน์</Title>
                                <Title>และชำระเงินออนไลน์</Title>
                            </div>
                        </Heading>
                        <FormWrapper>
                            <Grid container spacing={3}>
                                <FormFieldComponent groupRegisState={groupRegisState} provinceData={provinceData} />
                                <Grid item xs={12}>
                                    <CheckboxComponent shouldShow={canShowCheckboxElement} userRegisData={userRegisData}
                                        setUserRegisData={setUserRegisData} tempLastCheckbox={tempLastCheckbox} />
                                    <div style={{ textAlign: 'center' }}>
                                        <Button onClick={() => submitForm()} disabled={!validateCondition()}>ส่งข้อมูล</Button>
                                    </div>
                                    <CreditWrapper>
                                        By Melon LIVE
                                        <img src={topicSvg} alt="logo" width="32" height="32" />
                                    </CreditWrapper>
                                </Grid>
                            </Grid>
                        </FormWrapper>
                    </BgContainer>
                </>}
        </>
    )
}
const BgContainer = styled.div`
    font-family: 'kittithada';
    background-color: #ff9a77;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    padding: 12px 32px;
`
const Heading = styled.div`
    display: flex;
    align-items: center;
    max-width: 737px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 24px;
    @media (max-width: ${(props) => props.theme.breakpoint.md}px) {
        margin-bottom: 16px;
    }
`
const LogoImg = styled.img`
    width: 80px;
    height: 80px;
    margin-right: 20px;
    @media (max-width: ${(props) => props.theme.breakpoint.md}px) {
        width: 60px;
        height: 60px;
        margin-right: 16px;
    }
    @media (max-width: ${(props) => props.theme.breakpoint.sm}px) {
        width: 45px;
        height: 45px;
    }
`
const Title = styled.h2`
    color: #ffffff;
    font-size: 48px;
    margin: 0;
    line-height: 0.9;
    @media (max-width: ${(props) => props.theme.breakpoint.md}px) {
        font-size: 36px;
    }
    @media (max-width: ${(props) => props.theme.breakpoint.sm}px) {
        font-size: 28px;
    }
    @media (max-width: 350px) {
        font-size: 24px;
    }
`
const FormWrapper = styled.div`
    background-color: #ffffff;
    padding: 48px;
    max-width: 737px;
    width: 100%;
    margin: 0 auto;
    border-radius: 8px;
    @media (max-width: ${(props) => props.theme.breakpoint.md}px) {
        padding: 24px;
    }
`
const Button = styled.button`
    font-family: 'kittithada';
    font-size: 36px;
    color: #ffffff;
    width: 100%;
    max-width: 238px;
    padding: 4px;
    margin: 32px auto;
    background-color: #FF561C;
    border-radius: 27px;
    border: unset;
    outline: 0;
    cursor: pointer;
    &:disabled {
        color: rgba(0,0,0,.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
        text-shadow: none;
        box-shadow: none;
        cursor: not-allowed;
    }
`
const CreditWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #9C9BA2;
    img {
        margin-left: 16px;
        width: 32px;
        height: 32px;
    }
`