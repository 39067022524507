import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bgColor: { backgroundColor: '#ffffff', minHeight: '100vh' },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  textCenter: {
    textAlign: 'center',
  },
  cardSpaceing: {
    paddingTop: '50px',
  },
  textTitle: {
    color: '#3C3B40',
    fontSize: '40px',
    fontFamily: 'Kittithada',
  },
}));

export default useStyles;
