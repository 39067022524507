import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import successImg from '../../images/registerOnlineService/success-img.png';

export default function RegisterOnlineServiceSuccessPage() {
    const GlobalStyle = createGlobalStyle`
    body {
        background-color: #ffffff !important;
    }
`
    return (
        <>
            <GlobalStyle />
            <Container>
                <ContentWrapper>
                    <p className="content-title">ลงทะเบียนสำเร็จ</p>
                    <LogoImg src={successImg} alt="expire" width="250" height="192" />
                    <p className="content-desc">ขอบคุณสําหรับการลงทะเบียน</p>
                    <p className="content-desc">กรุณารอทางทีม SHIPPOP และ Pay Solutions</p>
                    <p className="content-desc">ติดต่อกลับหาคุณภายใน 1-2 วันทําการ</p>
                </ContentWrapper>
            </Container>
        </>
    )
}

const Container = styled.div`
    font-family: 'kittithada';
    padding: 16px;
    position: relative;
    height: 100vh;
    width: 100%;
`

const ContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .content-title {
      font-size: 32px;
      color: #3C3B40;
      margin: 0;
      text-align: center;
      line-height: 1;
  }
  .content-desc {
      font-size: 24px;
      color: #9C9BA2;
      margin: 0;
      text-align: center;
      line-height: 1;
      width: auto;
  }
  @media (max-width: ${(props) => props.theme.breakpoint.sm}px) {
    .content-desc {
        font-size: 22px;
        width: 324px;
    }
  }
`

const LogoImg = styled.img`
    margin-top: 48px;
    margin-bottom: 32px;
`
