import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from '../style/style';

export default function HeaderComponent(props: any) {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.title}>
      <Typography noWrap> ShopLiveRemote </Typography>
    </Grid>
  );
}
