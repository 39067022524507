import { FilledInputProps, Grid, GridSize, InputProps, MenuItem, OutlinedInputProps, TextField } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { IRegisData, IUserRegis } from '../models/RegisModel';

type PropsType = {
    label: string
    name: string
    errMessageTxt: string
    state: IRegisState
    regisValue: string
    InputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps> | undefined
    type?: string
    inputMode?: "text" | "none" | "tel" | "url" | "email" | "numeric" | "decimal" | "search" | undefined
    md?: GridSize
}

export interface IRegisState {
    regisState: IUserRegis
    errorState: IRegisData
    setRegisState: React.Dispatch<React.SetStateAction<IUserRegis>>
    setErrorState: React.Dispatch<React.SetStateAction<IRegisData>>
}

const validateValue = (event: ChangeEvent<HTMLInputElement>, errMessage: IRegisData, setErrMessage: React.Dispatch<React.SetStateAction<IRegisData>>) => {
    const { target } = event;
    const { name, value } = target;
    if (!value) return setErrMessage({ ...errMessage, ...{ [name]: 'กรุณากรอกข้อมูลส่วนนี้' } });
    if (name === 'telephone' && isInvalidPhonenumber(value)) {
        setErrMessage({ ...errMessage, ...{ [name]: 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง' } });
    } else if (name === 'postcode' && value.length !== 5) {
        setErrMessage({ ...errMessage, ...{ [name]: 'กรุณากรอกรหัสไปรษณีย์ให้ถูกต้อง' } });
    } else if (name === 'email' && isInvalidEmail(value)) {
        setErrMessage({ ...errMessage, ...{ [name]: 'กรุณากรอกอีเมลให้ถูกต้อง' } });
    } else {
        setErrMessage({ ...errMessage, ...{ [name]: '' } });
    }
}

const isInvalidEmail = (mail: string): boolean => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) return false
    return true
}

const isInvalidPhonenumber = (inputPhoneNumber: string): boolean => {
    var phoneno = /^\(?(0[5-9]{1}[0-9]{1})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if ((inputPhoneNumber.match(phoneno))) return false;
    return true;
}

export const InputRegis = (props: PropsType) => {
    const { md = 12, label, name, errMessageTxt, regisValue,
        state: { setRegisState, setErrorState, regisState, errorState },
        InputProps = undefined, type = 'text', inputMode = 'text' } = props;

    return (
        <Grid item xs={12} md={md}>
            <CustomLabel>
                {label}
                <span>*</span>
            </CustomLabel>
            <CustomTextField
                id={name}
                name={name}
                variant="outlined"
                value={regisValue}
                onChange={(e) => (setRegisState({ ...regisState, ...{ [e.target.name]: e.target.value } }), validateValue(e as ChangeEvent<HTMLInputElement>, errorState, setErrorState))}
                onBlur={(e) => validateValue(e as ChangeEvent<HTMLInputElement>, errorState, setErrorState)}
                error={errMessageTxt !== ''}
                helperText={errMessageTxt}
                InputProps={InputProps}
                type={type}
                inputMode={inputMode}
            />
        </Grid>
    );
}

export const WebsiteField = (prop: { websiteFacebookPage: string }) => {
    return (
        <Grid item xs={12}>
            <CustomLabel>
                เว็บไซต์/เฟชบุ๊กแฟนเพจ
            </CustomLabel>
            <CustomTextField
                id="websiteFacebookPage"
                name="websiteFacebookPage"
                variant="outlined"
                value={prop.websiteFacebookPage}
                inputMode="url"
                disabled
            />
        </Grid>
    )
}

export const ProvinceField = (props: { userRegisData: IUserRegis, provinceData: any[], setRegisState: React.Dispatch<React.SetStateAction<IUserRegis>> }) => {
    const { userRegisData, provinceData, setRegisState } = props
    const { province } = userRegisData
    return (
        <Grid item xs={12} md={6}>
            <CustomLabel>
                จังหวัด
                <span>*</span>
            </CustomLabel>
            <CustomTextField
                id="province"
                name="province"
                variant="outlined"
                select
                error={false}
                value={province}
                onChange={(e) => setRegisState({ ...userRegisData, province: e.target.value })}
            >
                {provinceData.map((item) => (
                    <MenuItem key={item} value={item}>
                        {item}
                    </MenuItem>
                ))}
            </CustomTextField>
        </Grid>
    )
}

const CustomLabel = styled.label`
    color: #3C3B40;
    font-size: 24px;
    margin-bottom: 8px;
    span {
        color: #E00000;
    }
`
const CustomTextField = styled(TextField)`
    width: 100%;
    margin-bottom: 16px;
    .MuiInputBase-input {
        background-color: #F3F2F6;
        border-radius: 8px;
    }
    .MuiInputBase-root {
        border-radius: 8px;
    }
`