import { makeStyles } from '@material-ui/core'
import 'firebase/auth'
import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { firebaseAnalytic, isFirebaseAuthed } from './firebase'
import * as Page from './page'
import { PageType } from './utils/PageTypes'

const TRACKING_ID = 'G-W84WSX24E9'
ReactGA.initialize(TRACKING_ID)

const useStyles = makeStyles({
    container: {
        display: 'flex',
    },
})

const theme = {
    breakpoint: {
        sm: 599,
        md: 959,
        lg: 1279,
        xl: 1919,
    },
}

function App() {
    useEffect(() => {
        firebaseAnalytic()
        ReactGA.pageview(window.location.pathname + window.location.search)
    }, [])
    const classes = useStyles()
    return (
        <ThemeProvider theme={theme}>
            <div className={classes.container}>
                <Switch>
                    {/* CMS */}
                    <Route
                        exact
                        sensitive
                        path={PageType.Login.path}
                        component={Page.LoginPage}
                    />
                    <AuthRoute
                        exact
                        sensitive
                        path={PageType.ManageImages.path}
                        component={Page.ManageImagesPage}
                    />
                    <AuthRoute
                        exact
                        sensitive
                        path={PageType.ReportPage.path}
                        component={Page.ReportPage}
                    />
                    <AuthRoute
                        exact
                        sensitive
                        path={PageType.ReportDetailPage.path}
                        component={Page.ReportDetailPage}
                    />
                    <AuthRoute
                        exact
                        sensitive
                        path={PageType.PatchPage.path}
                        component={Page.PatchPage}
                    />
                    {/* Tracking, Payment, Shipping */}
                    <Route
                        exact
                        sensitive
                        path={PageType.Tracking.path}
                        component={Page.TrackingPage}
                    />
                    <Route
                        exact
                        sensitive
                        path={PageType.PaySolution.path}
                        component={Page.PaySolutionPage}
                    />
                    <Route
                        exact
                        sensitive
                        path={PageType.Thankyou.path}
                        component={Page.ThankyouPage}
                    />
                    <Route
                        exact
                        sensitive
                        path={PageType.RegisterOnlineServicePage.path}
                        component={Page.RegisterOnlineServicePage}
                    />
                    <Route
                        exact
                        sensitive
                        path={PageType.RegisterOnlineServiceExpirePage.path}
                        component={Page.RegisterOnlineServiceExpirePage}
                    />
                    <Route
                        exact
                        sensitive
                        path={PageType.RegisterOnlineServiceSuccessPage.path}
                        component={Page.RegisterOnlineServiceSuccessPage}
                    />
                    {/* Others */}
                    <Route
                        exact
                        sensitive
                        path={PageType.Policy.path}
                        component={Page.PolicyPage}
                    />
                    <Route exact path="/" component={Page.LandingPage} />
                </Switch>
            </div>
        </ThemeProvider>
    )
}

interface IAuthRouteProps extends RouteProps {
    component: any
    rest?: any
}

const AuthRoute = ({ component: Component, ...rest }: IAuthRouteProps) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                isFirebaseAuthed ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: PageType.Login.path }} />
                )
            }
        />
    )
}
export default App
