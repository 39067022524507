import React from "react";
import styled from 'styled-components';
import paySolutionImg from '../../../images/registerOnlineService/paySolution.png';
import shipopImg from '../../../images/registerOnlineService/shipop.png';
import { IUserRegis } from "../models/RegisModel";
import { CheckboxRegis } from "../shareds/checkboxFields";

type PropsType = {
    shouldShow: boolean
    userRegisData: IUserRegis
    setUserRegisData: React.Dispatch<React.SetStateAction<IUserRegis>>
    tempLastCheckbox: {
        isSelectedShippop: boolean;
        isSelectedPaySolution: boolean;
    }
}

export default function CheckboxComponent(props: PropsType) {
    const { shouldShow, userRegisData, setUserRegisData, tempLastCheckbox } = props
    return (<>
        {shouldShow &&
            <CheckboxWrapper>
                <p className="checkbox-title">
                    บริการที่ต้องการสมัคร
                </p>
                <CheckboxRegis regisState={userRegisData} targetCheckbox={'isSelectedShippop'}
                    setRegisState={setUserRegisData} tempLastCheckbox={tempLastCheckbox.isSelectedShippop}
                    checkboxValue={userRegisData.isSelectedShippop} imgUrl={shipopImg}
                    label={'บริการขนส่งออนไลน์'} name={'shippop'}
                />
                <CheckboxRegis regisState={userRegisData} targetCheckbox={'isSelectedPaySolution'}
                    setRegisState={setUserRegisData} tempLastCheckbox={tempLastCheckbox.isSelectedPaySolution}
                    checkboxValue={userRegisData.isSelectedPaySolution} imgUrl={paySolutionImg}
                    label={'บริการชำระเงินออนไลน์'} name={'paySolution'}
                />
            </CheckboxWrapper>
        } </>)
}

const CheckboxWrapper = styled.div`
    .checkbox-title {
        font-size: 28px;
        color: #3C3B40;
        margin-bottom: 16px;
    }
`