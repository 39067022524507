import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,

  Container,

  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core';
import { Assignment, AttachFile, Close } from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import * as _ from 'lodash';
import React, { useEffect } from 'react';
import { StatusEnum } from '../../../enum/statusEnum';
import copyIcon from '../../../images/copyIcon.svg';
import { IBankAccount } from '../../../model/IBankAccount';
import bankData from '../../../static/bank.json';
import { IPaymentMethodTypeModel, PaymentMethodType } from '../../../utils/paymentMethodType';
import useStyles from '../style/style';
import SlipItem from './slipItem';

export default function CustomerBankSlipComponent(props: any) {
  const classes = useStyles();

  const [slipList, setSlipList] = React.useState<string[]>([]);
  const [bankAccountList, setBankAccountList] = React.useState<IBankAccount[] | null>(null)
  const [paySolutionAccountList, setPaySolutionAccount] = React.useState<IBankAccount[] | null>(null)
  const [showClipboardPopup, setShowClipboardPopup] = React.useState<boolean>(
    false,
  );

  const [paymentMethodDropdownItem, setPaymentMethodDropdownItem] = React.useState<IPaymentMethodTypeModel[]>([])

  useEffect(() => {
    setSlipList(props.slipList);

    const paymentAccountList: IBankAccount[] = props.bankAccounts
    const bankAccountList = paymentAccountList.filter((paymentAccount) => paymentAccount.bankName !== 'Pay Solutions')
    setBankAccountList(bankAccountList)

    const hasPaySolutions = _.some(paymentAccountList, (paymentAccount) => paymentAccount.bankName === 'Pay Solutions')
    if (hasPaySolutions) {
      const paySolutionAccountList = paymentAccountList.filter((paymentAccount) => paymentAccount.bankName === 'Pay Solutions')
      setPaySolutionAccount(paySolutionAccountList)
      setPaymentMethodDropdownItem([
        PaymentMethodType.PaySolutions,
        PaymentMethodType.Bank
      ])
    } else {
      setPaymentMethodDropdownItem([PaymentMethodType.Bank])
      props.setSelectedPaymentMethod(PaymentMethodType.Bank.value)
    }

  }, [props]);

  const copyToClipboard = (bankNumber: string) => {
    setShowClipboardPopup(true);
    const textField = document.createElement('textarea');
    textField.innerText = bankNumber;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowClipboardPopup(false);
  };

  const renderBankIcon = (bankName: string) => {
    let output = <></>;
    const imgData = bankData.find((item) => {
      return item.name === bankName;
    });
    output = (
      <img
        src={require(`../../../${imgData?.icon}`)}
        style={{ width: '24px', height: '24px', marginRight: '8px' }}
        alt={`${imgData?.name} logo`}
      />
    );
    return output;
  };

  const renderBankName = (bankName: string) => {
    return (
      <Typography
        variant="body2"
        component="p"
        className={[
          classes.bankDetailContent,
          classes.bankIconWrapper,
        ].join(' ')}
      >
        {bankName}
      </Typography>
    )
  }

  const renderBankAccount = (bankAccountNumber: string) => {
    return (
      <span className={classes.bankContactText}>
        {bankAccountNumber}
      </span>
    )
  }

  const renderCopyBankAccountButton = (bankAccountNumber: string) => {
    return (
      <Button
        variant="contained"
        size="small"
        className={classes.copyBtn}
        onClick={() => copyToClipboard(bankAccountNumber)}
      >
        <img
          src={copyIcon}
          className={classes.copyIconStyle}
          alt="copy icon"
        />{' '}
        <span className={classes.copyIconText}>คัดลอก</span>
      </Button>
    )
  }

  const renderAccountName = (accountName: string) => {
    return (
      <Typography
        variant="body2"
        component="p"
        className={[
          classes.bankDetailContent,
          classes.bankContactText,
        ].join(' ')}
      >
        {accountName}
      </Typography>
    )
  }

  const renderAccountNumberContainer = (bankAccountNumber: string) => {
    return (
      <Typography
        variant="body2"
        component="p"
        className={[
          classes.bankDetailContent,
          classes.cardBorder,
          classes.bankNumberWrapper,
        ].join(' ')}
      >
        {renderBankAccount(bankAccountNumber)}
        {renderCopyBankAccountButton(bankAccountNumber)}
      </Typography>
    )
  }

  const renderCustomerBankSlipComponent = () => {
    return (
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {slipList.map((data) => (
            <SlipItem
              key={data}
              data={data}
              deleteSlipImg={props.deleteSlipImg}
              activeStep={props.activeStep}
            />
          ))}
        </Grid>

        {props.activeStep === StatusEnum.TRANSFERWAITING || StatusEnum.TRANSFERINVALID || StatusEnum.TRANSFERRED
          ? (
            renderAddSlipButton()
          ) : (
            <div />
          )}
      </Grid>
    );
  };

  const renderAddSlipButton = () => {
    const isDisableEditing: boolean = props.activeStep === StatusEnum.COMPLETE || props.activeStep === StatusEnum.SHIPPINGWAITING
    return (
      <>
        <Grid
          container
          justify="center"
          style={{
            marginTop: '19px',
          }}
        >
          <Button
            variant="contained"
            component="label"
            disabled={isDisableEditing}
            className={classes.btnPayment}
          >
            <AttachFile />
          แนบสลิปโอนเงิน
          <input
              type="file"
              style={{ display: 'none' }}
              onChange={props.saveSlipToDB}
              accept="image/x-png,image/jpeg"
            />
          </Button>
        </Grid>
        <Grid
          container
          justify="flex-start"
          style={{
            marginTop: '24px',
          }}
        >
          <div className={classes.annotation}>
            <span style={{ fontWeight: 'bold' }}> หมายเหตุ:&nbsp;  </span>*หากไม่สามารถแนบไฟล์สลิปได้
            </div>
          <div className={classes.annotation}>
            Android ให้คลิกมุมขวาบน (<MoreVertIcon className={classes.annotationIcon} />) เปิดใน Chrome หรือ อื่นๆ
            </div>
          <div className={classes.annotation}>
            iOS ให้คลิกมุมขวาล่าง (<OpenInBrowserIcon className={classes.annotationIcon} />) เปิดใน Safari
          หรือ อื่นๆ
            </div>
        </Grid>
      </>
    )
  }

  const renderNoPaymentMethod = () => {
    return (
      <CardContent
        key=""
        className={classes.bankAccountWrapper}
      >
        <Typography className={classes.text}>
          กรุณาติดต่อผู้ขายเพื่อขอข้อมูลการโอนเงิน
            </Typography>
      </CardContent>
    )
  }

  const renderBankPaymentMethod = () => {
    return (
      <Grid item>
        {bankAccountList && bankAccountList?.length > 0 ?
          bankAccountList?.map((data: IBankAccount) => (
            <CardContent
              key={data.account}
              className={classes.bankAccountWrapper}
            >
              <Grid container spacing={1} style={{ alignItems: 'center' }}>
                {renderBankIcon(data.bankName)}
                {renderBankName(data.bankName)}
              </Grid>

              {renderAccountNumberContainer(data.account)}
              {renderAccountName(data.name)}
            </CardContent>
          ))
          :
          renderNoPaymentMethod()
        }
      </Grid>
    )
  }

  const renderEmailInputField = () => {
    const isDisableEditing: boolean = props.activeStep === StatusEnum.TRANSFERRED || props.activeStep === StatusEnum.COMPLETE || props.activeStep === StatusEnum.SHIPPINGWAITING
    return (
      <TextField
        id='email'
        name="email"
        label="อีเมลล์"
        value={props.email}
        disabled={isDisableEditing}
        fullWidth
        margin="normal"
        variant="outlined"
        onChange={(e) => props.setEmail(e.target.value)}
      />
    )
  }

  const renderPaySolutionPaymentMethod = () => {
    return (
      <Grid item>
        {paySolutionAccountList && paySolutionAccountList?.length > 0 ?
          paySolutionAccountList?.map((data: IBankAccount) => (
            <CardContent
              key={data.account}
              className={classes.bankAccountWrapper}
            >
              <Grid container spacing={1} style={{ alignItems: 'center' }}>
                {renderBankIcon(data.bankName)}
                {renderBankName(PaymentMethodType.PaySolutions.name)}
              </Grid>
              {renderAccountName(data.name)}
              {renderEmailInputField()}
            </CardContent>
          ))
          :
          renderNoPaymentMethod()
        }
      </Grid>
    )
  }

  const renderPaymentMethodDropdown = () => {
    const isDisableEditing: boolean = props.activeStep === StatusEnum.TRANSFERRED || props.activeStep === StatusEnum.COMPLETE || props.activeStep === StatusEnum.SHIPPINGWAITING

    return (
      <Container className={classes.paymentMethodDropdownContainer}>
        <InputLabel className={classes.paymentMethodDropdownLabel}>เลือกวิธีชำระเงิน</InputLabel>
        <TextField disabled={isDisableEditing} variant='outlined' select fullWidth value={props.selectedPaymentMethod} onChange={(e) => props.setSelectedPaymentMethod(e.target.value as string)}>
          {_.map(paymentMethodDropdownItem, (method) => {
            return (
              <MenuItem value={method.value}>{method.name}</MenuItem>
            )
          })}
        </TextField>
      </Container >
    )
  }

  return (
    <Grid item xs={12} className={classes.cardSpaceing}>
      <Card className={[classes.root, classes.wrapperLayout].join(' ')}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.iconBanking}>
              {' '}
              <Assignment style={{ fill: '#32D74B' }} />
            </Avatar>
          }
          title={<span className={classes.cardTitle}>โอนเงิน และแนบสลิป</span>}
        />
        {renderPaymentMethodDropdown()}
        <CardContent>
          {props.activeStep === StatusEnum.TRANSFERWAITING || StatusEnum.TRANSFERINVALID || StatusEnum.TRANSFERRED ? (
            props.selectedPaymentMethod === PaymentMethodType.PaySolutions.value ? renderPaySolutionPaymentMethod() : renderBankPaymentMethod()
          ) : (
            <div />
          )}
          {props.selectedPaymentMethod === PaymentMethodType.Bank.value ? renderCustomerBankSlipComponent() : null}
        </CardContent>
      </Card>
      <Snackbar
        open={showClipboardPopup}
        autoHideDuration={2000}
        onClose={closeSnackbar}
        message="คัดลอกแล้ว"
        action={
          <IconButton color="inherit" onClick={closeSnackbar}>
            <Close />
          </IconButton>
        }
      />
    </Grid>
  );
}
