export const PageType = {
    // CMS
    Login: {
        name: 'Login',
        path: '/login',
    },
    ManageImages: {
        name: 'ManageImages',
        path: '/manage-product-images',
    },
    ReportPage: {
        name: 'ReportPage',
        path: '/report',
    },
    ReportDetailPage: {
        name: 'ReportDetailPage',
        path: '/report/:pageId',
    },
    PatchPage: {
        name: 'PatchPage',
        path: '/patch',
    },
    // Tracking, Payment, Shipping
    Tracking: {
        name: 'Tracking',
        path: '/tracking/:pageId/live/:liveId/userId/:userId',
    },
    PaySolution: {
        name: 'PaySolutionPage',
        path: '/paySolution/:refno/:merchantid/:email/:productdetail/:total',
    },
    Thankyou: {
        name: 'ThankyouPage',
        path: '/paysolution/thankyou',
    },
    RegisterOnlineServicePage: {
        name: 'RegisterOnlineServicePage',
        path: '/register/onlineservice',
    },
    RegisterOnlineServiceExpirePage: {
        name: 'RegisterOnlineServiceExpirePage',
        path: '/register/onlineservice/expire',
    },
    RegisterOnlineServiceSuccessPage: {
        name: 'RegisterOnlineServiceSuccessPage',
        path: '/register/onlineservice/success',
    },
    // Others
    Policy: {
        name: 'Policy',
        path: '/policy.html',
    },
}
