import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import facebook from '../../../images/landing/social_facebook.svg'
import line from '../../../images/landing/social_line.svg'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bg: {
            backgroundColor: '#f2f1f6',
            width: 'calc(100% + 48px)',
            padding: theme.spacing(3),
            paddingTop: '8px',
            position: 'relative',
            margin: '0 -24px',
            alignItems: 'center',
            [theme.breakpoints.down('xs')]: {
                width: 'calc(100% + 40px)',
            },
        },
        title: {
            fontSize: '18px',
            color: '#3c3b40',
            fontFamily: 'kittithada',
        },
        group: {
            marginTop: '-24px',
        },
        groupSocial: {
            alignItems: 'center',
        },
        linkSocialFacebook: {
            textDecoration: 'none',
            borderRight: '1px solid #3c3b40',
        },
        linkSocialLine: {
            marginLeft: theme.spacing(1),
            textDecoration: 'none',
        },
        social: {
            fontSize: '16px',
            color: '#3c3b40',
            fontFamily: 'PslTextNewPro',
            padding: theme.spacing(1),
            alignItems: 'flex-start',
            [theme.breakpoints.down('xs')]: {
                fontSize: '13px',
            },
        },
        groupTextPresent: {
            fontSize: '16px',
            color: '#3c3b40',
            fontFamily: 'PslTextNewPro',
            fontWeight: 'bold',
            [theme.breakpoints.down('xs')]: {
                fontSize: '12px',
            },
        },
        melonLive: {
            color: '#ff561c',
        },
    }),
)

export default function FooterComponent() {
    const classes = useStyles()
    return (
        <Grid container item spacing={2} className={classes.bg}>
            <Grid lg={12} md={12} sm={12} xs={12}>
                <p className={classes.title}>ระบบช่วยการขายของออนไลน์</p>
            </Grid>
            <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                direction="row"
                alignItems="center"
                className={classes.group}
            >
                <Grid lg={10} md={10} sm={9} xs={8} container direction="row">
                    <Grid
                        lg={1}
                        md={2}
                        sm={3}
                        xs={5}
                        className={classes.groupSocial}
                    >
                        <a
                            href="https://www.facebook.com/hellomelonlive"
                            rel="noopener noreferrer"
                            target="_blank"
                            className={classes.linkSocialFacebook}
                            style={{
                                lineHeight: '0',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <img src={facebook} alt="app store icon" />
                            <span className={classes.social}>Melon LIVE</span>
                        </a>
                    </Grid>
                    <Grid lg={1} md={2} sm={3} xs={3}>
                        <a
                            href="https://line.me/R/ti/p/%40056msotj"
                            rel="noopener noreferrer"
                            target="_blank"
                            className={classes.linkSocialLine}
                            style={{
                                lineHeight: '0',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <img src={line} alt="app store icon" />
                            <span className={classes.social}>@MelonLIVE</span>
                        </a>
                    </Grid>
                </Grid>
                <Grid lg={2} md={2} sm={3} xs={4} container justify="flex-end">
                    <p className={classes.groupTextPresent}>
                        Presented by{' '}
                        <span className={classes.melonLive}>Melon LIVE</span>
                    </p>
                </Grid>
            </Grid>
        </Grid>
    )
}
