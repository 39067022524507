import { Button, CircularProgress, Dialog } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {
    AddBox,
    ArrowDownward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    SaveAlt,
    Search,
} from '@material-ui/icons'
import 'firebase/functions'
import MaterialTable, { Icons } from 'material-table'
import React, { forwardRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import DatePickers from './dateRangePickers'

const tableIcons: Icons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
    )),
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
    }),
)

export default function FacebookPageReportTable(
    title: string,
    dataArray: Array<any>,
    onSubmitDate: (fromDate: String, toDate: String) => void,
    fromdateLabel: string,
    todateLabel: string,
) {
    const classes = useStyles()
    const history = useHistory()
    const [data, setData] = React.useState(dataArray)
    const [open, setOpen] = React.useState(false)

    useEffect(() => {
        setData(dataArray)
    }, [dataArray])

    const handleClose = () => {
        setOpen(false)
    }

    const viewReport = (rowData: any) => {
        history.push(`./report/${rowData.pageId}`)
    }

    return (
        <div>
            <MaterialTable
                title={
                    <DatePickers
                        onSubmit={onSubmitDate}
                        todateValue={todateLabel}
                        fromdateValue={fromdateLabel}
                    />
                }
                columns={[
                    { field: 'pageId', title: 'PageId' },
                    { field: 'pageName', title: 'PageName' },
                    { field: 'bankAccountName', title: 'BankAccountName' },
                    { field: 'liveDuration', title: 'LiveDuration (min)' },
                    { field: 'LatestLive', title: 'LatestLive' },
                ]}
                data={dataArray}
                icons={tableIcons}
                options={{
                    pageSize: 25,
                    actionsColumnIndex: -1,
                    exportButton: true,
                    pageSizeOptions: [25, 50, 100, 200, dataArray.length],
                }}
                localization={{
                    header: {
                        actions: 'Action',
                    },
                }}
                actions={[
                    {
                        icon: (props: any) => {
                            return (
                                <div className={classes.root}>
                                    <Button variant="outlined" color="primary">
                                        View
                                    </Button>
                                </div>
                            )
                        },
                        tooltip: 'View',
                        onClick: (event, rowData) => viewReport(rowData),
                    },
                ]}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CircularProgress style={{ margin: '20px' }} />
            </Dialog>
        </div>
    )
}
