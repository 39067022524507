import { Avatar, Card, CardContent, CardHeader, Grid } from '@material-ui/core'
import { LocalShipping } from '@material-ui/icons'
import React from 'react'
import { StatusEnum } from '../../../enum/statusEnum'
import { IContact } from '../../../model/IContact'
import useStyles from '../style/style'
import ShippingComponent from './shippingComponent'

export default function CustomerShowContactComponent(props: any) {
    const classes = useStyles()
    const contact: IContact = props.contact
    const activeStep: StatusEnum = props.activeStep

    return (
        <Grid item xs={12} className={classes.cardSpaceing}>
            <Card
                className={[classes.root, classes.wrapperLayout].join(' ')}
                style={{ marginBottom: '10px' }}
            >
                <CardHeader
                    avatar={
                        <Avatar
                            aria-label="recipe"
                            className={classes.iconShipping}
                        >
                            {' '}
                            <LocalShipping style={{ fill: '#5CB4FF' }} />
                        </Avatar>
                    }
                    title={
                        <span className={classes.cardTitle}>
                            ที่อยู่ในการจัดส่ง
                        </span>
                    }
                />
                <CardContent>
                    <div style={{ marginBottom: '10px' }}>
                        <p className={classes.contactText}> {contact?.name}</p>
                        <p className={classes.contactText}>{contact?.tel}</p>
                        <p
                            className={classes.contactText}
                            style={{ color: '#6B6A71' }}
                        >
                            {`${contact?.address} ${contact?.province} ${contact?.postCode}`}
                        </p>
                    </div>
                    {activeStep == StatusEnum.COMPLETE ? (
                        <ShippingComponent
                            tracking={props.tracking}
                            pageId={props.pageId}
                        />
                    ) : (
                        <div />
                    )}
                </CardContent>
            </Card>
        </Grid>
    )
}
