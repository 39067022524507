import { Container, Grid } from '@material-ui/core'
import 'firebase/functions'
import 'firebase/storage'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { StatusEnum } from '../../enum/statusEnum'
import firebase from '../../firebase'
import { IContact } from '../../model/IContact'
import { IProduct } from '../../model/IProduct'
import { ISummary } from '../../model/ISummary'
import { ITracking } from '../../model/ITracking'
import { ITrackingParam } from '../../model/ITrackingParam'
import { PaymentMethodType } from '../../utils/paymentMethodType'
import FullScreenLoading from '../component/fullScreenloading'
import CustomerBankSlipComponent from './component/customerBankSlipComponent'
import CustomerContactFormComponent from './component/customerContactComponent'
import CustomerOrderComponent from './component/customerOrderComponent'
import CustomerShowContactComponent from './component/customeShowContactComponent'
import DownloadHeaderComponent from './component/downloadHeaderComponent'
import FooterComponent from './component/footerComponent'
import InboxPageModal from './component/modalConfirmPaymentComponent'
import SlipComponent from './component/slipComponent'
import StepComponent from './component/stepComponent'
import SubmitButtonComponent from './component/submitButtonComponent'
import TitleTextComponent from './component/titleTextComponent'
import TrackingStatusComponent from './component/trackingStatusComponent'
import useStyles from './style/style'


const statusMap: Record<string, StatusEnum> = {
    transferWaiting: StatusEnum.TRANSFERWAITING,
    transferred: StatusEnum.TRANSFERRED,
    shippingWaiting: StatusEnum.SHIPPINGWAITING,
    complete: StatusEnum.COMPLETE,
    transferInvalid: StatusEnum.TRANSFERINVALID,
}

export default function TrackingPage(props: any) {
    const classes = useStyles()
    const history = useHistory()

    const [trackingParam, setTrackingParam] = React.useState<ITrackingParam>({
        pageId: '',
        liveId: '',
        userId: '',
    })

    const [tracking, setTracking] = React.useState<ITracking>({
        id: 'KERRYEX',
        trackingNumber: 'SG123456TH',
        isShippop: false,
    })

    const [slipList, setSlipList] = React.useState<string[]>([])

    const [activeStep, setActiveStep] = React.useState<StatusEnum>(
        StatusEnum.TRANSFERWAITING,
    )

    const [customerProductArray, setCustomerProductArray] = React.useState<
        IProduct[]
    >([])

    const [summary, setSummary] = React.useState<ISummary>({
        saleSummary: 0,
        orderSummary: 0,
        shippingFee: 0,
    })

    const [contact, setContact] = React.useState<IContact>({
        address: '',
        district: '',
        subdistrict: '',
        name: '',
        postCode: '',
        province: '',
        tel: '',
    })

    const [email, setEmail] = React.useState<string>('')
    const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<string>(PaymentMethodType.PaySolutions.value)

    const [bankAccountsArray, setBankAccountsArray] = React.useState<{ account: string, bankName: string, name: string }[]>([])
    const [submitLoading, setsubmitLoading] = React.useState(false)

    const [isOpenInboxPageModal, setIsOpenInboxPageModal] = React.useState(
        false,
    )

    const [errContactFormMsg, setErrContactFormMsg] = React.useState<IContact>({
        address: '',
        district: '',
        subdistrict: '',
        name: '',
        postCode: '',
        province: '',
        tel: '',
    })

    useEffect(() => {
        const { pageId, liveId, userId } = props.match.params
        setTrackingParam({ pageId, liveId, userId })
        fetchData(pageId, liveId, userId)
    }, [])

    const fetchData = (pageId: string, liveId: string, userId: string) => {
        const functions = firebase
            .app()
            .functions('asia-east2')
            .httpsCallable('v1-getUserOrder')
        functions({ pageId: pageId, userId: userId, liveId: liveId })
            .then(async function (result) {
                const { data: {
                    status,
                    customerProductArray,
                    bankAccounts,
                    saleSummary,
                    orderSummary,
                    shippingFee,
                    contact,
                    tracking,
                    slipList,
                    paySolutions,
                    paymentMethod,
                } } = result.data

                const statusEnum = statusMap[status]

                const summary: ISummary = {
                    saleSummary: saleSummary,
                    orderSummary: orderSummary,
                    shippingFee: shippingFee,
                }
                const newProductArray = await convertProduct(
                    customerProductArray,
                )
                if (slipList) setSlipList(slipList)
                if (tracking) setTracking(tracking)
                if (statusEnum === StatusEnum.TRANSFERRED) openInboxPageModal()

                setSummary(summary)
                setCustomerProductArray(newProductArray)
                setSummary(summary)
                setContact(contact)
                setBankAccountsArray(bankAccounts)
                setActiveStep(statusEnum)
                setEmail(paySolutions?.email)
                setSelectedPaymentMethod(paymentMethod === '' ? PaymentMethodType.PaySolutions.value : paymentMethod)
            })
            .catch(function (error) {
                console.error(error)
            })
    }

    const convertProduct = async (
        productArray: IProduct[],
    ): Promise<IProduct[]> => {

        const newProductArray: IProduct[] = []

        const trackingDic = _.groupBy(productArray, (entry) =>
            entry.subCodeId ? `${entry.productId},${entry.subCodeId}` : entry.productId
        );

        for (const productId in trackingDic) {
            const newProduct: IProduct = trackingDic[productId][0]
            const amount = trackingDic[productId].reduce((sum, data) => {
                return sum + data.amount
            }, 0)

            const totalPrice = trackingDic[productId].reduce((sum, data) => {
                return sum + data.price * data.amount
            }, 0)

            newProduct.amount = amount
            newProduct.price = totalPrice
            newProductArray.push(newProduct)
        }

        return newProductArray
    }

    const saveSlipToDB = async (event: any) => {
        const { pageId, liveId, userId } = trackingParam
        const file = event.target.files[0]
        event.target.value = null
        const dateNow: string = moment(Date()).format('MMDDYYYYhmmss')
        const storageRef = firebase.storage().ref()
        const tempPath = `slip/${pageId}/${liveId}/${userId}_${dateNow}.jpg`
        const imageRef = storageRef.child(tempPath)
        const tempSlipListLoading = 'loading'
        const loadingSlipList = [tempSlipListLoading, ...slipList]
        await setSlipList(loadingSlipList)
        imageRef.put(file).then(function (snapshot) {
            setSlipList([tempPath, ...slipList])
        })
    }

    const deleteSlipImg = (imgPath: string) => {
        // Create a reference to the file to delete
        const storageRef = firebase.storage().ref()
        const desertRef = storageRef.child(imgPath)
        // Delete the file
        desertRef
            .delete()
            .then(function () {
                // File deleted successfully
                const temp = slipList.filter((item) => item !== imgPath)
                setSlipList(temp)
            })
            .catch(function (error) {
                // Uh-oh, an error occurred!
            })
    }

    const submitUserDataWithBank = () => {
        setsubmitLoading(true)
        const functions = firebase
            .app()
            .functions('asia-east2')
            .httpsCallable('v1-submitUserDataWithBank')

        functions({
            pageId: trackingParam.pageId,
            userId: trackingParam.userId,
            liveId: trackingParam.liveId,
            contact: contact,
            slipList: slipList,
        })
            .then(function (result) {
                window.location.reload()
            })
            .catch(function (error) {
                setsubmitLoading(false)
            })
    }

    const submitUserDataWithPaySolution = async () => {
        const refno = `${trackingParam.pageId.slice(11, 15)}${trackingParam.liveId.slice(11, 15)}${trackingParam.userId.slice(12, 16)}`
        const merchantid = _.chain(bankAccountsArray).filter(['bankName', 'Pay Solutions']).first().value().account
        const productdetail = `-pages-${trackingParam.pageId}-lives-${trackingParam.pageId}_${trackingParam.liveId}-userOrders-${trackingParam.pageId}_${trackingParam.userId}`
        const total = `${summary.saleSummary + summary.shippingFee}`

        setsubmitLoading(true)

        const functions = firebase
            .app()
            .functions('asia-east2')
            .httpsCallable('v1-submitUserDataWithPaySolution')

        functions({
            pageId: trackingParam.pageId,
            userId: trackingParam.userId,
            liveId: trackingParam.liveId,
            contact: contact,
        }).then((_) => {
            history.push(`/paySolution/${refno}/${merchantid}/${email}/${productdetail}/${total}`)
        }).catch((error) => {
            console.error(JSON.stringify(error))
        }).finally(() => setsubmitLoading(false))
    }

    const onClickSubmitUserData = () => {
        selectedPaymentMethod === PaymentMethodType.Bank.value ? submitUserDataWithBank() : submitUserDataWithPaySolution()
    }

    const setContactData = (event: any) => {
        const { value, name } = event
        setContact({
            ...contact,
            ...{ [name]: value },
        })
    }


    const openInboxPageModal = () => {
        setIsOpenInboxPageModal(true)
    }
    return (
        <Container fixed className={classes.bgColor}>
            { submitLoading &&
                <FullScreenLoading />
            }

            <DownloadHeaderComponent />
            <Grid container spacing={0}>
                <StepComponent
                    activeStep={
                        activeStep === StatusEnum.TRANSFERINVALID
                            ? StatusEnum.TRANSFERWAITING
                            : activeStep
                    }
                />
                <TitleTextComponent activeStep={activeStep} />

                {(activeStep === StatusEnum.SHIPPINGWAITING
                    || activeStep === StatusEnum.COMPLETE)
                    && <TrackingStatusComponent activeStep={activeStep} />
                }

                {(activeStep === StatusEnum.SHIPPINGWAITING
                    || activeStep === StatusEnum.COMPLETE)
                    && <CustomerShowContactComponent
                        tracking={tracking}
                        contact={contact}
                        activeStep={activeStep}
                        pageId={trackingParam.pageId}
                    />
                }

                <CustomerOrderComponent
                    customerProductArray={customerProductArray}
                    summary={summary}
                />

                {(activeStep === StatusEnum.TRANSFERWAITING
                    || activeStep === StatusEnum.TRANSFERINVALID
                    || activeStep === StatusEnum.TRANSFERRED)
                    && <CustomerContactFormComponent
                        setContactData={setContactData}
                        contact={contact}
                        errContactFormMsg={errContactFormMsg}
                        setErrContactFormMsg={setErrContactFormMsg}
                    />
                }

                {/* Paymenyt && Slip Section*/}
                {activeStep === StatusEnum.SHIPPINGWAITING
                    || activeStep === StatusEnum.COMPLETE
                    ? (<SlipComponent slipUrlList={slipList} />)
                    : (
                        <CustomerBankSlipComponent
                            setSelectedPaymentMethod={setSelectedPaymentMethod}
                            selectedPaymentMethod={selectedPaymentMethod}
                            setEmail={setEmail}
                            email={email}
                            bankAccounts={bankAccountsArray}
                            activeStep={activeStep}
                            slipList={slipList}
                            saveSlipToDB={saveSlipToDB}
                            deleteSlipImg={deleteSlipImg}
                        />
                    )}


                {/* Submit Button */}
                {(activeStep === StatusEnum.TRANSFERWAITING
                    || activeStep === StatusEnum.TRANSFERINVALID
                    || activeStep === StatusEnum.TRANSFERRED)
                    && <SubmitButtonComponent
                        submitUserData={() => onClickSubmitUserData()}
                        activeStep={activeStep}
                        contact={contact}
                        errContactFormMsg={errContactFormMsg}
                        slipList={slipList}
                        email={email}
                        selectedPaymentMethod={selectedPaymentMethod}
                    />
                }

                <InboxPageModal
                    isOpenInboxPageModal={isOpenInboxPageModal}
                    setIsOpenInboxPageModal={setIsOpenInboxPageModal}
                    pageId={trackingParam.pageId}
                />
            </Grid>

            <FooterComponent />
        </Container>
    )
}
