export interface IPaymentMethodTypeModel {
    name: string
    value: string
}

const PaySolutions: IPaymentMethodTypeModel = {
    name: 'ระบบชำระเงินออนไลน์ (Pay Solutions)',
    value: 'paySolutions',
}

const Bank: IPaymentMethodTypeModel = {
    name: 'โอนเงินผ่านธนาคาร',
    value: 'bank',
}

export const PaymentMethodType = {
    PaySolutions,
    Bank,
}
